import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { User } from '../../model/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-select-user-dialogs',
  templateUrl: './select-user-dialogs.component.html',
  styleUrls: ['./select-user-dialogs.component.css']
})
export class SelectUserDialogsComponent implements OnInit {
  public users: User[];
  public selectUser: User;
  public userDataSource = new MatTableDataSource<User>();
  public title: string;
  public titleButton: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public userTableColumns: string[] = [ 'select', 'name' ];
  public loading = true;
  constructor(public dialogRef: MatDialogRef<SelectUserDialogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.userService.getByStaff().subscribe( u => {
      this.users = u;
      this.userDataSource = new MatTableDataSource(u);
      this.userDataSource.paginator = this.paginator;
      this.userDataSource.sort = this.sort;
      this.loading = false;
    });
    if (this.data !==  undefined) {
      this.title = this.data.title;
      this.titleButton = this.data.titleButton;
    }
  }

  ngSelect(): void {
    this.dialogRef.close({
      valid: true,
      data: {
        user: this.selectUser
      }
    });
  }
  onChangeSelection(user: User) {
    this.selectUser = user;
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.userDataSource.filter = filterValue;
  }

}
