import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Email } from '../model/email';
import { Observable } from 'rxjs';
import { CrudResponseModel } from '../model/crud-response-model';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends AbstractDataProviderService<Email> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/email', http, appConfig);
  }

  getPeopleOrRelationshipEmail(peopleId: number): Observable<Email> {
    return this.http.get<Email>(this.baseUrl + '/getPeopleOrRelationshipEmail/' + peopleId.toString(),
    { headers: this.buildGetHeaders() });
  }

  getByPeopleId(peopleId: number): Observable<Email[]> {
    return this.http.get<Email[]>(this.baseUrl + '/byPeopleId/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }
  getByRelationship(peopleId: number): Observable<Email[]> {
    return this.http.get<Email[]>(this.baseUrl + '/byRelationship/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }
  getFullByPeopleId(peopleId: number): Observable<Email[]> {
    return this.http.get<Email[]>(this.baseUrl + '/fullByPeopleId/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }

  addEmailToPeople(email: Email, peopleId: number) {
      this.add(email).subscribe(result => {
          this.linkEmailToPeople(result.id, peopleId).subscribe();
      });
  }
  deleteEmailFromPeople(email: Email, peopleId: number) {
    this.delete(email.emailId).subscribe();
      if (email.relationshipId === null) {
        this.unlinkEmailFromPeople(email.emailId, peopleId).subscribe();
      } else {
        this.unlinkEmailFromRelationship(email.emailId, email.relationshipId).subscribe();
      }
    }

  linkEmailToPeople(emailId: number, peopleId: number, isPrefered?: boolean): Observable<CrudResponseModel> {
    const body = {
      'emailId': emailId,
      'peopleId': peopleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/people', body, { headers: this.buildPostHeaders() });
  }

  linkEmailToRelationship(emailId: number, relationshipId: number): Observable<CrudResponseModel> {
    const body = {
      'emailId': emailId,
      'relationshipId': relationshipId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/relationship', body, { headers: this.buildPostHeaders() });
  }


  unlinkEmailFromPeople(emailId: number, peopleId: number): Observable<CrudResponseModel> {
    const body = {
      'emailId': emailId,
      'peopleId': peopleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/people', body, { headers: this.buildPostHeaders() });
  }
  unlinkEmailFromRelationship(emailId: number, relationshipId: number): Observable<CrudResponseModel> {
    const body = {
      'emailId': emailId,
      'relationshipId': relationshipId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/relationship', body, { headers: this.buildPostHeaders() });
  }

  linkEmailToCompany(emailId: number, companyId: number): Observable<CrudResponseModel> {
    const body = {
      'companyId': companyId,
      'emailId': emailId
    };
    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/company', body, { headers: this.buildPostHeaders() });
  }

  unlinkEmailFromCompany(emailId: number, companyId: number): Observable<CrudResponseModel> {
    const body = {
      'emailId': emailId,
      'companyId': companyId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/company', body, { headers: this.buildPostHeaders() });
  }

  getByCompanyId(companyId: number): Observable<Email[]> {
    return this.http.get<Email[]>(this.baseUrl + '/byCompanyId/' + companyId.toString(), { headers: this.buildGetHeaders() });
  }
  getByEmailId(id: number): Observable<Email[]> {
    return this.http.get<Email[]>(this.baseUrl + '/byEmailId/' + id.toString(), { headers: this.buildGetHeaders() });
  }

  confirmEmail(vCode: string, token: string): Observable<any> {
    const body = {
      'authToken': token,
      'code': vCode
    };
    return this.http.post<any>(
      this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat/confirm',
      body,
      { headers: this.buildGetHeaders() });
  }
}

