import { Component, Injector, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BaseList } from '../../../building-blocks/base-list';

import { BaseListDialogData } from '../../../building-blocks/base-list-dialog-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaffService } from '../../../services/staff.service';

import { Staff } from '../../../model/Staff';
import { StaffEditComponent } from '../staff-edit/staff-edit.component';
import { Community } from 'src/app/model/community';
import { AlertDialogComponent } from 'src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { PeopleCommunityPositionService } from 'src/app/services/people-community-position.service';
import { AccessType } from 'src/app/model/access-type-ring';

const staffColumns = ['name', 'position', 'isActive', 'startDate', 'actions'];

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent extends BaseList<Staff>  {

  private communityId = 0;
  public communities: Community[] = [];

  constructor(
    public injector: Injector,
    private staffService: StaffService,
    private peopleCommunityPositionService: PeopleCommunityPositionService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
    super(staffColumns, '/peopleCommunityPosition', peopleCommunityPositionService, 'peopleCommunityPosition', injector);
    this.filters = '';
    this.loadSecurityRings();
  }
  protected loadSecurityRings() {
    this.securityEntity.rings.push({ringId : 575, accessType: AccessType.Add });
    this.securityEntity.rings.push({ringId : 775, accessType: AccessType.Update });
    this.securityEntity.rings.push({ringId : 775, accessType: AccessType.Delete });
    this.entityRings.push(this.securityEntity);
  }


  protected getList(pageIndex: number, pageSize: number): Observable<Staff[]> {
    return this.peopleCommunityPositionService.getAllPaginated(pageIndex, pageSize, this.filters);
  }

  protected getCount(): Observable<number> {
    return this.peopleCommunityPositionService.getCount(this.filters);
  }

  protected getById(id: number): Observable<Staff> {
    return this.staffService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any {
    let dialogRef: any;
    if (this.searchParamsBreadcrumb.communityId === 0) {
      const data = {
        Title: 'Select a Community',
        Message: 'You must select a community in the breadcrumb'
      };
      dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: data
      });
    } else {
      dialogRef = this.dialog.open(StaffEditComponent, {
        width: '620px',
        height: '500px',
        data: new BaseListDialogData<Staff>(isUpdate, model, emitter)
      });
    }

    return dialogRef;
  }

  applyFilter(filterValue) {
    this.loading = true;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.filters = filterValue;
    this.dataSource.reload();
    this.loading = false;
  }

  removeFilter() {
    this.filters = '';
  }

  getDialogData(peopleCommunityPositionId: number) {
    // A reference to this instance must be saved and passed to the
    // built function, so that it may be called from the dialog component.
    const closure = this;

    return () => {
      return closure.staffService.getcommunityStaffByStaffId(peopleCommunityPositionId)
        .pipe(map(results => {
          return {
            update: true,
            entity: results[0]
          };
        }));
    };
  }

  deleteItem(id: number): void {
    if (confirm('Are you sure you want to delete this item?')) {
      this.loading = true;
      this.staffService.delete(id).subscribe(result => {
        this.dataSource.reload();
        this.loading = false;
      });
    }
  }

}
