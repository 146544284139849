import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class User extends BaseForm {

    /**
     * The ID of the user in the database.
     */
    userId = 0;


    firstName = '';


    lastName = '';

    @FormField('email', ['', [Validators.required, Validators.email]])
    email = '';

    /**
     * The username of the user.
     */
    // @FormField('userName', ['', Validators.required]) <!--Bug 867 User Name in Web Page-->
    userName = '';

   /**
     * The peopleId of the user.
     */
    peopleId = 0;

      /**
     * The people of the user.
     */
    @FormField('people', ['', Validators.required])
    people = '';
}
