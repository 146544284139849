import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { People } from '../model/people';
import { AbstractDataProviderService } from './abstract-data-provider.service';

import * as AppConstants from '../app-constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { PictureService } from './picture.service';
import { PaymentInfo } from '../model/paymentInfo';
import { ResidentSearch } from '../model/resident-search';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class PeopleService extends AbstractDataProviderService<People> {

  protected baseUrlPicture = '/picture';
  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserDisplayName());
  currentUser = this.userInfo.asObservable();
  private userPicture = new BehaviorSubject('');
  currentUserPicture = this.userPicture.asObservable();

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig, private pictureService: PictureService) {
    super(AppConstants.EndpointNames.GridVendor, '/people', http, appConfig, LevelFilter.Unit);
  }

  getAllPaginatedFiltered(pageIndex: number, pageSize: number, filter: string): Observable<People[]> {
    const queryString = '/allPagedFiltered/' + pageIndex.toString() + '/' + pageSize.toString() + '/' + filter;
    return this.http.get<People[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
  GetForCurrentUser(): Observable<People> {
    return this.http.get<People>(this.baseUrl + '/forUser', { headers: this.buildGetHeaders() });
  }
  GetForUser(userId: number): Observable<People> {
    return this.http.get<People>(this.baseUrl + '/forUser/' + userId, { headers: this.buildGetHeaders() });
  }
  GetProfilePicture(impersonated?: boolean): Observable<any> {
    if (impersonated) {
      return this.pictureService.GetProfilePictureImpersonated();
    }
    return this.pictureService.GetProfilePicture();
  }
  SetProfilePicture(picture: Uint8Array): Observable<null> {
    return this.pictureService.SetProfilePicture(picture);
  }

 setUserPicture() {
    this.GetProfilePicture().subscribe( pic => {
      const reader =  new FileReader();
      const blob = new Blob( [ new Uint8Array(pic) ], { type: 'image/jpeg' } );
      if (blob.size > 0) {
      const file = new File([blob], 'xxx', {type: 'image/jpeg', lastModified: Date.now()});
      reader.onload = (event: any) => {
        this._setUserPicture(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      this._setUserPicture(null);
    }
  });
  }


  setUserInfo(people: People): void {
    const displayName = people.displayName.trim() !== '' ? people.displayName :
    people.firstName + ' ' + people.lastName;
    LocalStorageHelper.setUserDisplayName(displayName);
    this.userInfo.next(displayName);
  }
  getUserInfo(): Observable<string> {
    if (this.userInfo.value == null) {
      this.userInfo.next(LocalStorageHelper.getUserDisplayName());
    }
    return this.userInfo;
  }
  _setUserPicture(src: string): void {
    this.userPicture.next(src);
  }

  getPaymentInfo(username: string): Observable<PaymentInfo[]> {
    const queryString = '/paymentInfo/' + username;
    return this.http.get<PaymentInfo[]>(this.baseUrl + queryString, { headers: this.buildPostHeaders() });
  }


  getResidentsAllByParamsPaginated(pageIndex: number, pageSize: number,
    propertyManagementCompanyId: number, communityId: number, areaId: number, unitId: number,
    filter: string): Observable<People[]> {
      let  queryString = '/allResidentsByParamsPaged/' +  pageIndex + '/' + pageSize + '/' +
      propertyManagementCompanyId + '/' + communityId + '/' + areaId  + '/' + unitId;

      if (filter) {
        queryString += '/' + filter;
      }

      return this.http.get<People[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllByFilter( filter: string): Observable<ResidentSearch[]> {
    this.setBreadcrumbParams();
        const queryString = '/searchResidents/'  + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
        this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
        + '/' + this.searchParamsBreadcrumb.unitId +  '/' + filter  ;
    return this.http.get<ResidentSearch[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}
