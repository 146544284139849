import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NewCreditCard } from 'src/app/model/newCreditCard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmaugService } from 'src/app/services/smaug.service';
import { BankAccountType } from 'src/app/model/bank-account-type';
import { NewBankAccount } from 'src/app/model/new-bank-account';

@Component({
  selector: 'app-add-ach-payment-dialog',
  templateUrl: './add-ach-payment-dialog.html',
  styleUrls: ['./add-ach-payment-dialog.css']
})
export class AddAchPaymentDialogComponent implements OnInit {
  userSourceAccountNumber = '';
  paypointSourceAccountNumber = '';
  applicationId = 1;

  registerForm: FormGroup;
  submitted = false;

  loading = false;

  bankAccountTypes: BankAccountType[] = [];
  accountTypeSelected: BankAccountType;
  bankAccountNumber: number;
  bankABANumber: number;

  constructor(
    public dialogRef: MatDialogRef<AddAchPaymentDialogComponent>,
    private formBuilder: FormBuilder,
    private smaugService: SmaugService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userSourceAccountNumber = data.userSourceAccountNumber;
    this.paypointSourceAccountNumber = data.paypointSourceAccountNumber;
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      bankNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(19)]],
      bankABANumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(19)]],
      bankAccountType: ['', [Validators.required, Validators.minLength(2)]]
    });
    this.smaugService.getBankAccountType().subscribe(x => {
      this.bankAccountTypes = x;
    });
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    console.log(this.registerForm.invalid);

    if (this.registerForm.invalid) {
      // return;
    }

    this.bankABANumber = this.registerForm.get('bankABANumber').value;
    this.bankAccountNumber = this.registerForm.get('bankNumber').value;

    const bankAccount: NewBankAccount = {
      userSourceAccountNumber: this.userSourceAccountNumber,
      paypointSourceAccountNumber: this.paypointSourceAccountNumber,
      applicationId: this.applicationId,
      bankAccountNumber: this.bankAccountNumber,
      bankAccountTypeId: this.accountTypeSelected.bankAccountTypeId,
      bankABANumber: this.bankABANumber
    };
    this.loading = true;
    this.smaugService.addBankAccount(bankAccount).subscribe(result => {
      this.loading = false;
      this.dialogRef.close(bankAccount);
    }, error => {
      this.snackbar.open(error, '', { duration: 6000 });
      this.loading = false;
    });
  }

  selected(value: any): void {
    if (value) {
      this.accountTypeSelected = value;
    }
  }

}
