import { Component, OnInit, Inject } from '@angular/core';
import { SearchPeopleDialogComponent } from '../../../app-dialogs/search-people-dialog/search-people-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup} from '@angular/forms';
import { People } from '../../../model/people';
import { Community_Position, Position } from '../../../model/community-position';
import { CommunityPositionService } from '../../../services/community-position.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../app-dialogs/confirm-dialog/confirm-dialog.component';
import { Staff, PeopleStaff } from '../../../model/Staff';
import { PeopleService } from '../../../services/people.service';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { AccessType } from 'src/app/model/access-type-ring';
export interface CommunityPositionGroup {
  letter: string;
  positions: Position[];
}

export const _filter = (opt: Position[], value: string): Position[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.positionName.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-staff-edit',
  templateUrl: './staff-edit.component.html',
  styleUrls: ['./staff-edit.component.css']
})

export class StaffEditComponent extends BaseSecurity implements OnInit {
  public model: Staff;
  public staffFormGroup: FormGroup;

  /**
     * Initializes a new instance of TagAddEditComponent.
     *
     * @param dialogRef A reference to an instance of MatDialogRef that allows
     * add/update dialogs to be opened.
     * @param data If a tag has been selected for update, the data of the tag
     * to update.
     * @param fb A reference to an instance of FormBuilder.
     * @param snackBar A reference to an instance of MatSnackBar.
     * @param dialog .
     */

  public loading = false;
  public isUpdate = false;
  public communityPositions: Community_Position[] = [];
  public communityPositionOptions: Observable<CommunityPositionGroup[]>;
  public positions: Position[] = [];
  public firstLetterPositionNames: string[] = [];
  public cpsGroup: CommunityPositionGroup[] = [];

  constructor(public dialogRef: MatDialogRef<StaffEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private communityPositionService: CommunityPositionService,
    private peopleService: PeopleService) {
    super();
    this.model = new Staff(this.fb);
    this.staffFormGroup = this.model.buildFormGroup();
  }

  ngOnInit() {
    this.loading = true;
    this.isUpdate = false;
    this.cpsGroup = [];
    this.communityPositionService.getPositions().subscribe(x => {
      this.positions = x;
    });

    if (this.data.update === true) {
      this.peopleService.getById(this.data.model.entity.peopleId).subscribe(p => {
        this.data.model.entity.peopleStaff = new PeopleStaff(p.peopleId, this.displayFn(p));
        this.data.model.entity.positionStaff = new Position(this.data.model.entity.community_PositionId,
          this.data.model.entity.positionStaff);
        this.model.fromObject(this.data.model.entity);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.model.isActive = true;
      this.model.showInContacts = true;
    }
  }

  displayFn(people?: People): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : '') +
      people.lastName + (people.displayName ? ' ' + people.displayName : '') : '';
  }

  openDialogPeople(): void {
    const dialogRef = this.dialog.open(SearchPeopleDialogComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.model.peopleStaff = new PeopleStaff(result.data.people.peopleId, this.displayFn(result.data.people)) ;
        this.staffFormGroup.get('peopleStaff').setValue(this.model.peopleStaff);
      }
    });
  }

  unique(originalArray: string[]): string[] {
    return Array.from(originalArray.sort()).filter(function (item, pos, ary) {
      return !pos || item !== ary[pos - 1];
    });
  }

  close(modelDto: any) {
    this.dialogRef.close({
      valid: true,
      isUpdate: this.isUpdate,
      entity: modelDto
    });
  }

  onSubmit(): void {
    if (!this.staffFormGroup.valid) {
      return;
    }
    const modelDto = this.model.toDto();
    modelDto.peopleId = modelDto.peopleStaff.peopleId;
    modelDto.community_PositionId = modelDto.positionStaff.community_PositionId;
    this.close(modelDto);
  }

  onCancel(): void {
    if (this.staffFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }
}
