// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as AppConstants from '../app/app-constants';

export const environment = {
  production: false,
  defaultDomain: 'gridenterprisestaging.com',
  endpoints: {
    [AppConstants.EndpointNames.C3PO]: 'https://c3po.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.GridVendor]: 'https://gringotts.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.MenuService]: 'https://menu.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.SortingHat]: 'https://sortinghat.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.SecurityAdminService]: 'https://sec.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.TardisService]: 'https://tardis.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.NotificationService]: 'https://notifications.gridenterprisestaging.com/api/notifications',
    [AppConstants.EndpointNames.SmaugService]: 'https://smaug.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.SearchService]: 'https://search.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.Newsfeed]: 'https://newsfeed.gridenterprisestaging.com/api',
    [AppConstants.EndpointNames.Pensieve]: 'https://pensieve.gridenterprisestaging.com/api'
  },
  apiKeys: {
    Smaug : 'F7F85f9ACPlIhPjJ2+fXAao9EbzGMqsqjIJVXrJfSq4=',
    C3PO : 'ADmEpdg0zEeJ7VjeWSgDOp63qxxYo6l3LYltDRYCss0',
    Gingotts : 'ADmEpdg0zEeJ7VjeWSgDOp63qxxYo6l3LYltDRYCss0',
    Pensieve : 'gFs03YTa+jVOi+2MGpmiZBGAwinXFi4ZuwFmu9cQLU0=',
   },
  firebaseConfig: {
    apiKey: 'AIzaSyBlumvCCSbKUKg4KXWQNGd2SORBrXmEZZs',
    authDomain: 'gridenterprisestaging.firebaseapp.com',
    databaseURL: 'https://gridenterprisestaging.firebaseio.com',
    projectId: 'gridenterprisestaging',
    storageBucket: 'gridenterprisestaging.appspot.com',
    messagingSenderId: '845110034828'
  },
  messagingPublicKey: 'BA_sTWOQnygdlpailTcT1JDOBRRYirHAZ5tLFCI3AF04BnMi4G8U4wMMEAgAaf5Ogoxd2n_m1vQzSK_AWBZ0GGs',
  snsApplicationName: 'GridEnterpriseStaging',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
