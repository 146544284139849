import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

import { CompaniesRoutingModule } from './companies-routing/companies-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SharedCompanyModule } from './shared-company/shared-company.module';

import { PropertyManagementCompanyListComponent } from './property-management-company-list/property-management-company-list.component';
import {
  PropertyManagementCompanyAddEditComponent
} from './property-management-company-add-edit/property-management-company-add-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CompaniesRoutingModule,
    MaterialModule,
    SharedModule,
    SharedCompanyModule
  ],
  exports: [
    CompaniesRoutingModule,
  ],
  declarations: [
    PropertyManagementCompanyListComponent,
    PropertyManagementCompanyAddEditComponent
  ],
})
export class CompaniesModule { }

