import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Company, TypeCompany } from '../model/company';
import { Observable, BehaviorSubject } from 'rxjs';
import { PictureService } from './picture.service';
import { Community } from '../model/community';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends AbstractDataProviderService<Company> {

  public companyLogo = new BehaviorSubject('');
  currentCompanyLogo = this.companyLogo.asObservable();

  private isCommunity = new BehaviorSubject(false);
  isCurrentCommunity = this.isCommunity.asObservable();

  private companyInfo = new BehaviorSubject('');
  currentCompanyInfo = this.companyInfo.asObservable();

  public communityId = new BehaviorSubject(0);
  currentcommunityId = this.communityId.asObservable();

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig, private pictureService: PictureService) {
    super(AppConstants.EndpointNames.GridVendor, '/company', http, appConfig);
  }


  getByType(type: TypeCompany): Observable<Company[]> {
    this.setBreadcrumbParams();
    const queryString = '/allByType/' + type;
    return this.http.get<Company[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
  /**
   * Retrieves a company by its EIN number.
   *
   * @param eINNumber - The EIN number to look for.
   */
  getByEinNumber(eINNumber: string): Observable<Company[]> {
    return this.http.get<Company[]>(this.baseUrl + '/byEinNumber/' + eINNumber, { headers: this.buildGetHeaders() });
  }

  /**
   * Loads the logo of the specified company.
   *
   * @param companyId - The ID of the company.
   */
  getCompanyLogo(companyId: number): Observable<any> {
    return this.pictureService.GetCompanyPictureLogo(companyId);
  }

  /**
   * Sets the company logo of the specified company to the picture provided.
   *
   * @param companyId - The ID of the company, whose logo will be set.
   * @param picture - An array of bytes containing the picture to set.
   */
  setCompanyLogo(companyId: number, picture: Uint8Array): Observable<null> {
    return this.pictureService.SetCompanyPictureLogo(companyId, picture);
  }

  addCompanyCommunity(company: Company): Observable<number> {
    const url = this.baseUrl + '/addCompanyCommunity';
    company['originId'] = 2;
    return this.http.post<number>(url, company, { headers: this.buildPostHeaders() });
  }

  addCompanyPmc(company: Company): Observable<number> {
    const url = this.baseUrl + '/addCompanyPMC';
    company['originId'] = 2;
    return this.http.post<number>(url, company, { headers: this.buildPostHeaders() });
  }

  /**
   * Loads the company logo.
   *
   * @param companyId - The ID of the company.
   */
  loadCompanyLogo(companyId: number) {

    this.getCompanyLogo(companyId).subscribe(pic => {

      const reader =  new FileReader();
      const blob = new Blob( [ new Uint8Array(pic) ], { type: 'image/jpeg' } );
      if (blob.size > 0) {
        const file = new File([blob], 'logo', {type: 'image/jpeg', lastModified: Date.now()});
        reader.onload = (event: any) => {
          this.companyLogo.next(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        this.companyLogo.next(null);
      }
    });
  }

  /**
   * Sets the active community for this site.
   */
  setCommunity(community: Community): void {
    this.isCommunity.next(true);

    // Set the company name.
    this.companyInfo.next(community.name);

    // Load and set the company logo.
    this.loadCompanyLogo(community.companyId);

    // Set the community ID.
    this.communityId.next(community.communityId);
  }
}
