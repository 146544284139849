import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actions-visitor',
  templateUrl: './actions-visitor.component.html',
  styleUrls: ['./actions-visitor.component.css']
})
export class ActionsVisitorComponent implements OnInit {
  constructor(private router: Router) { }
  ngOnInit() {}
  settings() {
      this.router.navigateByUrl('/app/visitor/settings');
  }
}
