import { User } from './user';

export class NewUser {
    firstName: string;
    lastName: string;
    email: string;
    isDisabled = false;
    peopleId: number;
    password: string;
    userName: string;

    constructor(user: User = null) {
        // Build a NewUser object from an existing user object.
        if (user !== undefined && user !== null) {
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.email = user.email;
            this.peopleId = user.peopleId;
            this.password = null;
            this.userName = user.userName;
        }
    }
}
