
export namespace stringHelper  {

 export function base64ToArrayBuffer(base64: any) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
       const ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 export function  jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
}
