import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatSidenav } from '@angular/material';

import { filter } from 'rxjs/operators';

/* Components */
import { AccountDetailsComponent } from '../account/account-details/account-details.component';

/* Services */
import { LoginService } from '../services/login.service';
import { MenuService } from '../services/menu.service';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  public links = [];

  public routerTitle: string;
  public routerFilter: string;
  public loading: boolean;
  public impersonate = false;
  public readonly MENU_ITEM_ACCOUNT = 130;
  public readonly MENU_ITEM_LOGOUT = 132;
  @ViewChild('side') side: MatSidenav;
  @ViewChild('breadcrumb') breadcrumb: BreadcrumbComponent;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private menuService: MenuService,
    private dialog: MatDialog,
    url: ActivatedRoute) {

    this.populateMenu();
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .forEach(e => {
        this.routerTitle = url.root.firstChild.snapshot.data.title;
      });
  }

  @HostBinding('class.is-open')
  collapse = false;

  public populateMenu() {
    this.links = this.menuService.mapMenuToAngularRoutes(LocalStorageHelper.getUserMenu());
    const accountMenu = this.menuService.getItemMenuById(this.links, this.MENU_ITEM_ACCOUNT);
   if (accountMenu !== null) {
      accountMenu.click =  () => this.openAccountDialog(this);
    }

    const logoutMenu = this.menuService.getItemMenuById(this.links, this.MENU_ITEM_LOGOUT);
   if (logoutMenu !== null) {
    logoutMenu.click =  () => this.logout(this);
    }
  }

  ngOnInit() {
    this.routerFilter = '';
    this.menuService.reloadMenu.subscribe(_ => {
      this.populateMenu();
    });
  }
  toggle() {
    this.collapse = !this.collapse;
  }

  logout(that: SideNavComponent) {
    that.loginService.logout();
    that.router.navigateByUrl('/login');
  }

  openAccountDialog(that: SideNavComponent) {
    const userImpersonate = LocalStorageHelper.getUserImpersonatedInfo();
    if (userImpersonate) {
      this.impersonate = true;
    }
    const dialogRef = that.dialog.open(AccountDetailsComponent, {
      width: '800px',
      data: {isImpersonated : this.impersonate},
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
