import { AfterViewInit, Component, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatSelect,
} from '@angular/material';

import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';

import { RoleService } from '../../services/role.service';
import { User } from '../../model/user';
import { People } from '../../model/people';
import { PeopleService } from '../../services/people.service';
import { EmailService } from '../../services/email.service';
import { SiteHelper } from '../../helpers/site-helper';
import { SearchPeopleDialogComponent } from '../../app-dialogs/search-people-dialog/search-people-dialog.component';
import { Role } from 'src/app/model/role';
import { RolesEntry } from 'src/app/model/roles-entry';
import { RoleTypes } from 'src/app/model/role-type';
import { CommunityService } from 'src/app/services/community.service';
import { PropertyManagmentCompanyService } from 'src/app/services/property-managment-company.service';
import { CompanyService } from 'src/app/services/company.service';
import { TypeCompany, Company, CompanyEntry } from 'src/app/model/company';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';
import { UserRoleCompany } from 'src/app/model/user-role-company';

export enum TypeProfile {
  GridStaff = 1,
  CommunityStaff = 2,
  ManagementCompanyStaff = 3,
  TenantOwner = 4,
}
@Component({
  selector: 'app-users-add-edit-dialog',
  templateUrl: './users-add-edit-dialog.component.html',
  styleUrls: ['./users-add-edit-dialog.component.css']
})

export class UsersAddEditDialogComponent implements OnInit {
  public model: User;
  public peopleSelect: People;
  public roles: RolesEntry[] = [];
  public roleAll: RolesEntry[] = [];
  public userFormRole: FormGroup;
  public rolesToWipe: Role[] = [];
  public rolesToAdd: Role[] = [];
  public companies: CompanyEntry[] = [];
  public companiesFilter: CompanyEntry[] = [];
  public userRoleCompany: UserRoleCompany[] = [];
  public loading: boolean;
  public isUpdate: boolean;
  public people: string;
  public roleSelected: RolesEntry;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('staffType') staffType: MatSelect;
  public roleTableColumns: string[] = ['select', 'name'];
  public roleDataSource = new MatTableDataSource<RolesEntry>();

  constructor(public dialogRef: MatDialogRef<UsersAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private roleService: RoleService,
    private peopleService: PeopleService,
    private emailService: EmailService,
    private cdr: ChangeDetectorRef,
    private companyService: CompanyService) {
    this.model = new User(this.fb);
    this.userFormRole = this.model.buildFormGroup();
  }

  ngOnInit() {
    this.loading = true;
    this.peopleSelect = new People(null);
    this.loading = true;
    forkJoin(this.roleService.getAll(),
        this.companyService.getByType(0)).subscribe(result => {
          this.roleAll =  result[0];
          this.companies = result[1];
          this.loading = false;
    if (this.data.update === true) {
      this.loading = true;
      this.initSelectedRoles();
      this.isUpdate = true;
      forkJoin(this.peopleService.GetForUser(this.data.model.user.userId)).subscribe(r => {
        this.peopleSelect = r[0];
        this.people = this.displayFn(this.peopleSelect);
        this.data.model.user.people = this.people;
        this.data.model.user.peopleId = this.peopleSelect.peopleId;
        this.model.fromObject(this.data.model.user);
        this.loading = false;
        this.staffType.value = this.getProfileByRoleType(this.data.model.roles[0].roleType);
        this.bindRoles(this.staffType.value);
      });
    }
  });
  }
  initSelectedRoles() {
    this.data.model.roles.forEach(ro => {
        this.userRoleCompany.push({roleId: ro.roleId, companyId: ro.companyId});
    });
  }

  getProfileByRoleType(roleType: number): number {
    switch (roleType) {
      case RoleTypes.Tenant:
      case RoleTypes.Owner:
        return TypeProfile.TenantOwner;
      case RoleTypes.CommunityAdmin:
      case RoleTypes.CommunityOperator:
        return TypeProfile.CommunityStaff;
      case RoleTypes.ManagementCompanyAdmin:
      case RoleTypes.ManagementCompanyOperator:
        return TypeProfile.ManagementCompanyStaff;
      case RoleTypes.GridAdmin:
      case RoleTypes.GridOperator:
        return TypeProfile.GridStaff;
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.roleDataSource.filter = filterValue;
  }

  selectRole(row) {
    this.roleSelected  = row;
    const initCompanies = JSON.parse(JSON.stringify(this.companies));
    this.companiesFilter  = [];
      if (row.roleTypeId === RoleTypes.Owner || row.roleTypeId === RoleTypes.Tenant || row.roleTypeId === RoleTypes.Custom
         || row.roleTypeId === RoleTypes.CommunityAdmin || row.roleTypeId === RoleTypes.CommunityOperator) {
        this.companiesFilter = initCompanies.filter(c => c.typeCompany === TypeCompany.Community);
      } else if (row.roleTypeId === RoleTypes.ManagementCompanyAdmin || row.roleTypeId === RoleTypes.Custom
        || row.roleTypeId === RoleTypes.ManagementCompanyOperator) {
        this.companiesFilter = initCompanies.filter(c => c.typeCompany === TypeCompany.PropertyMAnagementCompany);
      }
      if (this.userRoleCompany.length > 0 && this.userRoleCompany.filter(urc => urc.roleId === row.roleId).length > 0) {
        this.companiesFilter.forEach(cp => {
          if (this.userRoleCompany.filter(ro => ro.companyId === cp.companyId && ro.roleId === row.roleId).length > 0) {
            cp.selected = true;
          }
      });
    }
    if (this.companiesFilter.length === 0) {
        // This happens when the role does not have a company realted or the role can see all the companies in the system
        this.userRoleCompany.push({ roleId: this.roleSelected.roleId, companyId: 0});
      }
    this.cdr.detectChanges();
  }
  selectCompany(company: CompanyEntry ) {
    if (this.userRoleCompany.filter(ro => ro.companyId === company.companyId
      && ro.roleId ===  this.roleSelected.roleId).length > 0) {
      this.userRoleCompany.splice(this.userRoleCompany.findIndex(ro => ro.companyId === company.companyId
        && ro.roleId ===  this.roleSelected.roleId), 1);
    } else {
      this.userRoleCompany.push({ roleId: this.roleSelected.roleId, companyId: company.companyId});
    }
    this.checkRole(this.roleSelected);
    console.log(this.userRoleCompany);
  }

  checkRole(role: RolesEntry) {
   const count = this.userRoleCompany.filter(r => r.roleId === role.roleId).length;
    role.selected = count > 0;
  }


  onChangeRole(e, role): void {
    role.selected = e.checked;
    if (!role.selected) {
      this.removeRole(role);
    } else {
      this.addRole(role);
      this.selectRole(role);
    }
  }
  getRoles(typeProfile: number) {
    switch (typeProfile) {
      case TypeProfile.GridStaff:
        return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.GridAdmin || ro.roleTypeId === RoleTypes.GridOperator
          || ro.roleTypeId === RoleTypes.Custom);
      case TypeProfile.CommunityStaff:
        return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.CommunityOperator || ro.roleTypeId === RoleTypes.CommunityAdmin
          || ro.roleTypeId === RoleTypes.Custom);
      case TypeProfile.ManagementCompanyStaff:
        return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.ManagementCompanyAdmin
        || ro.roleTypeId === RoleTypes.ManagementCompanyOperator || ro.roleTypeId === RoleTypes.Custom);
      case TypeProfile.TenantOwner:
        return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.Tenant
          || ro.roleTypeId === RoleTypes.Owner || ro.roleTypeId === RoleTypes.Custom);
        default: return this.roleAll;
    }
  }
  bindRoles(typeProfile) {
    this.roles = [];
    this.loading = true;
    this.roles = this.getRoles(typeProfile);
    for (const role of this.roles) {
        if (this.isUpdate) {
          role.selected = this.userRoleCompany.filter(r => r.roleId === role.roleId).length > 0;
        }
    }
      this.roles = this.roles.sort((b, a) => Number(a.selected) - Number(b.selected));
      this.roleDataSource = new MatTableDataSource(this.roles);
      this.roleDataSource.paginator = this.paginator;
      this.roleDataSource.sort = this.sort;
      this.loading = false;
  }

  openDialogPeople(): void {
    const dialogRef = this.dialog.open(SearchPeopleDialogComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.data.people.userId && (result.data.people.userId !== this.model.userId)) {
          SiteHelper.openDialogAlert('Warning', 'This entry already has a user associated to it.', this.dialog);
        } else {
          this.peopleSelect = result.data.people;
          this.model.peopleId = result.data.people.peopleId;
          this.userFormRole.controls['people'].setValue(this.displayFn(this.peopleSelect));
        }
      }
    });
  }

  displayFn(people?: People): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
      people.lastName : '';
  }

  addRole(role: RolesEntry): void {
    const idx = this.rolesToWipe.findIndex(x => x.roleId === role.roleId);
    if (idx > -1) {
      this.rolesToWipe.splice(idx, 1);
    }

    if (this.data.update === false ||
      (this.data.update === true && this.data.model.roles.findIndex(r => r.roleId === role.roleId) === -1)) {
      this.rolesToAdd.push(role as Role);
    }
  }

  removeRole(role: RolesEntry): void {
    const idx = this.rolesToAdd.findIndex(x => x.roleId === role.roleId);
    if (idx > -1) {
      this.rolesToAdd.splice(idx, 1);
    }
    if (this.data.update === true) {
      this.rolesToWipe.push(role as Role);
    }
    if (this.userRoleCompany.length > 0) {
      this.userRoleCompany = this.userRoleCompany.filter(rol => rol.roleId  !== role.roleId);
    }
    this.companiesFilter.forEach(c => c.selected = false);
  }

  close() {
    const entity = this.model.toDto();
    this.dialogRef.close({
      valid: true,
      roles: this.data.model != null ? this.data.model.roles : [],
      entity: entity,
      people: this.peopleSelect,
      rolesCompanies: this.userRoleCompany
    });
  }
  noValidRole() {
    return !this.peopleSelect.relationshipId
      && this.rolesToAdd.filter(r => r.roleTypeId === RoleTypes.Owner || r.roleTypeId === RoleTypes.Tenant).length > 0;
  }

  onSubmit(): void {
    if (!this.userFormRole.valid) {
      return;
    }
    if (this.noValidRole()) {
      SiteHelper.openDialogAlert('Warning',
        'you can not associate the owner or tenant role to this person because it has no relationship.', this.dialog);
      return;
    }
    this.model.firstName = this.peopleSelect.firstName;
    this.model.lastName = this.peopleSelect.lastName;
    this.close();
  }

  onChange(e) {
   this.bindRoles(e.value);
  }

  onCancel(): void {
    if (this.userFormRole.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }

}
