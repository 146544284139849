import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { Position } from '../model/community-position';

export class PeopleStaff {
    peopleId: number;
    completeName: string;
    constructor(id: number, cn: string) {
        this.peopleId = id;
        this.completeName = cn;
    }
    toString() {
        return this.completeName;
    }
} 

export class Staff extends BaseForm {
    peopleCommunityPositionId = 0;
    peopleId = 0;
    community_PositionId = 0;

    @FormField('peopleStaff', ['', Validators.required])
    peopleStaff = new PeopleStaff(0, '');

    @FormField('positionStaff', ['', Validators.required])
    positionStaff = new Position(0, '');

    @FormField('isActive', [true])
    isActive = true;

    @FormField('showInContacts', [true])
    showInContacts = true;

    @FormField('startDate', ['', Validators.required])
    startDate = new Date();
}
