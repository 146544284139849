import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Community } from '../model/community';
import { Observable } from 'rxjs';
import { CommunityTree } from '../model/community-tree';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class CommunityService extends AbstractDataProviderService<Community> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/community', http, appConfig, LevelFilter.Community);
  }

  getAllTreePaginated(pageIndex: number, pageSize: number, communityId: number,
    propertyManagementCompanyId: number): Observable<CommunityTree[]> {
    const queryString = '/allPagedByParams/' + pageIndex.toString() + '/' + pageSize.toString() +
       '/' + propertyManagementCompanyId +  '/' + communityId;
    return this.http.get<CommunityTree[]>(this.baseUrl + queryString ,  { headers: this.buildGetHeaders() });
  }

  getAllAllowForAreaList(): Observable<Community[]> {
    return this.http.get<Community[]>(this.baseUrl + '/allAllowForAreaList/' ,  { headers: this.buildGetHeaders() });
  }

  getAllAllowForUnitList(): Observable<Community[]> {
    return this.http.get<Community[]>(this.baseUrl + '/allAllowForUnitList/'  ,  { headers: this.buildGetHeaders() });
  }

  getBySubDomain(domain: string): Observable<Community> {
    return this.http.get<Community>(this.baseUrl + '/byDomain/' + domain, { headers: this.buildGetHeaders() });
  }

}
