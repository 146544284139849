import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Observable, forkJoin, Subject } from 'rxjs';
import {
  MatDialog,
  MatSelect,
  MatSnackBar,
  MatInput
} from '@angular/material';

// Services
import { AddressService } from '../../services/address.service';
import { AddressTypeService } from '../../services/address-type.service';
import { CompanyTypeService } from '../../services/company-type.service';
import { CompanyService } from '../../services/company.service';
import { StateService } from '../../services/state.service';
import { CityService } from '../../services/city.service';
import { TaxClassificationService } from '../../services/tax-classification.service';
import { ZipCodeService } from '../../services/zip-code.service';
import { PhoneNumberService } from '../../services/phone-number.service';
import { PhoneTypeService } from '../../services/phone-type.service';
import { EmailAddressTypeService } from '../../services/email-address-type.service';
import { EmailService } from '../../services/email.service';

// Models
import { AddressTableEntry } from '../../model/address-table-entry';
import { AddressType } from '../../model/address-type';
import { Company } from '../../model/company';
import { CompanyType } from '../../model/company-type';
import { State } from '../../model/state';
import { City } from '../../model/city';
import { TaxClassification } from '../../model/tax-classification';
import { ZipCode } from '../../model/zip-code';
import { PhoneType } from '../../model/phone-type';
import { PhoneNumber } from '../../model/phone-number';
import { EmailAddressType } from '../../model/email-address-type';
import { Email } from '../../model/email';

// Dialogs
import { AlertDialogComponent } from '../../app-dialogs/alert-dialog/alert-dialog.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.css']
})
export class CompanyAddComponent implements OnInit  {

  public MESSAGE_ERROR_ZIPCODE = 'Unable to add address, city or state does not belong to that zip code';
  public TITLE_WARNING = 'Warning';
  public MESSAGE_ZIPCODE_NOT_EXISTS = 'zip code does not exist';
  public LENGTH_ZIPCODE = 5;
  public companyId: number;
  public formCompany: FormGroup;
  public addressItems: FormArray;
  public emailsItems: FormArray;
  public phonesItems: FormArray;

  public addresses: AddressTableEntry[] = [];
  public addressTypes: AddressType[] = [];
  public companyTypes: CompanyType[] = [];
  public companies: Company[] = [];
  public states: State[] = [];
  public cities: City[][] = [];
  public taxClassifications: TaxClassification[][];
  public zipCodes: Observable<ZipCode[]>;
  public allZips: ZipCode[][];

  public phones: PhoneNumber[] = [];
  public phoneTypes: PhoneType[] = [];
  public emails: Email[] = [];
  public emailAddressTypes: EmailAddressType[];

  public model: Company;
  public entity: Company;
  public addressModel: AddressTableEntry;
  public emailModel: Email;
  public phoneModel: PhoneNumber;
  public loading = true;
  public selectedFile: File;
  public imgSrc = '../../../assets/images/noimage.png';
  public changePicture = false;
  public arrayBuffer: any;

  public initComplete = new Subject<boolean>();


  @ViewChild('stateSelect') stateSelect: MatSelect;
  @ViewChild('citySelect') citySelect: MatSelect;
  @ViewChild('zipCodeInput') zipCodeInput: MatInput;
  @ViewChild('eINNumber') eINNumber: MatInput;

  zipCodeCtrl = new FormControl();
  filteredOptionsZipCodes: Observable<any[]>;

  constructor(private companyTypeService: CompanyTypeService,
    private companyService: CompanyService,
    private taxClassificationService: TaxClassificationService,
    private fb: FormBuilder,
    private addressService: AddressService,
    private addressTypeService: AddressTypeService,
    private stateService: StateService,
    private cityService: CityService,
    private zipCodeService: ZipCodeService,
    private phoneTypeService: PhoneTypeService,
    private emailAddressTypeService: EmailAddressTypeService,
    private emailService: EmailService,
    private phoneNumberService: PhoneNumberService,
    private breadcrumbService: BreadcrumbService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
    this.model = new Company(this.fb);
    this.formCompany = this.model.buildFormGroup();
    this.addressItems = this.formCompany.get('address') as FormArray;
    this.emailsItems = this.formCompany.get('emails') as FormArray;
    this.phonesItems = this.formCompany.get('phones') as FormArray;

  }

  ngOnInit() {
    this.loading = true;
    this.addressItems.removeAt(0);
    this.phonesItems.removeAt(0);
    this.emailsItems.removeAt(0);

    const phoneTypesObservable = this.phoneTypeService.getAll();
    const emailAddressTypesObservable = this.emailAddressTypeService.getAll();
    const addressTypesObservable = this.addressTypeService.getAll();
    const stateObservable = this.stateService.getAll();
    forkJoin(
      phoneTypesObservable,
      emailAddressTypesObservable,
      addressTypesObservable,
      stateObservable,
      this.companyService.getAll(),
      this.taxClassificationService.getAll(),
      this.companyTypeService.getAll(),
    )
      .subscribe(results => {
        this.phoneTypes = results[0];
        this.emailAddressTypes = results[1];
        this.addressTypes = results[2];
        this.states = results[3];
        this.companies = results[4];
        this.taxClassifications = results[5];
        this.companyTypes = results[6];
        this.initComplete.next(true);
      });
  }

  public watchInit() {
    return this.initComplete.asObservable();
  }

  initCompany(): void {
    this.entity = new Company(this.fb);
    if (this.companyId) {
      forkJoin(this.companyService.getById(this.companyId),
        this.addressService.getByCompanyId(this.companyId),
        this.phoneNumberService.getByCompanyId(this.companyId),
        this.emailService.getByCompanyId(this.companyId),
        this.companyService.getCompanyLogo(this.companyId)).subscribe(results => {
          this.entity = results[0];
          this.loadAddress(results[1]);
          this.loadPhones(results[2]);
          this.loadEmails(results[3]);
          this.getLogo(results[4]);
          this.model.fromObject(this.entity);
          this.loading = false;
        });
    } else {
        this.loading = false;
        this.addAdress();
        this.addPhone();
        this.addEmail();
      this.loading = false;
    }
  }

  getLogo(pic: any) {
    this.loading = true;
    const reader = new FileReader();
    const blob = new Blob([new Uint8Array(pic)], { type: 'image/jpeg' });
    if (blob.size > 0) {
      const file = new File([blob], 'Logo', { type: 'image/jpeg', lastModified: Date.now() });
      this.selectedFile = file;
      reader.onload = (event: any) => {
        this.imgSrc = event.target.result;
        this.loading = false;
      };
      reader.readAsDataURL(file);
    }
  }

  loadAddress(add: AddressTableEntry[]): void {
    this.loading = true;
    add.forEach((ad) => {
      this.addAdress(ad);
    }, this.loading = false);
    if (add.length === 0) {
      this.addAdress();
      this.loading = false;
    }
  }

  loadPhones(phones: PhoneNumber[]): void {
    this.loading = true;
    phones.forEach((p) => {
        this.addPhone(p);
    }, this.loading = false);
    if (phones.length === 0) {
      this.addPhone();
      this.loading = false;
    }
  }

  loadEmails(emails: Email[]): void {
    this.loading = true;
    emails.forEach((email) => {
        this.addEmail(email);
    }, this.loading = false);
    if (emails.length === 0) {
      this.addEmail();
      this.loading = false;
    }
  }

  onStateSelectionChange(e, i: number): void {
    this.addressModel.stateId = e.value;
    this.stateSelect.value = e.value;
    this.addressModel.stateName = this.states.find(x => x.stateId === e.value).name;
    this.cityService.getByStateId(e.value).subscribe(x => {
      this.cities[i] = x;
      this.addressModel.cityId = null;
    });
  }

  updateAddress(value: string, i: number, isForm = false): void {
    if (value === undefined || value.length !== this.LENGTH_ZIPCODE) {
      return;
    }

    this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
      const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
      this.cityService.getByStateId(state.stateId).subscribe(x => {
        this.cities[i] = x;
        const city = x.find(c => c.name === zipCode[0].city);
          this.formCompany.get('address').get(i.toString()).get('stateId').setValue(state.stateId);
          this.formCompany.get('address').get(i.toString()).get('cityId').setValue(city.cityId);
          this.formCompany.get('address').get(i.toString()).get('zipCode').setValue(value);
          this.formCompany.get('address').get(i.toString()).get('cityId').enable();
          this.formCompany.get('address').get(i.toString()).get('stateId').enable();
        this.addresses[i].zipCodeId = zipCode[0].zipCodeId;
      });
    });
  }

  validateEinNumber(einNumber: string): void {
    if (einNumber.length === 10) {
      this.companyService.getByEinNumber(einNumber).subscribe(result => {
        if (result.length > 0) {
          if (!(this.companyId && result.length === 1 && result[0].companyId === this.companyId)) {
            this.openDialog('Error', 'The EIN already existe');
            einNumber = '';
          }
        }
      });
    }
  }

  clearZipCode(): void {
    this.addressModel.cityId = null;
    this.addressModel.cityName = null;
    this.addressModel.stateId = null;
    this.addressModel.stateName = null;
    this.addressModel.zipCode = null;
    this.addressModel.zipCodeId = null;
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Message.indexOf('zipcpde') >= 0) {
        this.clearZipCode();
      }
    });
  }

  get addressFormGroup() {
    return this.formCompany.get('address') as FormArray;
  }

  get emailsFormGroup() {
    return this.formCompany.get('emails') as FormArray;
  }

  get phonesFormGroup() {
    return this.formCompany.get('phones') as FormArray;
  }

  addAdress(entity: AddressTableEntry = null): void {
    this.addressItems.push(this.createAddress(entity));
    if (entity) {
      this.updateAddress(entity.zipCode, this.addressItems.length - 1);
    }
  }

  createAddress(entity: AddressTableEntry = null): FormGroup {
    this.addressModel = new AddressTableEntry(this.fb);
    const formGroup = this.addressModel.buildFormGroup();
    if (entity) {
      this.addressModel.fromObject(entity);
    } else {
      formGroup.controls['cityId'].disable();
      formGroup.controls['stateId'].disable();
    }
    this.addresses.push(this.addressModel);
    return formGroup;
  }

  removeAddress(index) {
    if (this.addresses[index].addressId > 0) {
      this.addresses[index].isDeleted = true;
    } else {
      this.addresses.splice(index, 1);
      this.cities.splice(index, 1);
    }
    this.addressItems.removeAt(index - 1);
  }

  addPhone(entity: PhoneNumber = null): void {
    this.phonesItems.push(this.createPhone(entity));
  }

  createPhone(entity: PhoneNumber = null): FormGroup {
    this.phoneModel = new PhoneNumber(this.fb);
    const formGroup = this.phoneModel.buildFormGroup();
    if (entity) {
      this.phoneModel.fromObject(entity);
    }
    this.phones.push(this.phoneModel);
    return formGroup;
  }

  removePhone(index: number): void {
    if (this.phones[index].phoneNumberId > 0) {
      this.phones[index].isDeleted = true;
    } else {
      this.phones.splice(index, 1);
    }
    this.phonesItems.removeAt(index - 1);
  }

  addEmail(entity: Email = null): void {
    this.emailsItems.push(this.createEmail(entity));
  }

  createEmail(entity: Email = null): FormGroup {
    this.emailModel = new Email(this.fb);
    const formGroup = this.emailModel.buildFormGroup();
    if (entity) {
      this.emailModel.fromObject(entity);
    }
    this.emails.push(this.emailModel);
    return formGroup;
  }

  removeEmail(index) {
    if (this.emails[index].emailId > 0) {
      this.emails[index].isDeleted = true;
    } else {
      this.emails.splice(index, 1);
    }
    this.emailsItems.removeAt(index - 1);
  }

  private validateAddress(): Observable<boolean> {
    return new Observable((obs: any) => {
      this.zipCodeService.getById(this.addressModel.zipCodeId).subscribe(z => {
        if (z.city !== this.addressModel.cityName) {
          obs.next(false);
          obs.complete();
        } else {
          obs.next(true);
          obs.complete();
        }
      });
    });
  }

  onFileChanged(file: FileList) {
    this.selectedFile = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgSrc = event.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
    this.changePicture = true;
  }

  saveCompany(entityTypeToSave: string): Observable<number> {
    this.loading = true;
    this.model = this.model.toDto();
    this.addresses.forEach((add, index) => this.addresses[index] = add.toDto());
    this.phones.forEach((p, index) => this.phones[index] = p.toDto());
    this.emails.forEach((e, index) => this.emails[index] = e.toDto());
    this.model.address_list = this.addresses;
    this.model.phone_list = this.phones;
    this.model.email_list = this.emails;
    if (!this.companyId) {
      return this.addCompany(entityTypeToSave);
    } else {
      return this.editCompany(entityTypeToSave);
    }
  }

  addCompany(entityTypeToSave: string): Observable<number> {
    this.loading = true;
    return new Observable((obs: any) => {
      if (entityTypeToSave === 'Community') {
        this.companyService.addCompanyCommunity(this.model).subscribe(companyResult => {
          this.model.companyId = companyResult;
          this.savePicture();
          obs.next(companyResult);
          obs.complete();
          this.loading = false;
          this.breadcrumbService.updateBreadcrumb.next(true);
        });
      } else {
        this.companyService.addCompanyPmc(this.model).subscribe(companyResult => {
          this.model.companyId = companyResult;
          this.savePicture();
          obs.next(companyResult);
          obs.complete();
          this.loading = false;
          this.breadcrumbService.updateBreadcrumb.next(true);
        });
      }
    });
  }

  savePicture() {
    if (this.selectedFile && this.changePicture) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.arrayBuffer = reader.result;
        this.companyService.setCompanyLogo(this.model.companyId, this.arrayBuffer).subscribe();
      };
      reader.readAsArrayBuffer(this.selectedFile);
    }
  }

  editCompany(entityTypeToSave: string): Observable<number> {
    this.loading = true;
    return new Observable((obs: any) => {
      this.companyService.update(this.model).subscribe();
      this.addresses.forEach(add => {
        if (!add.addressId) {
          this.addressService.add(add).subscribe(result => {
            this.addressService.linkAddressToCompany(result.id, this.model.companyId,
              add.isPrefered).subscribe();
          });
        } else if (add.isDeleted) {
          this.addressService.unlinkAddressFromCompany(add.addressId, this.model.companyId).subscribe();
        } else {
          this.addressService.update(add).subscribe();
        }
      });
      this.phones.forEach(pho => {
        if (!pho.phoneNumberId) {
          this.phoneNumberService.add(pho).subscribe(result => {
            this.phoneNumberService.linkPhoneNumberToCompany(result.id, this.model.companyId).subscribe();
          });
        } else if (pho.isDeleted) {
          this.phoneNumberService.unlinkPhoneNumberFromCompany(pho.phoneNumberId, this.model.companyId).subscribe();
        } else {
          this.phoneNumberService.update(pho).subscribe();
        }
      });
      this.emails.forEach(email => {
        if (!email.emailId) {
          this.emailService.add(email).subscribe(result => {
            this.emailService.linkEmailToCompany(result.id, this.model.companyId).subscribe();
          });
        } else if (email.isDeleted) {
          this.emailService.unlinkEmailFromCompany(email.emailId, this.model.companyId).subscribe();
        } else { this.emailService.update(email).subscribe(); }
      });
      this.savePicture();
      obs.next(this.model.companyId);
      obs.complete();
      this.loading = false;
    });
  }
}
