import { Injectable, Inject } from '@angular/core';
import { PackageLog } from '../model/packageLog';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class PackageLogService extends AbstractDataProviderService<PackageLog> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/packages/log', http, appConfig, LevelFilter.Unit);
  }

  getAllPaged(pageIndex: number, pageSize: number, filter?: string): Observable<PackageLog[]> {
      this.setBreadcrumbParams();
      const queryString = '/allPaged/' + pageIndex + '/' + pageSize + '/' + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
      this.searchParamsBreadcrumb.communityId + '/' +  this.searchParamsBreadcrumb.areaId
      + '/' +  this.searchParamsBreadcrumb.unitId +  (filter ? '/' + filter : '');
    return this.http.get<PackageLog[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getPackageLogDetail(id: string, ishot: string): Observable<PackageLog[]> {
    const queryString = '/getById/' + id + '/' + ishot;
    return this.http.get<PackageLog[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}
