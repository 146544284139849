import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NewCreditCard } from 'src/app/model/newCreditCard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmaugService } from 'src/app/services/smaug.service';
import { CreditCardForm } from 'src/app/model/credit-card-form';

@Component({
  selector: 'app-add-credit-card-dialog',
  templateUrl: './add-credit-card-dialog.component.html',
  styleUrls: ['./add-credit-card-dialog.component.css']
})
export class AddCreditCardDialogComponent implements OnInit {
  model: CreditCardForm;
  userSourceAccountNumber = '';
  paypointSourceAccountNumber = '';
  applicationId = 1;
  public years: string[] = [];
  public months: string[] = [];
  registerForm: FormGroup;
  submitted = false;

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AddCreditCardDialogComponent>,
    private fb: FormBuilder,
    private smaugService: SmaugService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userSourceAccountNumber = data.userSourceAccountNumber;
    this.paypointSourceAccountNumber = data.paypointSourceAccountNumber;

    this.model = new CreditCardForm(this.fb);
    this.registerForm = this.model.buildFormGroup();
  }

  ngOnInit() {
    const currentYear = new Date().getFullYear();
    this.model.year = currentYear.toString().substring(2);
    this.registerForm.get('year').setValue(this.model.year);
    for (let i = 1 ; i <= 12; i++) {
      this.months.push(i < 10 ? '0' + i : i.toString());
    }
    for (let i = currentYear ; i <= currentYear + 20; i++) {
      this.years.push(i.toString().substring(2, i.toString().length ));
    }
    this.registerForm.get('month').setValue(this.months[0]);
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    if (this.registerForm.invalid) {
       return;
    }

    const entity = this.model.toDto();
    const creditCard: NewCreditCard = {
      userSourceAccountNumber: this.userSourceAccountNumber,
      paypointSourceAccountNumber: this.paypointSourceAccountNumber,
      applicationId: this.applicationId,
      cardName: entity.cardName,
      cardNumber: entity.cardNumber,
      cardExpirationMMYY: entity.month + entity.year,
      cardCVV: entity.cardCVV,
      zip: entity.zip
    };
    this.loading = true;
    this.smaugService.addCreditCard(creditCard).subscribe(result => {
      this.loading = false;
      this.dialogRef.close(creditCard);
    }, error => {
      this.snackbar.open(error, '', { duration: 5000 });
      this.loading = false;
    });
  }

}
