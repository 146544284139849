import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Unit extends BaseForm {
    unitId = 0;

    areaId = 0;

    @FormField('displayName', [])
    displayName = '';

    @FormField('number', [])
    number = '';

    @FormField('apt', [])
    apt = '';

    @FormField('unitCategoryId', ['', Validators.required])
    unitCategoryId = 0;

    isDeleted = false;

    originId: number;

    areaTypeId?: number;

}
