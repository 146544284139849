import { Validators, FormArray } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { AddressTableEntry } from './address-table-entry';

export class Email extends BaseForm  {

    emailId = 0;

    @FormField('address', ['', [Validators.required, Validators.email]])
    address = '';


    @FormField('emailAddressTypeId', [1, Validators.required])
    emailAddressTypeId = 1;

    isDeleted = false;

    @FormField('isPreferred', [false, Validators.required])
    isPreferred = false;

    /* The creation date of this record.
    */
   creationDate: Date = null;

   /**
    * The ID of the user that created this record.
    */
   creationUserId = 0;
   originId = 2;
   /**
    * The modification date of this record.
    */
   modificationDate: Date = null;

   /**
    * The ID of the user that last modified this record.
    */
   modificationUserId = 0;

   relationshipId = null;
}

