import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { catchError } from 'rxjs/operators';
import { SearchAddress } from '../model/search-address';
import { SearchPeople } from '../model/search-people';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    if (AppConstants.EndpointNames.SearchService in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.SearchService];
      this.baseUrl = endpoint;
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  private buildGetHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let getHeaders = new HttpHeaders();
    getHeaders = getHeaders.set('Authorization', 'bearer ' + token);

    return getHeaders;
  }

  public findAddresses(termSearch: string): Observable<SearchAddress[]> {
    const requestUrl = this.baseUrl + `/address/autocomplete?v=${ termSearch }`;
    return this.http.get<SearchAddress[]>(requestUrl)
    .pipe(
      catchError(this.handleError)
    );
  }

  public findPeopleByUnitId(unitId: number): Observable<SearchPeople[]> {
    const requestUrl = this.baseUrl + `/contacts/basedOnUnit/${ unitId }`;
    return this.http.get<SearchPeople[]>(requestUrl)
    .pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}`);
    }

    return throwError(
      `Connection error with the service. Code ${error.status}`);
  }

}
