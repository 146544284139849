import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

/* Components */
import { ConfirmForgotPasswordComponent } from '../confirm-forgot-password/confirm-forgot-password.component';
import { HomeComponent } from '../home/home.component';
import { LandingCommunityComponent } from '../landing-community/landing-community.component';
import { LoginComponent } from '../login/login.component';
import { NewsFeedComponent } from '../news-feed/news-feed.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

import { AuthGuardService } from '../services/auth-guard.service';
import { RegisterComponent } from '../register/register.component';
import { ConfirmEmailComponent } from '../confirm-email/confirm-email.component';
import { AnonymousPaymentFindPeopleComponent } from '../anonymous-payment-find-people/anonymous-payment-find-people.component';
import { ClearCacheMenuComponent } from '../clear-cache-menu/clear-cache-menu.component';
import { ServiceInActionListComponent } from '../services-in-action/service-in-action-list/service-in-action-list.component';
// import { RoleListComponent } from '../security/role-list/role-list.component';

const appRoutes: Routes = [
  {
    path: 'app',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    data: {
      'title': null
    },
    children: [
      { path: 'account', loadChildren: 'src/app/account/account.module#AccountModule' },
      { path: 'area', loadChildren: 'src/app/area/area.module#AreaModule' },
      { path: 'community/units', loadChildren: 'src/app/unit/unit.module#UnitModule' },
      { path: 'community/pictures', loadChildren: 'src/app/picture/picture.module#PictureModule' },
      { path: 'company', loadChildren: 'src/app/company/company.module#CompanyModule' },
      { path: 'community', loadChildren: 'src/app/community/community.module#CommunityModule' },
      { path: 'ledger', loadChildren: 'src/app/ledger/ledger.module#LedgerModule' },
      { path: 'docs', loadChildren: 'ThorinFrontend/src/app/app.thorin.module#AppThorinModule' },
      { path: 'community/residents', loadChildren: 'src/app/resident/resident.module#ResidentModule' },
      { path: 'people', loadChildren: 'src/app/people/people.module#PeopleModule' },
      { path: 'security', loadChildren: 'src/app/security/security.module#SecurityModule' },
      { path: 'contact-us', loadChildren: 'src/app/contact-us/contact-us.module#ContactUsModule' },
      { path: 'management-company', loadChildren: 'src/app/companies/companies.module#CompaniesModule' },
      { path: 'tags', loadChildren: 'src/app/tag/tag.module#TagModule' },
      { path: '', component: NewsFeedComponent, pathMatch: 'full' },
      { path: 'packages', loadChildren: 'src/app/community-packages/community-packages.module#PackagesModule' },
      { path: 'visitors', loadChildren: 'src/app/visitors/visitors.module#VisitorsModule' },
      { path: 'mass-communication', loadChildren: 'src/app/mass-communication/mass-communication.module#MassCommunicationModule' },
      {
        path: 'clear-cache-menu',
        component: ClearCacheMenuComponent,
        data: {
          'title': 'Clear Cache',
        }
      },
      { path: 'services-in-action', loadChildren: 'src/app/services-in-action/services-in-action.module#ServicesInActionModule' },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      'title': 'Start session',
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      'title': 'Register',
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      'title': 'Forgot Password' }
  },
  {
    path: 'confirm-forgot-password/:username',
    component: ConfirmForgotPasswordComponent,
    data: {
      'title': 'Confirm forgot Password' }
  },
  {
    path: 'confirm-forgot-password/:username/:responseCode',
    component: ConfirmForgotPasswordComponent,
    data: {
      'title': 'Confirm forgot Password' }
  },
  {
    path: 'confirm-email/:username/:token',
    component: ConfirmEmailComponent,
    data: {
      'title': 'Verify Email' }
  },
  {
    path: 'home',
    component: LandingCommunityComponent,
    data: {
      'title': 'Start session',
    }
  },
  {
    path: 'payment',
    component: AnonymousPaymentFindPeopleComponent,
    data: {
      'title': 'pay',
    }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app'
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
//        preloadingStrategy: SelectivePreloadingStrategy
      }
    ),
    CommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRouterModule { }



