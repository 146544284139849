import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'angularfire2';

import { AppConfig, APP_CONFIG } from '../app.config';

import { NotificationService } from './notification.service';

import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { NullInjector } from '@angular/core/src/di/injector';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {

  private messaging: firebase.messaging.Messaging;

  private applicationName: string;

  private notificationCallback: (text: string) => void;

  public packageDeliverySuccess = new Subject<any>();

  constructor(
    private _notificationService: NotificationService,
    @Inject(FirebaseApp) _firebaseApp: firebase.app.App,
    @Inject(APP_CONFIG) protected appConfig?: AppConfig) {

    this.messaging = firebase.messaging(_firebaseApp);
    this.messaging.usePublicVapidKey(appConfig.messagingPublicKey);
    this.applicationName = appConfig.snsApplicationName;
    this.notificationCallback = (t: string) => {
      alert(t);
    };

    this.messaging.onTokenRefresh(() => {
      this.messaging.getToken().then(refreshedToken => {
        console.log('Token refreshed. ', refreshedToken);
        this._notificationService.registerToken(refreshedToken, this.applicationName).subscribe(x => {});
      }).catch(err => {
        console.log('Unable to retrieve refreshed token: ', err);
      });
    });

    this.messaging.onMessage(message => {
      console.log('Message received: ', message);
      if (message.data !== undefined && message.data.message !== undefined) {
        this.notificationCallback(message.data.message);
      }
      if (message.data.Type !== undefined && message.data.Type === 'DeliverPackageSignatureSuccess') {
        this.packageDeliverySuccess.next(message.data);
      }
    });
  }
  onPackageDeliverySuccess(): Observable<any> {
    return this.packageDeliverySuccess.asObservable();
  }

  requestPermission() {
    console.log('Firebase App: ', firebase.app().options);
    this.messaging.requestPermission().then(() => {
      console.log('Permission granted.');
      return this.messaging.getToken();
    }).then(token => {
      console.log('Token ', token);
      this._notificationService.registerToken(token, this.applicationName).subscribe(x => {});
    }).catch(reason => {
      console.log('Unable to get permission. Reason: ', reason);
    });
  }

  registerDeviceToUser() {
    this.messaging.getToken().then(token => {
      console.log('Token ', token);
      LocalStorageHelper.setClientToken(token);
      this._notificationService.registerToken(token, this.applicationName).subscribe(x => {});
    }).catch(reason => {
      console.log('Unable to get permission. Reason: ', reason);
    });
  }

  unregisterDeviceToUser(): Promise<any> {
    return this.messaging.getToken().then(token => {
      console.log('Token ', token);
      return this._notificationService.deregisterToken(token, this.applicationName);
    }).catch(reason => {
      console.log('Unable to get permission. Reason: ', reason);
    });
  }

  getToken(): Promise<String> {
    return this.messaging.getToken();
  }


  setNotificationCallback(callback: (t: string) => void) {
    this.notificationCallback = callback;
  }
}
