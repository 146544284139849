import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material';

// Services
import { TitleService } from '../../services/title.service';
import { PeopleService } from '../../services/people.service';
import { EmailService } from '../../services/email.service';
import { EmailAddressTypeService } from '../../services/email-address-type.service';
import { AddressService } from '../../services/address.service';
import { AddressTypeService } from '../../services/address-type.service';
import { StateService } from '../../services/state.service';
import { CityService } from '../../services/city.service';
import { ZipCodeService } from '../../services/zip-code.service';
import { UserService } from '../../services/user.service';

// Models
import { People } from '../../model/people';
import { Title } from '../../model/title';
import { AddressTableEntry } from '../../model/address-table-entry';
import { AddressType } from '../../model/address-type';
import { EmailAddressType } from '../../model/email-address-type';
import { Email } from '../../model/email';
import { PhoneNumberService } from '../../services/phone-number.service';
import { PhoneTypeService } from '../../services/phone-type.service';
import { PhoneNumber } from '../../model/phone-number';
import { PhoneType } from '../../model/phone-type';
import { State } from '../../model/state';
import { City } from '../../model/city';
import { ZipCode } from '../../model/zip-code';
import { AlertDialogComponent } from '../../app-dialogs/alert-dialog/alert-dialog.component';
import { ValidatorHelper } from 'src/app/helpers/validator-helper';
import { SiteHelper } from 'src/app/helpers/site-helper';


@Component({
  selector: 'app-people-edit',
  templateUrl: './people-edit.component.html',
  styleUrls: ['./people-edit.component.css']
})
export class PeopleEditComponent implements OnInit {

  public model: People;
  public titles: Title[] = [];
  public emailRelationship: Email;
  public phoneRelationship: PhoneNumber;
  public addressRelationship: AddressTableEntry;

  public emails: Email[] = [];
  public emailAddressTypes: EmailAddressType[] = [];
  public phones: PhoneNumber[] = [];
  public phoneTypes: PhoneType[];
  public addresses: AddressTableEntry[] = [];
  public addressTypes: AddressType[];
  public addressModel: AddressTableEntry;
  public states: State[];
  public cities: City[];
  public zipCodes: Observable<ZipCode[]>;
  public allZips: ZipCode[];
  private peopleId: number;
  public loading: boolean;
  public unitAddress = -1;
  peopleForm: FormGroup;
  private sub: Subscription;
  private entity: string;

  public MESSAGE_ERROR_ZIPCODE = 'Unable to add address, city or state does not belong to that zip code';
  public TITLE_WARNING = 'Warning';
  public MESSAGE_ZIPCODE_NOT_EXISTS = 'zip code does not exist';
  public LENGTH_ZIPCODE = 5;

  public addressItems: FormArray;
  public emailsItems: FormArray;
  public phonesItems: FormArray;

  public selected = -1;
  public selectedPhone = -1;

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private peopleService: PeopleService,
    private titleService: TitleService,
    private emailService: EmailService,
    private emailAddressTypeService: EmailAddressTypeService,
    private phoneNumberService: PhoneNumberService,
    private phoneTypeService: PhoneTypeService,
    private addressService: AddressService,
    private addressTypeService: AddressTypeService,
    private stateService: StateService,
    private cityService: CityService,
    private zipCodeService: ZipCodeService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    this.loading = true;
    this.model = new People(this.fb);
    this.peopleForm = this.model.buildFormGroup();
    this.addressItems = this.peopleForm.get('address') as FormArray;
    this.emailsItems = this.peopleForm.get('emails') as FormArray;
    this.phonesItems = this.peopleForm.get('phones') as FormArray;
  }

  ngOnInit() {
    this.addressItems.removeAt(0);
    this.phonesItems.removeAt(0);
    this.emailsItems.removeAt(0);
    Validators.required = ValidatorHelper.required;
    this.sub = this.route
      .data
      .subscribe(v => this.entity = v.origin);

    this.sub = this.route.paramMap.subscribe(
      params => {
        this.peopleId = +params.get('id');

        const phoneTypesObservable = this.phoneTypeService.getAll();
        const emailAddressTypesObservable = this.emailAddressTypeService.getAll();
        const addressTypesObservable = this.addressTypeService.getAll();
        const citiesObservable = this.cityService.getByPeopleId(this.peopleId);
        const titlesObservable = this.titleService.getAll();
        const statesObservable = this.stateService.getAll();
        forkJoin(
          phoneTypesObservable,
          emailAddressTypesObservable,
          addressTypesObservable,
          citiesObservable,
          titlesObservable,
          statesObservable)
          .subscribe(results => {
            this.phoneTypes = results[0];
            this.emailAddressTypes = results[1];
            this.addressTypes = results[2];
            this.cities = results[3];
            this.titles = results[4];
            this.states = results[5];
            this.initPeople();
          });
      });
  }


  initPeople(): void {

    if (this.peopleId) {
      this.model.peopleId = this.peopleId;
      this.peopleService.getById(this.peopleId).subscribe(p => {
        this.model.fromObject(p);
      });

      const a = this.addressService.getAddressesRelatedToPeopleId(this.model.peopleId);

      const c = this.phoneNumberService.getFullByPeopleId(this.model.peopleId);
      const e = this.emailService.getFullByPeopleId(this.model.peopleId);


      forkJoin(a, c, e).subscribe(results => {

        if (results[0].length > 0) {
          results[0] = this.reorderArrayIsPreferedFirst(results[0]);
          results[0].forEach(add => {
            this.unitAddress = add.cannotChange ? add.addressId : this.unitAddress;
            this.addAddress(add);
          });
        } else {
          this.addAddress();
        }

        if (results[1].length > 0) {
          results[1].forEach((ph, index) => {
            this.selectedPhone = ph.isPreferred ? index : this.selectedPhone;
            this.addPhone(ph);
          });
        } else {
          this.addPhone();
        }

        if (results[2].length > 0) {
          results[2].forEach((em, index) => {
            this.selected = em.isPreferred ? index : this.selected;
            this.addEmail(em);
          });
        } else {
          this.addEmail();
        }

        this.loading = false;
      });
    }
    if (this.peopleId === 0) {
      this.loading = false;
      this.addAddress();
      this.addPhone();
      this.addEmail();
    }
  }


  reorderArrayIsPreferedFirst(array: AddressTableEntry[]): AddressTableEntry[] {
    const returnArray: AddressTableEntry[] = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].isPrefered) {
        returnArray.unshift(array[i]);
      } else {
        returnArray.push(array[i]);
      }
    }
    return returnArray;
  }

  removeAddress(index: number): void {
    if (this.addresses[index].addressId) {
      if (this.addresses[index].addressId !== this.unitAddress) {
        if (confirm('Are you sure to delete this address?')) {
          this.addresses[index].isDeleted = true;
          this.addressService.delete(this.addresses[index].addressId).subscribe(a => {
            this.addressService.unlinkAddressFromPeople(this.addresses[index].addressId, this.model.peopleId).subscribe();
          });
          this.addresses.splice(index, 1);
          this.peopleForm.markAsDirty();
          this.addressItems.removeAt(index);
        }
      } else {
        confirm('Address from Unit cannot be change or deleted.');
      }
    } else {
      this.addresses.splice(index, 1);
      this.addressItems.removeAt(index);
    }
  }


  removePhone(index: number): void {
    if (this.phones[index].phoneNumberId > 0) {
      this.phones[index].isDeleted = true;
    } else {
      this.phones.splice(index, 1);
    }
    this.phonesFormGroup.controls[index].markAsDirty();
    this.phonesItems.removeAt(index);
  }



  removeEmail(index) {
    if (this.emails[index].emailId > 0) {
      this.emails[index].isDeleted = true;
    } else {
      this.emails.splice(index, 1);
    }
    this.emailsFormGroup.controls[index].markAsDirty();
    this.emailsItems.removeAt(index);
  }

  get addressFormGroup() {
    return this.peopleForm.get('address') as FormArray;
  }

  get emailsFormGroup() {
    return this.peopleForm.get('emails') as FormArray;
  }
  get phonesFormGroup() {
    return this.peopleForm.get('phones') as FormArray;
  }
  addAddress(entity: AddressTableEntry = null): void {
    this.addressItems.push(this.createAddress(entity));
    if (entity) {
      //  this.SetAddressComplete(entity, entity.zipCode, this.addresses.length - 1);
    }
  }
  createAddress(entity: AddressTableEntry = null): FormGroup {
    const addressModel = new AddressTableEntry(this.fb);
    const formGroup = addressModel.buildFormGroup();

    if (entity) {
      // Is the Address related to UNIT CANNOT NOT BE MODIFIED
      if (entity.cannotChange) {
        formGroup.controls['line1'].disable();
        formGroup.controls['line2'].disable();
        formGroup.controls['zipCode'].disable();
        formGroup.controls['cityId'].disable();
        formGroup.controls['stateId'].disable();
        formGroup.controls['addressTypeId'].disable();
      }
      addressModel.fromObject(entity);
    }
    this.addresses.push(addressModel);
    return formGroup;
  }
  selectPhone(index) {
    this.selectedPhone = index;
    this.setPreferedPhone(0, false);
  }
  setPreferedPhone(index, isPreferred) {
    this.phonesFormGroup.controls[index].get('isPreferred').setValue(isPreferred);
    this.phonesFormGroup.controls[index].markAsDirty();
  }
  selectEmail(index) {
    this.selected = index;
    this.setPreferedEmail(0, false);
  }
  setPreferedEmail(index, isPreferred) {
    this.emailsFormGroup.controls[index].get('isPreferred').setValue(isPreferred);
    this.emailsFormGroup.controls[index].markAsDirty();
  }
  addPhone(entity: PhoneNumber = null): void {
    this.phonesItems.push(this.createPhone(entity));
  }
  createPhone(entity: PhoneNumber = null): FormGroup {
    const phoneModel = new PhoneNumber(this.fb);
    const formGroup = phoneModel.buildFormGroup();
    if (entity) {
      phoneModel.fromObject(entity);
    }
    this.phones.push(phoneModel);
    return formGroup;
  }

  addEmail(entity: Email = null): void {
    this.emailsItems.push(this.createEmail(entity));
  }
  createEmail(entity: Email = null): FormGroup {
    const emailModel = new Email(this.fb);
    const formGroup = emailModel.buildFormGroup();
    if (entity) {
      emailModel.fromObject(entity);
    }
    this.emails.push(emailModel);
    return formGroup;
  }



  onZipCodeChange(formGroupAdd: FormGroup, i: number): void {

    const value = formGroupAdd.get('zipCode').value;
    if (value === undefined || value.length !== this.LENGTH_ZIPCODE) {
      return;
    }
    this.initZipCode(formGroupAdd, value, i);
  }

  initZipCode(formGroupAdd: FormGroup, value: string, i: number) {
    this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
      if (zipCode.length === 0) {
        this.clearZipCode();
        this.openDialog(this.TITLE_WARNING, this.MESSAGE_ZIPCODE_NOT_EXISTS);
        return;
      }
      this.addresses[i].zipCodeId = zipCode[0].zipCodeId;
      const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
      formGroupAdd.get('stateId').setValue(state.stateId);

      this.cityService.getByStateId(state.stateId).subscribe(x => {
        if (this.cities !== undefined) {
          this.cities = this.cities.concat(x);
        } else {
          this.cities = x;
        }

        const city = this.cities.find(c => c.name.trim() === zipCode[0].city.trim());
        formGroupAdd.get('cityId').setValue(city !== undefined ? city.cityId : 0);
        this.peopleForm.markAsDirty();
      });
    });
  }

  clearZipCode(): void {
    this.addressModel.cityId = null;
    this.addressModel.cityName = null;
    this.addressModel.stateId = null;
    this.addressModel.stateName = null;
    this.addressModel.zipCode = null;
    this.addressModel.zipCodeId = null;
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.clearZipCode();
    });
  }

  onSave(): void {
    if (this.peopleForm.valid) {
      if (this.peopleForm.value.emails.filter(e => e.isPreferred && !e.isDeleted).length === 0) {
        this.setPreferedEmail(0, true);
      }
      if (this.peopleForm.value.phones.filter(e => e.isPreferred && !e.isDeleted).length === 0) {
        this.setPreferedPhone(0, true);
      }
      this.model = this.model.toDto();
      this.addresses.forEach((add, index) => this.addresses[index] = add.toDto());
      this.phones.forEach((p, index) => this.phones[index] = p.toDto(this.phonesFormGroup[index]));
      this.emails.forEach((e, index) => this.emails[index] = e.toDto(this.emailsFormGroup[index]));
      if (!this.peopleId) {
        this.addPeople();
      } else {
        this.editPeople();
      }
    }
  }

  addPeople(): void {
    this.peopleService.add(this.model).subscribe(peopleResult => {
      this.phones.forEach((pho) => {
        this.phoneNumberService.addPhoneNumberToPeople(pho, peopleResult.id);
      });
      this.emails.forEach((email) => {
        this.emailService.addEmailToPeople(email, peopleResult.id);
      });
      this.addressService.addBulk(this.addresses).subscribe(addrResult => {
        addrResult.idList.forEach(id => {
          /*const add = this.addresses.find(a => a.addressId === id);
          if (add.isPrefered && this.model.relationshipId) {
            this.addressService.linkAddressToRelationship(add.addressId, this.model.relationshipId).subscribe();
          } else {
            this.addressService.linkAddressToPeople(id, peopleResult.id).subscribe();
          }*/
          this.addressService.linkAddressToPeople(id, peopleResult.id).subscribe();
        });
      });
      this.onSaveComplete();
    });
  }
  updatePhones(): void {
    if (this.phonesFormGroup.dirty) {
      this.phones.forEach((pho, index) => {
        if (!pho.phoneNumberId) {
          this.phoneNumberService.addPhoneNumberToPeople(pho, this.model.peopleId);
        } else if (pho.isDeleted) {
          this.phoneNumberService.deletePhoneNumberFromPeople(pho, this.model.peopleId);
        } else {
          if (this.phonesFormGroup.controls[index].dirty) {
            this.phoneNumberService.update(pho).subscribe();
          }
        }
      });
    }
  }

  updateEmails(): void {
    if (this.emailsFormGroup.dirty) {
      this.emails.forEach((email, index) => {
        if (!email.emailId) {
          this.emailService.addEmailToPeople(email, this.model.peopleId);
        } else if (email.isDeleted) {
          this.emailService.deleteEmailFromPeople(email, this.model.peopleId);
        } else {
          if (this.emailsFormGroup.controls[index].dirty) {
            this.emailService.update(email).subscribe();
          }
        }
      });
    }
  }

  markPrefered(index: number) {
    let i = 0;
    for (i = 0; i < this.addresses.length; i++) {
      this.addressFormGroup.controls[i].get('isPrefered').setValue((i === index));
    }
  }

  addNewAddress() {
    this.addresses.forEach((address, index) => {
      if (!address.addressId) {
        this.addressService.add(address).subscribe(result => {
          this.addressService.linkAddressToPeople(result.id, this.model.peopleId).subscribe(() => {
            if (address.isPrefered && this.model.relationshipId) {
              this.addressService.setPreferedAddress(result.id, this.model.peopleId).subscribe();
            }
          });
        });
      } else {
        if (address.addressId === this.unitAddress) {
          if (address.isPrefered && this.model.relationshipId) {
            this.addressService.setPreferedAddress(address.addressId, this.model.peopleId).subscribe();
          }
        } else {
          this.addressService.update(address).subscribe(upd => {
            if (address.isPrefered && this.model.relationshipId) {
              this.addressService.setPreferedAddress(address.addressId, this.model.peopleId).subscribe();
            }
          });
        }
      }
    });
  }


  editPeople(): void {
    if (SiteHelper.isDirty(this.peopleForm)) {
      this.peopleService.update(this.model).subscribe();
    }
    this.updatePhones();
    this.updateEmails();
    this.addNewAddress();
    this.onSaveComplete();
  }

  onSaveComplete(): void {
    this.peopleForm.reset();
    this.goToList();
  }

  onCancel(): void {
    this.peopleForm.reset();
    this.goToList();
  }

  goToList() {
    if (this.entity === 'residents') {
      this.router.navigateByUrl('/app/community/residents');
    } else {
      this.router.navigateByUrl('/app/people');
    }
  }
}
