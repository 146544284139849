import {
    AfterViewInit,
    EventEmitter,
    OnInit,
    ViewChild,
    OnDestroy
} from '@angular/core';

import {
  MatPaginator,
  MatSnackBar
} from '@angular/material';

import { Router } from '@angular/router';

import { DataProviderInterface } from '../interfaces/data-provider-interface';
import { CrudResponseModel } from '../model/crud-response-model';
import { TableDataSource } from './table-data-source';

import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { SearchParamsBreadcrumb } from '../model/search-params-breadcrumb';
import { LevelFilter } from '../model/breadcrumb';
import { BaseSecurity } from './base-security';


/**
 * Implements the basic functionality necessary to present a list of entities
 * on screen.
 */
export abstract class BaseList<T> extends BaseSecurity implements OnInit, AfterViewInit, OnDestroy {

  protected expandedElement: T | null;

  /** True, if the table is still loading data. */
  public loading: boolean;

  /** A reference to the data source that retrieves data for this table. */
  public dataSource: TableDataSource<T>;

  /** A reference to the site router. */
  protected router: Router;

  /** A reference to the MatSnackBar component, to allow displaying snack bars. */
  protected snackBar: MatSnackBar;

  /** A string used to filter the contents of the list shown to the user. */
  protected filters: string;

  /** A reference to the table paginator component. */
  @ViewChild(MatPaginator) protected paginator: MatPaginator;

  /** a reference field for breadcrumb Subscription */
  public breadcrumbChanges: Subscription;

  /** a reference for breadcrumd filters params to list */
  public searchParamsBreadcrumb: SearchParamsBreadcrumb = new SearchParamsBreadcrumb();

  /** Abstract method that should be overriden to implement entity-specific logic
   * to fetch the list to present, starting at page pi of size ps.
   *
   * @param pi - The page index.
   * @param ps - The page size.
   * @param fi - The filter of the data.
   */
  protected abstract getList(pi: number, ps: number, fi: string): Observable<T[]>;

  /** Abstract method that should be overriden to implement entity-specific logic
   * to fetch the item count for a specific entity.
  */


 protected checkBreadcrumbParams(): boolean {
   const filterLevel = this._dataService.getLevelFilterBreadcrumb();
   return ((this.searchParamsBreadcrumb.propertyManagementCompanyId !== LocalStorageHelper.getManagementCompanyFromBreadcrumb()
          && filterLevel >= LevelFilter.PropertyManagementCompany )
   || (this.searchParamsBreadcrumb.communityId !== LocalStorageHelper.getCommunitiesFromBreadcrumb()
          && filterLevel >= LevelFilter.Community)
   || (this.searchParamsBreadcrumb.areaId !== LocalStorageHelper.getBuildingFromBreadcrumb()
          && filterLevel >= LevelFilter.Area)
   || (this.searchParamsBreadcrumb.unitId !==  LocalStorageHelper.getUnitIdFromBreadcrumb()
          && filterLevel >= LevelFilter.Unit));
 }
 protected setBreadcrumbParams() {
  this.searchParamsBreadcrumb.propertyManagementCompanyId = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
  this.searchParamsBreadcrumb.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
  this.searchParamsBreadcrumb.areaId = LocalStorageHelper.getBuildingFromBreadcrumb();
  this.searchParamsBreadcrumb.unitId =  LocalStorageHelper.getUnitIdFromBreadcrumb();
}

  protected abstract getCount(): Observable<number>;

  /**
   * Abstract method that should be overriden to implement component-specific
   * funcionality to open a creation/edition form to modify entity data.
   *
   * @param isUpdate - True, if the operation is an update.
   * @param model - If isUpdate === true, the data to modify.
   * @param emitter - An emitter to signal the calling code when the operation
   * is complete.
   */
  protected abstract openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any;

  /**
   * Virtual method that should be overwritten to implement entity-specific
   * logic to be executed before inserting a given entity.
   *
   * @param entity A reference to an object of type T containing the information
   * to insert into a new record.
   */
  protected beforeInsert(entity: T, data: any): Observable<any> {
    return of({});
  }

  /**
   * Virtual method that should be overwritten to implement entity-specific
   * logic to be executed after inserting a given entity.
   *
   * @param entity A reference to an object of type T containing the information
   * to insert into a new record.
   */
  protected afterInsert(entity: T, data: any, beforeResult: any, insertResult: any): Observable<any> {
    return of({});
  }

  /**
   * Virtual method that should be overwritten to implement entity-specific
   * logic to be executed before updating an entity.
   *
   * @param entity A reference to an object of type T containing the information
   * to update in an existing record, identified by the record ID in entity.
   * @param data A reference to an object containing additional information
   * that may be required by the method when processing this event.
   */
  protected beforeUpdate(entity: T, data: any): Observable<any> {
    return of({});
  }

  /**
   * Virtual method that should be overwritten to implement entity-specific
   * logic to be executed after updating an entity.
   *
   * @param entity A reference to an object of type T containing the information
   * to update in an existing record, identified by the record ID in entity.
   * @param data A reference to an object containing additional information
   * that may be required by the method when processing this event.
   */
  protected afterUpdate(entity: T, data: any, beforeResult: any, updateResult: any): Observable<any> {
    return of({});
  }

  constructor(
    public _displayedColumns: string[],
    protected _uriSection: string,
    protected _dataService: DataProviderInterface<T>,
    public entityName: string,
    public $injector,
    public startsLoading = true) {
    super();
      this.router = $injector.get(Router);
      this.snackBar = $injector.get(MatSnackBar);
      this.loading = this.startsLoading;
      this.setBreadcrumbParams();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      if (this.checkBreadcrumbParams()) {
        this.setBreadcrumbParams();
        this._dataService.setBreadcrumbParams();
        this.loading = true;
        this.reload();
      }
  });
    }

  ngOnInit() {
    super.ngOnInit();
    this.dataSource = new TableDataSource<T>(this._dataService, (pi: number, ps: number, fi: string) => {
        return this.getList(pi, ps, fi);
    },
    () => {
        return this.getCount();
    });

    this.dataSource.loadingEvent.subscribe(x => {
      this.loading = x;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.breadcrumbChanges.unsubscribe();
  }
  goToItem(row: any): void {
    this.router.navigateByUrl('/app' + this._uriSection + '/' + row[this.entityName + 'Id']);
  }

  reload(): void {
    this.dataSource.reload();
  }

  /**
   * Sets the filter used by this list to the specified value.
   *
   * @param filterValue The value applied to the list to filter.
   */
  applyFilter(filterValue) {
    this.loading = true;
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
    this.dataSource.reload();
    this.loading = false;
  }

  /**
   * Cleans the filters applied on the list.
   */
  cleanFilters(): void {
    this.filters = '';
  }

  deleteItem(id: number): void {
    if (confirm('Are you sure you want to delete this item?')) {
      this.loading = true;
      this._dataService.delete(id).subscribe(result => {
        this.dataSource.reload();
        this.loading = false;
      });
    }
  }

  /**
   * Uses the data returned by the edition form to update a single entity.
   *
   * @param data The data to use when updating the entity.
   * @param emitter An EventEmitter that is triggered whenever the entity
   * has already been updated.
   */
  protected update(data: any, emitter: EventEmitter<CrudResponseModel>) {
    const entity: T = data.entity;

    this.beforeUpdate(entity, data).subscribe(beforeResult => {
      this._dataService.update(entity).subscribe(updateResult => {

        this.afterUpdate(entity, data, beforeResult, updateResult).subscribe(afterResult => {
          // TODO: Emit the «after» event result, too.
          emitter.emit(updateResult);
          this.loading = false;
          this.dataSource.reload();
          this.snackBar.open('The record has been successfully updated.', '', {
            duration: 2000
          });
        });
      });
    });
  }

  /**
   * Uses the data returned by the editionform to insert a single entity.
   *
   * @param data The data to insert into a new entity record.
   * @param emitter An EventEmitter that is triggered whenever the entity
   * has already been inserted.
   */
  protected insert(data: any, emitter: EventEmitter<CrudResponseModel>) {
    const entity: T = data.entity;

    this.beforeInsert(entity, data).subscribe(beforeResult => {
      this._dataService.add(entity).subscribe(insertResult => {

        this.afterInsert(entity, data, beforeResult, insertResult).subscribe(afterResult => {
          // TODO: Emit the «after» event result, too.
          this.loading = false;
          this.dataSource.reload();
          this.snackBar.open('The record has been successfully created.', '', {
            duration: 2000
          });
        });
      });
    });
  }

  openAddEditInternal(isUpdate: boolean, data: any): void {
    //
    const crudCompleteEventEmitter = new EventEmitter<CrudResponseModel>();
    const dialogRef = this.openEditForm(isUpdate, data, crudCompleteEventEmitter);
    dialogRef.beforeClose().subscribe(result => {

      if (typeof result !== 'undefined' && result.valid === true) {
        if (isUpdate) {
          this.update(result, crudCompleteEventEmitter);
        } else {
          this.insert(result, crudCompleteEventEmitter);
        }
      }
    });
  }

  openAddEdit(id: number, getData: () => Observable<any>): void {
    let dataObservable;
    const isUpdate = (id > 0);

    // If the ID is a positive non-zero integer, then it's an update
    // and we first need to go for the data to the service.
    if (isUpdate) {

      if (getData !== null) {
        dataObservable = getData();
      } else {
        dataObservable = this._dataService.getById(id).pipe(map(result => {
          return { entity: result };
        }));
      }

      dataObservable.subscribe(data => {
        this.openAddEditInternal(isUpdate, data);
      });
    } else {
      this.openAddEditInternal(isUpdate, null);
    }
  }
}
