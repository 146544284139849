import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../services/search.service';
import { SearchAddress } from '../model/search-address';
import { RegisterStep1Component } from './register-step1/register-step1.component';

interface Contact {
  id: number;
  type: string;
  contact: string;
  peopleId: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  selectedAddresses: SearchAddress[] = [];
  showAddress = false;
  step = 1;
  showSubmit1 = false;
  showStep1 = true;
  notFound = false;
  selectedContact: Contact;
  public loading: boolean;

  constructor(
    // public searchService: SearchService
  ) {

  }

  receiveMessage($event: boolean) {
    this.loading = $event;
  }

  ngOnInit() {
  }

}
