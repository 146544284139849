import { Component, OnInit, Injector, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PackageLog } from 'src/app/model/packageLog';
import { BaseList } from 'src/app/building-blocks/base-list';
import { PackageLogService } from 'src/app/services/package-log.service';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { map, debounceTime, switchAll } from 'rxjs/operators';

const packageColumns = [
  'receivedDate',
  'trackNumber',
  'unit',
  'packageStatus',
  'recipient',
  'carrierName',
  'actions'
];

@Component({
  selector: 'app-package-log',
  templateUrl: './package-log.component.html',
  styleUrls: ['./package-log.component.css']
})
export class PackageLogComponent extends BaseList<PackageLog> implements AfterViewInit {
  @ViewChild('filter') filterInput: ElementRef;
  private filterObservable: Observable<any>;
  private filterStatus: boolean;
  constructor(
    public injector: Injector,
    private cdr: ChangeDetectorRef,
    private packageService: PackageLogService) {
    super(packageColumns, '/packageLog', packageService, 'packageLog', injector);
    this.filters = '';
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.filterInput) {
      this.filterObservable = fromEvent(this.filterInput.nativeElement, 'keyup')
        .pipe(
          map((e: any) => {
            return e.target.value;
          }),
          debounceTime(300),
          map((q: string) => {
            if (q.length > 2) {
              this.cdr.detectChanges();
              this.loading = true;
              this.applyFilter(q);
              this.filterStatus = true;
            } else {
              if (q.length === 0 && this.filterStatus) {
                this.applyFilter('');
                this.filterStatus = false;
              }
            }
          },
            switchAll()));
      this.filterObservable.subscribe();
    }
  }

  ShortenStringDescription(description: string): string {
    if (description) {
      if (description.length > 15) {
        return description.substring(0, 15) + '..';
      }
      return description;
    }
  }

  public viewDetail(pl: PackageLog): void {
    const id = pl.isHot ? pl.hotPackageId : pl.packageId;
    this.router.navigate(['app/packages/log/detail/' + id + '/' + pl.isHot ]);
  }


  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<PackageLog[]> {
    return this.packageService.getAllPaged(pageIndex, pageSize, filter);
  }

  protected getCount(): Observable<number> {
    return this.packageService.getCount(this.dataSource.filter);
  }

  protected getById(id: number): Observable<PackageLog> {
    return this.packageService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any { }

}
