import { Package } from 'src/app/model/package';
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatAutocomplete,
} from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { ResidentSearch } from 'src/app/model/resident-search';
import { fromEvent, Observable, forkJoin, Subject } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PeopleService } from 'src/app/services/people.service';
import { Carrier } from 'src/app/model/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { PackagesService } from 'src/app/services/packages.service';
import { Router } from '@angular/router';
import { PackageTypeService } from 'src/app/services/package-type.service';
import { PackageType } from 'src/app/model/Package-type';
import { StorageService } from 'src/app/services/storage.service';
import { CommunitySettings } from 'src/app/model/community-settings';
import { SiteHelper } from 'src/app/helpers/site-helper';

@Component({
  selector: 'app-package-add',
  templateUrl: './package-add.component.html',
  styleUrls: ['./package-add.component.css']
})
export class PackageAddComponent implements OnInit, AfterViewInit {
  public carriers: Carrier [];
  private packageTypes: PackageType[];
  public storages: Storage[];
  public model: Package;
  public packageFormGroup: FormGroup;
  public loading: boolean;
  @Input() communitySettings: CommunitySettings;
  @Input() showInPopup: boolean;
  @Input() resident: ResidentSearch;
  public addPackages = new Subject<Package>();
  public addAnotherP = new Subject<Package>();
  public cancel = new Subject<boolean>();


  /**
   * Initializes a new instance of TagAddEditComponent.
   *
   * @param fb A reference to an instance of FormBuilder.
   * @param snackBar A reference to an instance of MatSnackBar.
   * @param dialog .
   */

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private peopleService: PeopleService,
    private carrierService: CarrierService,
    private packageTypeService: PackageTypeService,
    private storageService: StorageService,
    private router: Router,
    private packageService: PackagesService) {
      this.model = new Package(this.fb);
      this.packageFormGroup = this.model.buildFormGroup();
    }

    settingForm() {
      this.loading = true;
      if (this.communitySettings.showNote && this.communitySettings.requireNote ) {
        this.addRequiredValidatorToControl('notes');
      }
      if (this.communitySettings.showPackageType && this.communitySettings.requirePackageType ) {
        this.addRequiredValidatorToControl('packageTypeId');
      }
      if (this.communitySettings.showSender && this.communitySettings.requireSender ) {
        this.addRequiredValidatorToControl('sender');
      }
      if (this.communitySettings.showStorage && this.communitySettings.requireStorage ) {
        this.addRequiredValidatorToControl('storageId');
      }
      this.packageFormGroup.updateValueAndValidity();
      this.loading = false;
    }

    addRequiredValidatorToControl(control: string) {
      this.packageFormGroup.controls[control].setValidators([Validators.required]);
      this.packageFormGroup.controls[control].updateValueAndValidity();
    }

 /**
   * Sets up the component once it has been initialized by Angular.
   */
  ngOnInit() {
    forkJoin(this.carrierService.getAll(),
            this.packageTypeService.getAll(),
            this.storageService.getByCommunityId(this.communitySettings.communityId)
    ).subscribe(result => {
      this.carriers = result[0];
      this.packageTypes = result[1];
      this.storages = result[2];
      if (this.storages.length === 0) {
        SiteHelper.openDialogAlert('Warning' , 'This community does not have storage configured', this.dialog);

      }
    });
  }
  ngAfterViewInit() {
    this.settingForm();
  }

  protected carrierChange(carrier: Carrier) {
    this.model.carrier = carrier.name;
  }

  protected  addPackage(): Observable<Package> {
    if (!this.packageFormGroup.valid) {
        SiteHelper.markFormGroupTouched(this.packageFormGroup);
        return;
    }
      const dto = this.model.toDto();
      this.addPackages.next(dto);
      return this.addPackages.asObservable();
  }
  protected addAnother() {
    const dto = this.model.toDto();
    this.addAnotherP.next(dto);
   }

  public onCancel() {
    if (!this.showInPopup) {
      this.router.navigateByUrl('/app/packages');
    } else {
      this.cancel.next(true);
    }
  }
  public watchCancel() {
    return this.cancel.asObservable();
  }
  public watchAddPackage() {
    return this.addPackages.asObservable();
  }
  public watchAddAnother() {
    return this.addAnotherP.asObservable();
  }

    /**
   * Event handler for the Ok/Submit button.
   */
  onSubmit(): void {
    this.loading = true;
    const dto = this.model.toDto();
    dto.peopleId = this.resident.peopleId;
    dto.unitId = this.resident.unitId;
    dto.storageId = dto.storageId === 0 && this.storages.length > 0 ? this.storages[0] : dto.storageId;
    this.packageService.receive(dto).subscribe(r => {
      if (this.showInPopup) {
        this.addAnother();
      } else {
         this.router.navigateByUrl('/app/packages');
      }
    });
  }

}
