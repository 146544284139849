import { Unit } from './unit';

export class Level {
    id: number;
    name: string;
    type: string;
    fatherId: number;
    son: Level[];
}

export class UserProfile {
    id: number;
    description: string;
}

export class Breadcrumb {
userProfile: UserProfile;
levels: Level[];
}

export enum Profile {
    GridStaff = 1,
    PropertyManagementCompanyStaff = 2,
    CommunityStaff = 3,
    OwnerTenant = 4
  }

export enum LevelFilter {
    None = 0,
    PropertyManagementCompany = 1,
    Community = 2,
    Area = 3,
    Unit = 4
  }
