import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Relationship } from '../model/relationship';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RelationshipService extends AbstractDataProviderService<Relationship> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.GridVendor, '/relationship', http, appConfig);
  }

  getByUnitId(unitId: number): Observable<Relationship[]> {
    return this.http.get<Relationship[]>(this.baseUrl + '/unit/' + unitId.toString(), { headers: this.buildGetHeaders() });
  }
}
