import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { forkJoin } from 'rxjs';

/* Models */
import { AddressTableEntry } from '../../model/address-table-entry';
import { AddressType } from '../../model/address-type';
import { City } from '../../model/city';
import { Email } from '../../model/email';
import { EmailAddressType } from '../../model/email-address-type';
import { People } from '../../model/people';
import { PhoneNumber } from '../../model/phone-number';
import { PhoneType } from '../../model/phone-type';
import { State } from '../../model/state';
import { ZipCode } from '../../model/zip-code';

/* Services */
import { AddressService } from '../../services/address.service';
import { AddressTypeService } from '../../services/address-type.service';
import { CityService } from '../../services/city.service';
import { EmailService } from '../../services/email.service';
import { EmailAddressTypeService } from '../../services/email-address-type.service';
import { PeopleService } from '../../services/people.service';
import { PhoneNumberService } from '../../services/phone-number.service';
import { PhoneTypeService } from '../../services/phone-type.service';
import { StateService } from '../../services/state.service';
import { UserService } from '../../services/user.service';
import { ZipCodeService } from '../../services/zip-code.service';

/* Components */
import { AlertDialogComponent } from '../../app-dialogs/alert-dialog/alert-dialog.component';
import { R2D2, MainAddress, BillAddress } from 'src/app/model/r2d2';
import { R2D2Service } from 'src/app/services/r2-d2.service';
import { SiteHelper } from 'src/app/helpers/site-helper';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {
  private model: People;
  private initialModel: People;
  public accountFormGroup: FormGroup;
  public addresses: AddressTableEntry[] = [];
  public addressTypes: AddressType[] = [];
  public states: State[] = [];
  public cities: City[][] = [];
  public allZips: ZipCode[] = [];
  public phones: PhoneNumber[] = [];
  public phoneTypes: PhoneType[] = [];
  public emails: Email[] = [];
  public emailAddressTypes: EmailAddressType[] = [];
  public imgSrc = '../../../assets/images/user-placeholder.svg';
  public MESSAGE_ERROR_ZIPCODE = 'Unable to add address, city or state does not belong to that zip code';
  public TITLE_WARNING = 'Warning';
  public MESSAGE_ZIPCODE_NOT_EXISTS = 'zip code does not exist';
  public MESSAGE_ADD_ADDRESS = 'You must add at least one address';
  public MESSAGE_ADD_EMAIL_PREFERRED = 'you must select a preferred email';
  public MESSAGE_ADD_PHONE_PREFERRED = 'you must select a preferred phone number';
  public MESSAGE_MANDATORY_FIELDS = 'Please, complete the required fields';
  public LENGTH_ZIPCODE = 5;
  public loading: boolean;
  public selectedFile: File;
  public changePicture = false;
  public step: number;
  public arrayBuffer: any;
  private isImpersonate: boolean;
  public addressItems: FormArray;
  public unitAddress = -1;
  public unitAddressFlag: boolean;
  public emailsItems: FormArray;
  public phonesItems: FormArray;
  public selected = -1;
  public selectedPhone = -1;
  public currentUserIsStaff=false;

  constructor(
    public dialogRef: MatDialogRef<AccountDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private peopleService: PeopleService,
    private emailService: EmailService,
    private emailAddressTypeService: EmailAddressTypeService,
    private phoneNumberService: PhoneNumberService,
    private phoneTypeService: PhoneTypeService,
    private addressService: AddressService,
    private addressTypeService: AddressTypeService,
    private stateService: StateService,
    private cityService: CityService,
    private zipCodeService: ZipCodeService,
    private userService: UserService,
    private r2D2Service: R2D2Service,
    private fb: FormBuilder,
    private dialog: MatDialog) {
    this.model = new People(this.fb);
    this.accountFormGroup = this.model.buildFormGroup();
    this.addressItems = this.accountFormGroup.get('address') as FormArray;
    this.emailsItems = this.accountFormGroup.get('emails') as FormArray;
    this.phonesItems = this.accountFormGroup.get('phones') as FormArray;


  }



  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.step = 0;
    this.loading = true;
    this.addressItems.removeAt(0);
    this.phonesItems.removeAt(0);
    this.emailsItems.removeAt(0);
    this.isImpersonate = this.data.isImpersonated;
    const phoneTypesObservable = this.phoneTypeService.getAll();
    const emailAddressTypesObservable = this.emailAddressTypeService.getAll();
    const addressTypesObservable = this.addressTypeService.getAll();
    const stateObservable = this.stateService.getAll();
    forkJoin(
      phoneTypesObservable,
      emailAddressTypesObservable,
      addressTypesObservable,
      stateObservable).subscribe(results => {
        this.phoneTypes = results[0];
        this.emailAddressTypes = results[1];
        this.addressTypes = results[2];
        this.states = results[3];
        this.initPeople();
      });


      this.userService.isStaff.subscribe(responseValue=>{
       this.currentUserIsStaff = responseValue;
      });

  }

  initPeople(): void {
    this.peopleService.GetForCurrentUser().subscribe(p => {
      this.model.fromObject(p);

      const a = this.addressService.getAddressesRelatedToPeopleId(this.model.peopleId);
      const c = this.phoneNumberService.getFullByPeopleId(this.model.peopleId);
      const e = this.emailService.getFullByPeopleId(this.model.peopleId);

      forkJoin(a, c, e).subscribe(results => {

        if (results[0].length > 0) {
          results[0].forEach(add => {
            this.unitAddress = add.cannotChange ? add.addressId : this.unitAddress;
            this.addAdress(add);
          });
        }

        if (results[1].length > 0) {
          results[1].forEach((ph, index) => {
            this.selectedPhone = ph.isPreferred ? index : this.selectedPhone;
            this.addPhone(ph);
          });
        }

        if (results[2].length > 0) {
          results[2].forEach((em, index) => {
            this.selected = em.isPreferred ? index : this.selected;
            this.addEmail(em);
          });
        }
        this.loading = false;
      });
    });
    if (!this.isImpersonate) {
      this.peopleService.currentUserPicture.subscribe(userPicture => {
        this.imgSrc = userPicture !== null && userPicture !== undefined ? userPicture : this.imgSrc;
      });
    } else {
      this.peopleService.GetProfilePicture(true).subscribe(pic => {
        const reader = new FileReader();
        const blob = new Blob([new Uint8Array(pic)], { type: 'image/jpeg' });
        if (blob.size > 0) {
          const file = new File([blob], 'xxx', { type: 'image/jpeg', lastModified: Date.now() });
          reader.onload = (event: any) => {
            this.imgSrc = event.target.result;
          };
          reader.readAsDataURL(file);
        }
      });
    }
  }
  onFileChanged(file: FileList) {
    this.selectedFile = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgSrc = event.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
    this.changePicture = true;
  }

  save(): void {
    this.editPeople();
  }

  savePicture() {
    if (this.selectedFile && this.changePicture) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.arrayBuffer = reader.result;
        this.peopleService.SetProfilePicture(this.arrayBuffer).subscribe(res => {
          if (!this.isImpersonate) {
            this.peopleService.setUserPicture();
          }
        });
      };
      reader.readAsArrayBuffer(this.selectedFile);
    }
  }

  editPeople(): void {
    if (this.addresses.filter(a => !a.isDeleted).length === 0) {
      this.openDialog(this.TITLE_WARNING, this.MESSAGE_ADD_ADDRESS);
      return;
    }
    if (this.accountFormGroup.status !== 'VALID') {
      this.openDialog(this.TITLE_WARNING, this.MESSAGE_MANDATORY_FIELDS);
      return;
    }

    if (this.accountFormGroup.value.emails.filter(e => e.isPreferred && !e.isDeleted).length === 0) {
      this.openDialog(this.TITLE_WARNING, this.MESSAGE_ADD_EMAIL_PREFERRED);
      return;
    }
    if (this.accountFormGroup.value.phones.filter(e => e.isPreferred && !e.isDeleted).length === 0) {
      this.openDialog(this.TITLE_WARNING, this.MESSAGE_ADD_PHONE_PREFERRED);
      return;
    }
    this.loading = true;
    this.savePicture();

    this.model = this.model.toDto();
    this.addresses.forEach((add, index) => this.addresses[index] = add.toDto());
    this.phones.forEach((p, index) => this.phones[index] = p.toDto(this.phonesFormGroup[index]));
    this.emails.forEach((e, index) => this.emails[index] = e.toDto(this.emailsFormGroup[index]));

    this.loading = true;
    if (SiteHelper.isDirty(this.accountFormGroup)) {
      this.peopleService.update(this.model).subscribe(_ => {
          this.peopleService.setUserInfo(this.model);
      });
    }

    this.updatePhones();
    this.updateEmails();
    this.updateAddresses();
    this.saveIntoJenark();
    this.loading = false;
    this.closeModal();
  }

  saveIntoJenark(): void {
    if (!this.accountFormGroup.dirty) {
      return;
    }
    const a = new R2D2();
    a.BillAddress = new BillAddress();
    a.MainAddress = new MainAddress();

    const cellPhone = this.phones.find(x => (x.phoneTypeId === 1 && x.isPreferred) || x.phoneTypeId === 1);
    const homePhone = this.phones.find(x => (x.phoneTypeId === 2 && x.isPreferred) || x.phoneTypeId === 2);
    const workPhone = this.phones.find(x => (x.phoneTypeId === 3 && x.isPreferred) || x.phoneTypeId === 3);
    const billingAddress = this.addresses.find(x => x.addressTypeId === 3);
    const mainAddress = this.addresses.find(x => x.addressTypeId === 1);

    a.FName = this.model.firstName;
    a.Mi = this.model.middleName;
    a.LName = this.model.lastName;
    a.WorkPhone = workPhone ? workPhone.number : '';
    a.Phone = homePhone ? homePhone.number : '';
    a.CellPhone = cellPhone ? cellPhone.number : '';
    a.Email = this.emails.length > 0 ? this.emails[0].address : '';

    if (billingAddress) {
      a.BillAddress.City = billingAddress.cityName;
      a.BillAddress.Country = 'USA';
      a.BillAddress.St = billingAddress.stateName;
      a.BillAddress.Street = billingAddress.line1;
      a.BillAddress.Zip = billingAddress.zipCode;
    }
    if (mainAddress) {
      a.MainAddress.City = mainAddress.cityName;
      a.MainAddress.Country = 'USA';
      a.MainAddress.St = mainAddress.stateName;
      a.MainAddress.Street = mainAddress.line1;
      a.MainAddress.Zip = mainAddress.zipCode;
    }
    this.r2D2Service.SaveIntoJenark(a, this.model.userId).subscribe(x => { });
  }

  closeModal(): void {
    this.dialogRef.close({ valid: true, });
  }
  markEmailAsPreferred(index) {
    if (this.emailsFormGroup.controls[this.selected] !== undefined) {
      this.emailsFormGroup.controls[this.selected].get('isPreferred').setValue(false);
    }
    this.selected = index;
  }

  markPhoneAsPreferred(index) {
    if (this.phonesFormGroup.controls[this.selectedPhone] !== undefined) {
      this.phonesFormGroup.controls[this.selectedPhone].get('isPreferred').setValue(false);
    }
    this.selectedPhone = index;
  }

  updatePhones(): void {
    this.phones.forEach((pho, index) => {
      if (!pho.phoneNumberId) {
        this.phoneNumberService.addPhoneNumberToPeople(pho, this.model.peopleId);
      } else if (pho.isDeleted) {
        this.phoneNumberService.deletePhoneNumberFromPeople(pho, this.model.peopleId);
      } else {
        // if (this.phonesFormGroup.controls[index].dirty) {
        this.phoneNumberService.update(pho).subscribe();
        // }
      }
    });
  }


  updateEmails(): void {
    if (this.emailsFormGroup.dirty) {
      this.emails.forEach((email, index) => {
        if (!email.emailId) {
          this.emailService.addEmailToPeople(email, this.model.peopleId);
        } else if (email.isDeleted) {
          this.emailService.deleteEmailFromPeople(email, this.model.peopleId);
        } else {
          // if (this.emailsFormGroup.controls[index].dirty) {
          this.emailService.update(email).subscribe();
          // }
        }
      });
    }
  }

  markPrefered(index: number) {
    let i = 0;
    for (i = 0; i < this.addresses.length; i++) {
      this.addressFormGroup.controls[i].get('isPrefered').setValue((i === index));
    }
  }

  updateAddresses(): void {

    this.addresses.forEach((address, index) => {
      address.isMailing = (address.addressTypeId === 1);

      if (!address.addressId) {
        this.addressService.add(address).subscribe(result => {
          this.addressService.linkAddressToPeople(result.id, this.model.peopleId).subscribe(() => {
            if (address.isPrefered && this.model.relationshipId) {
              this.addressService.setPreferedAddress(result.id, this.model.peopleId).subscribe();
            }
          });
        });

      } else {
        if (address.isDeleted) {
          if (address.addressId !== this.unitAddress) {
            this.addressService.delete(address.addressId).subscribe();
          }
        } else {
          if (this.unitAddress === address.addressId) {
            if (address.isPrefered && this.model.relationshipId) {
              this.addressService.setPreferedAddress(address.addressId, this.model.peopleId).subscribe();
            }
          } else {
            this.addressService.update(address).subscribe(upd => {
              if (address.isPrefered && this.model.relationshipId) {
                this.addressService.setPreferedAddress(address.addressId, this.model.peopleId).subscribe();
              }
            });
          }
        }
      }
    });
  }


  SetAddressComplete(app: any, value: string, i: number): void {
    if (value === undefined || value.length !== this.LENGTH_ZIPCODE || app.isDeleted) {
      return;
    }
    this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
      if (zipCode.length === 0) {
        this.clearZipCode(i);
        this.openDialog(this.TITLE_WARNING, this.MESSAGE_ZIPCODE_NOT_EXISTS);
        return;
      }
      this.addresses[i].zipCodeId = zipCode[0].zipCodeId;
      const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
      this.addressFormGroup.controls[i].get('stateId').setValue(state.stateId);

      this.cityService.getByStateId(state.stateId).subscribe(x => {
        this.cities[i] = x;
        const city = x.find(c => c.name === zipCode[0].city);
        this.addressFormGroup.controls[i].get('zipCode').setValue(zipCode[0].zipCode);
        this.addressFormGroup.controls[i].get('cityId').setValue(city.cityId);
      });
    });
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  clearZipCode(index: number): void {
    this.addresses[index].cityId = null;
    this.addresses[index].cityName = null;
    this.addresses[index].stateId = null;
    this.addresses[index].stateName = null;
    this.addresses[index].zipCode = null;
    this.addresses[index].zipCodeId = null;
  }

  onStateSelectionChange(e, i: number): void {
    this.cityService.getByStateId(e.value).subscribe(x => {
      this.cities[i] = x;
    });
  }

  get addressFormGroup() {
    return this.accountFormGroup.get('address') as FormArray;
  }

  get emailsFormGroup() {
    return this.accountFormGroup.get('emails') as FormArray;
  }

  get phonesFormGroup() {
    return this.accountFormGroup.get('phones') as FormArray;
  }

  addAdress(entity: AddressTableEntry = null): void {
    this.addressItems.push(this.createAddress(entity));
    if (entity) {
      this.SetAddressComplete(entity, entity.zipCode, this.addresses.length - 1);
    }
  }

  createAddress(entity: AddressTableEntry = null): FormGroup {
    const addressModel = new AddressTableEntry(this.fb);
    const formGroup = addressModel.buildFormGroup();
    if (entity) {
      if (entity.cannotChange) {
        formGroup.controls['line1'].disable();
        formGroup.controls['line2'].disable();
        formGroup.controls['zipCode'].disable();
        formGroup.controls['cityId'].disable();
        formGroup.controls['stateId'].disable();
        formGroup.controls['addressTypeId'].disable();
      }
      addressModel.fromObject(entity);
    }
    this.addresses.push(addressModel);
    return formGroup;
  }

  removeAddress(index) {
    if (this.addresses[index].addressId !== this.unitAddress) {
      if (this.addresses[index].addressId > 0) {
        this.addresses[index].isDeleted = true;
      } else {
        this.addresses.splice(index, 1);
        this.cities.splice(index, 1);
      }
      this.addressItems.removeAt(index);
    } else {
      confirm('Address from Unit cannot be change or deleted.');
    }
  }

  addPhone(entity: PhoneNumber = null): void {
    this.phonesItems.push(this.createPhone(entity));
  }

  createPhone(entity: PhoneNumber = null): FormGroup {
    const phoneModel = new PhoneNumber(this.fb);
    const formGroup = phoneModel.buildFormGroup();
    if (entity) {
      phoneModel.fromObject(entity);
    }
    this.phones.push(phoneModel);
    return formGroup;
  }

  removePhone(index: number): void {
    if (this.phones[index].phoneNumberId > 0) {
      this.phones[index].isDeleted = true;
    } else {
      this.phones.splice(index, 1);
    }
    this.phonesItems.removeAt(index);
    this.phonesFormGroup.controls[index].markAsDirty();
  }

  addEmail(entity: Email = null): void {
    this.emailsItems.push(this.createEmail(entity));
  }

  createEmail(entity: Email = null): FormGroup {
    const emailModel = new Email(this.fb);
    const formGroup = emailModel.buildFormGroup();
    if (entity) {
      emailModel.fromObject(entity);
    }
    this.emails.push(emailModel);
    return formGroup;
  }

  // remove email from group
  removeEmail(index) {
    if (this.emails[index].emailId > 0) {
      this.emails[index].isDeleted = true;
    } else {
      this.emails.splice(index, 1);
    }
    this.emailsFormGroup.controls[index].markAsDirty();
    this.emailsItems.removeAt(index);   
  }
}
