import { Component, EventEmitter, Injector, Input, OnInit } from '@angular/core';
import { MatButton, MatDialog, MatSnackBar } from '@angular/material';

import { Observable } from 'rxjs';

import { BaseList } from '../../building-blocks/base-list';
import { BaseListDialogData } from '../../building-blocks/base-list-dialog-data';

import { Entity } from '../../model/entity';
import { CrudResponseModel } from '../../model/crud-response-model';
import { EntityService } from '../../services/entity.service';
import { EntityAddEditDialogComponent } from '../entity-add-edit-dialog/entity-add-edit-dialog.component';
import { AccessType, EntityRing } from 'src/app/model/access-type-ring';

const entityColumns = [
  'entityId',
  'entityTypeName',
  'entityKeyId',
  'name',
  'description',
  'actions'
];

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.css']
})
export class EntityListComponent extends BaseList<Entity> {
public entityPermisions = new EntityRing();
  constructor(
    public injector: Injector,
    private entityService: EntityService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) {
      super(entityColumns, '/security/entity', entityService, 'entity', injector);
  }

  protected loadSecurityRings() {
    this.entityPermisions.rings.push({ringId: 551, accessType: AccessType.Add});
    this.entityPermisions.rings.push({ringId: 651, accessType: AccessType.Delete});
    this.entityPermisions.rings.push({ringId: 751, accessType: AccessType.Update});
    this.entityRings.push(this.entityPermisions);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Entity[]> {
    return this.entityService.getAllPaginated(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.entityService.getCount();
  }

  protected getById(id: number): Observable<Entity> {
    return this.entityService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any {
    const dialogRef = this.dialog.open(EntityAddEditDialogComponent, {
      width: '850px',
      data: new BaseListDialogData<Entity>(isUpdate, model, emitter)
    });

    return dialogRef;
  }
}
