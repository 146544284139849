import { Component, OnInit, Injector, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Package } from 'src/app/model/package';
import { BaseList } from 'src/app/building-blocks/base-list';
import { PackagesService } from 'src/app/services/packages.service';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { HotPackagesService } from 'src/app/services/hot-packages.service';
import { map, debounceTime, switchAll } from 'rxjs/operators';
import { SecurityHelper } from 'src/app/helpers/security-helper';

const packageColumns = [
  'community',
  'unit',
  'recipient',
  'carrierName',
  'trackNumber',
  'receivedDate'
];

@Component({
  selector: 'app-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.css']
})
export class PackagesListComponent extends BaseList<Package> implements  AfterViewInit {
  @ViewChild('filter') filterInput: ElementRef;
  private filterObservable: Observable<any>;
  private filterStatus: boolean;
  isStaff: boolean;
  constructor(
    public injector: Injector,
    private cdr: ChangeDetectorRef,
    private packageService: HotPackagesService) {
       super(packageColumns, '/hotpackages', packageService, 'hotPackages', injector);
       this.filters = '';
       this.isStaff = SecurityHelper.LoggedUserIsStaff();
   }

   ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.filterInput) {
    this.filterObservable = fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((e: any) => {
          return e.target.value;
        }),
        debounceTime(300),
        map((q: string) => {
          if (q.length > 2) {
            this.loading = true;
            this.cdr.detectChanges();
            this.applyFilter(q);
            this.filterStatus = true;
          } else {
            if (q.length === 0 && this.filterStatus) {
              this.applyFilter('');
              this.filterStatus = false;
            }
          }
        },
        switchAll()));
    this.filterObservable.subscribe();
      }
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Package[]> {
    return this.packageService.getAllPaged(pageIndex, pageSize, filter);
  }

  protected getCount(): Observable<number> {
    return this.packageService.getCount(this.dataSource.filter);
  }

  protected getById(id: number): Observable<Package> {
    return this.packageService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any {}

}
