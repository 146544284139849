import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class CommunitySettings extends BaseForm {

    communitySettingsId = 0;
    communityId: 0;

    @FormField('packagesEnabled', [false, Validators.required])
    packagesEnabled = false;

    @FormField('showSender', [false, Validators.required])
    showSender = false;

    @FormField('requireSender', [false, Validators.required])
    requireSender = false;

    @FormField('showSignature', [false, Validators.required])
    showSignature = false;

    @FormField('requireSignature', [false, Validators.required])
    requireSignature = false;

    @FormField('showPackageType', [false, Validators.required])
    showPackageType = false;

    @FormField('requirePackageType', [false, Validators.required])
    requirePackageType = false;

    @FormField('showStorage', [false, Validators.required])
    showStorage = false;

    @FormField('requireStorage', [false, Validators.required])
    requireStorage = false;

    @FormField('showNote', [false, Validators.required])
    showNote = false;

    @FormField('requireNote', [false, Validators.required])
    requireNote = false;

    @FormField('notificationDelayInMiliseconds', [0, Validators.required])
    notificationDelayInMiliseconds = 0;

    @FormField('notificationTypeId', [0, Validators.required])
    notificationTypeId = 0;
}
