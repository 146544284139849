import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Unit } from '../model/unit';
import { Observable } from 'rxjs';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends AbstractDataProviderService<Unit> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/unit', http, appConfig, LevelFilter.Unit);
  }

  getAllPaginated(pageIndex: number, pageSize: number, filter?: string): Observable<Unit[]> {
    const route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString()  + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' +  this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }

    return this.http.get<Unit[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCount(filter?: string): Observable<number> {
    if (this.filterLevel === LevelFilter.None) {
    return this.http.get<CountResultModel>(this.baseUrl + '/count' , { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
    } else {
        return this.getCountByBreadcumd(filter);
    }
  }

  getAllByPaginatedFiltered(pageIndex: number, pageSize: number, filter?: string): Observable<Unit[]> {
    const queryString = '/allPaged/' + pageIndex.toString() + '/' + pageSize.toString() + '/' + filter;
    return this.http.get<Unit[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

}


