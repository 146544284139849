import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('sidenav') sidenav: SideNavComponent;
  constructor() { }

  ngOnInit() {
  }

  @HostListener('collapse')
  click() {
    this.sidenav.toggle();
  }

}
