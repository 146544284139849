import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { forkJoin, Observable } from 'rxjs';
import { SmaugService } from 'src/app/services/smaug.service';
import { AddCreditCardDialogComponent } from '../add-credit-card-dialog/add-credit-card-dialog.component';
import { AccountData } from 'src/app/model/account-data';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AccountService } from 'src/app/services/account.service';
import { AddAchPaymentDialogComponent } from '../add-ach-payment-dialog/add-ach-payment-dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { BalanceAccount } from 'src/app/model/account';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { AccessType, EntityRing } from 'src/app/model/access-type-ring';

interface PaymentItem {
  icon: string;
  name: string;
  lastDigits: number;
  expDate: string;
  accountId: number;
  token: number;
  paymentMethodTypeId: number;
}

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.css']
})
export class PaymentDialogComponent extends BaseSecurity implements OnInit {
  displayedColumns: string[] = ['account', 'amount_due', 'checked'];
  dataSource: AccountData[] = [];
  public dataSourceArray: any;
  public methods: PaymentItem[] = [];
  totalAmount = 0;
  public totalFee: number;
  public totalAmountWithFee: number;
  loading = true;
  methodSelected: PaymentItem = {
    name: '', icon: '', lastDigits: 0, expDate: '', accountId: 0, token: 0, paymentMethodTypeId: 0
  };

  sourceCommunityCode = '';
  sourceAccountNumber = '';
  private observableBalanceArray: Observable<BalanceAccount[]>;
  private balanceAccount: BalanceAccount[] = [];
  public executePayPermisions = new EntityRing();
  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    private smaugService: SmaugService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    super();
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.executePayPermisions.rings.push({ringId: 7013, accessType: AccessType.Execute});
    this.entityRings.push(this.executePayPermisions);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data.accounts.length > 0) {
      const accounts: string[] = this.data.accounts;

      const unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
      if (unitId > 0) {
        this.observableBalanceArray = this.accountService.getBalancesByUnitId(unitId);
      } else {
         this.observableBalanceArray = this.accountService.getBalances();
      }

      this.smaugService.getUserDataForProviderId().subscribe(data => {
        this.sourceAccountNumber = data[0].sourceAccountNumber;
      });
      forkJoin(this.observableBalanceArray).subscribe(results => {
        const acc = results[0];
        for (let i = 0; i < acc.length; i++) {
          const element = acc[i];
          this.dataSource.push({
            account: accounts[i],
            amount_due: (element) ? element.amount : 0,
            checked: true
          });
        }
        this.balanceAccount = results[0];
        this.totalAmount += results[0].map(s => s.amount).reduce((acco, val) => {
          return acco + val;
        });
        this.dataSourceArray =  new MatTableDataSource(this.dataSource);
        // this.loading = false;
        this.getPaymentMethod();
      });
    }
  }

  getPaymentMethod() {
    this.methods = [];
    this.loading = true;
    if (this.balanceAccount.length > 0) {
      for (let i = 0; i < this.balanceAccount.length; i++) {
        const element = this.balanceAccount[i];
        this.smaugService.getPaypointBybyCommunityCode(element.communityExternalCode).subscribe(data => {
          for (let iX = 0; iX < data.length; iX++) {
            const payment = data[iX];
            const creditService = this.smaugService.getCreditCards(payment.paypointId);
            const accountService = this.smaugService.getBanksAccount(payment.paypointId);
            forkJoin(creditService, accountService).subscribe(results => {
              if (results[0]) {
                this.methods = this.methods.concat(results[0].map(x => {
                  const newPayment: PaymentItem = {
                    icon: 'credit_card',
                    name: x.name,
                    expDate: x.expDate.toString(),
                    accountId: element.accountId,
                    lastDigits: x.lastDigits,
                    token: x.token,
                    paymentMethodTypeId: 1
                  };
                  return newPayment;
                }));
              }
              if (results[1]) {
                this.methods = this.methods.concat(results[1].map(x => {
                  const newPayment: PaymentItem = {
                    icon: 'account_balance',
                    name: x.name,
                    expDate: '',
                    accountId: element.accountId,
                    lastDigits: x.lastDigits,
                    token: x.token,
                    paymentMethodTypeId: 0
                  };
                  return newPayment;
                }));
              }
              this.onMethodSelected(this.methods[0]);
              // this.methodSelected = this.methods[0];
              this.loading = false;
            });
          }
          // this.loading = false;
        }, error => {
          this.snackbar.open(error, '', { duration: 5000 });
          this.loading = false;
        });
      }
    }
  }

  pay() {
    if (this.totalAmount < 0) {
      const data = {
        Title: 'Payments',
        Message: 'This amount can not be paid'
      };
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: data
      });
      return;
    }
    if (this.methodSelected.token != null) {
      this.loading = true;
      this.accountService.makePayment({
        userSourceAccountNumber: this.sourceAccountNumber,
        accountId: this.methodSelected.accountId,
        applicationId: 1,
        classificationItemId: 1,
        token: this.methodSelected.token,
        amount: this.totalAmount
      }).subscribe(results => {
        this.loading = false;
        this.dialogRef.close({
          pay: true,
          accounts: this.dataSource.filter(x => x.checked === true),
          response: results
        });
      }, error => {
        this.snackbar.open(error, '', { duration: 5000 });
        this.loading = false;
      });
    }
  }

  openAddCreditCard() {
    if (LocalStorageHelper.getPaymentInfo().length > 0) {
    const data = {
      userSourceAccountNumber: this.sourceAccountNumber,
      paypointSourceAccountNumber: LocalStorageHelper.getPaymentInfo()[0].communityExternalCode
    };
    const dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPaymentMethod();
    });
  }
}

  openAddBankAccount() {
    if (LocalStorageHelper.getPaymentInfo().length > 0) {
    const data = {
      userSourceAccountNumber: this.sourceAccountNumber,
      paypointSourceAccountNumber: LocalStorageHelper.getPaymentInfo()[0].communityExternalCode
    };
    const dialogRef = this.dialog.open(AddAchPaymentDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPaymentMethod();
    });
  }
}

  onChange(value: boolean, index: number) {
    this.dataSource[index].checked = value;
    this.totalAmount = 0;
    this.dataSource.forEach(element => {
      if (element.checked === true) { this.totalAmount += element.amount_due; }
    });
  }

  onMethodSelected(value: PaymentItem) {
    this.methodSelected = value;

    const observableFee = this.smaugService.getFee(this.totalAmount, 1, 1).subscribe(result => {
        this.totalFee = result.filter(f => f.paymentMethodTypeId === this.methodSelected.paymentMethodTypeId)[0].fee;
        this.totalAmountWithFee = this.totalAmount + this.totalFee;
    });

  }
}
