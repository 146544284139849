import { Component, OnInit } from '@angular/core';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { Router } from '@angular/router';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material';
import { SecurityHelper } from 'src/app/helpers/security-helper';

@Component({
  selector: 'app-actions-package',
  templateUrl: './actions-package.component.html',
  styleUrls: ['./actions-package.component.css']
})
export class ActionsPackageComponent implements OnInit {

  constructor(  private dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
  }

  settings() {
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (comunityLocalStorage === 0) {
      SiteHelper.openDialogAlert('Warning', 'Select a community on breadcrumb. ', this.dialog);
    } else {
      this.router.navigateByUrl('/app/packages/' + comunityLocalStorage + '/settings');
    }
  }

}
