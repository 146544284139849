/**
 * Constants used throughout the application.
 */

export namespace EndpointNames {
    export const C3PO = 'C3PO';
    export const GridVendor = 'gridVendor';
    export const MenuService = 'menuService';
    export const NotificationService = 'notification';
    export const Pensieve = 'pensieve';
    export const SortingHat = 'sortingHat';
    export const SecurityAdminService = 'security';
    export const TardisService = 'tardis';
    export const SmaugService = 'smaug';
    export const SearchService = 'search';
    export const Newsfeed = 'newsfeed';
    export const Thorin = 'thorin';
}

