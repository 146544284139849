import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackageLog } from 'src/app/model/packageLog';
import { PackageLogService } from 'src/app/services/package-log.service';


@Component({
  selector: 'app-package-log-detail',
  templateUrl: './package-log-detail.component.html',
  styleUrls: ['./package-log-detail.component.css']
})
export class PackageLogDetailComponent implements OnInit {
  public packagesLog: PackageLog[];
  public loading: boolean;
  public breakpoint: number;
  public id: string;
  public ishot: string;
  constructor(private packageService: PackageLogService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.ishot = this.route.snapshot.paramMap.get('ishot');
    this.loading = true;
    this.packageService.getPackageLogDetail(this.id, this.ishot).subscribe(x => {
      this.packagesLog = x;
      this.loading = false;
    });
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 3;
  }

  onResize(event) {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 3;
  }

}
