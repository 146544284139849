import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { People } from '../../model/people';
import { PeopleService } from '../../services/people.service';
import { LevelFilter } from 'src/app/model/breadcrumb';

@Component({
  selector: 'app-search-people-dialog',
  templateUrl: './search-people-dialog.component.html',
  styleUrls: ['./search-people-dialog.component.css']
})
export class SearchPeopleDialogComponent implements OnInit {

  public peoples: People[];
  public selectPeople: People;
  public peopleDataSource = new MatTableDataSource<People>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public userTableColumns: string[] = [  'name',  'address' , 'accountNumber' ];
  public loading: boolean;
  constructor(public dialogRef: MatDialogRef<SearchPeopleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private peopleService: PeopleService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  applyFilter(filterValue: string) {
    if (filterValue.trim().length === 0) {
      return;
    }
    this.loading = true;
    this.peopleService.filterLevel = LevelFilter.None;
    this.peopleService.getAllPaginated(this.paginator.pageIndex + 1, this.paginator.pageSize, filterValue).subscribe( u => {
      this.peopleDataSource = new MatTableDataSource(u);
      this.peopleDataSource.paginator = this.paginator;
      this.peopleDataSource.sort = this.sort;
      this.loading = false;
    });
  }

  ngSelect(people: People): void {
    this.selectPeople = people;
    this.dialogRef.close({
      valid: true,
      data: {
        people: this.selectPeople
      }
    });
  }
  ngOnInit() {
  }

}
