import { Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { interval, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, flatMap, map, retryWhen } from 'rxjs/operators';

import { AppConfig, APP_CONFIG } from '../app.config';

import { CountResultModel } from '../model/count-result-model';
import { CrudResponseModel } from '../model/crud-response-model';
import { DataProviderInterface } from '../interfaces/data-provider-interface';
import { ErrorResponse } from '../model/error-response';
import { ServiceError } from '../model/service-error';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { environment } from 'src/environments/environment';
import { SearchParamsBreadcrumb } from '../model/search-params-breadcrumb';
import { LevelFilter } from '../model/breadcrumb';

export class AbstractDataProviderService<T> implements DataProviderInterface<T> {

  protected baseUrl: string;
  protected getHeaders: HttpHeaders;
  protected postHeaders: HttpHeaders;
  public authApiKey: boolean;
  public searchParamsBreadcrumb: SearchParamsBreadcrumb = new SearchParamsBreadcrumb();
  public breadcrumbChanges: Subscription;
  public filterLevel: LevelFilter;
  constructor(
    endpointName: string,
    protected uriSection: string,
    protected http: HttpClient,
    @Inject(APP_CONFIG) protected appConfig?: AppConfig,
    protected _filterLevel: LevelFilter = LevelFilter.None) {
      this.filterLevel = _filterLevel;
    if (this.appConfig !== null) {
      // Check whether the endpoint name exists in the endpoint dictionary.
      if (endpointName in this.appConfig.endpoints) {
        const endpoint = this.appConfig.endpoints[endpointName];
        this.baseUrl = endpoint + this.uriSection;
      } else {
        console.error('Invalid endpoint name passed to data provider.');
      }
    } else {
      // If appConfig was not injected, log an error.
      console.error('A reference to an instance of AppConfig was not injected into this service.');
    }
  }

  public setBreadcrumbParams(pmcId?: number, communityId?: number, areaId?: number ) {

    this.searchParamsBreadcrumb.propertyManagementCompanyId = pmcId ? pmcId : LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    this.searchParamsBreadcrumb.communityId = communityId ? communityId : LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.searchParamsBreadcrumb.areaId = areaId ? areaId : LocalStorageHelper.getBuildingFromBreadcrumb();
    this.searchParamsBreadcrumb.unitId =  LocalStorageHelper.getUnitIdFromBreadcrumb();
  }

  /**
   * Retries an HTTP operation for maxRetry number of times after having waited delayMs number of milliseconds.
   * @param maxRetry - The number of retries before failing.
   * @param delayMs - The number of milliseconds to wait before retrying.
   */
  private httpRetry<V>(maxRetry: number = 1, delayMs: number = 1000) {
    return (src: Observable<V>) => src.pipe(
      retryWhen(error => {
        return interval(delayMs).pipe(
          flatMap(count => count === maxRetry ? throwError(error) : of(count))
        );
      })
    );
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  protected buildGetHeaders(): HttpHeaders {
    let getHeaders = new HttpHeaders();
    if (this.authApiKey) {
        getHeaders = getHeaders.set('Authorization', 'api-key ' + environment.apiKeys.Gingotts);
    } else {
        const token = LocalStorageHelper.getAuthToken();
        getHeaders = getHeaders.set('Authorization', 'bearer ' + token);
    }

    return getHeaders;
  }

  /**
   * Builds a set of HTTP headers to send whenever a POST, PUT or PATCH request is issued.
   */
  protected buildPostHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let postHeaders = new HttpHeaders();
    postHeaders = postHeaders.set('Authorization', 'bearer ' + token);
    postHeaders = postHeaders.set('Content-Type', 'application/json');

    return postHeaders;
  }

  getLevelFilterBreadcrumb(): LevelFilter {
    return this.filterLevel;
  }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl, { headers: this.buildGetHeaders() });
  }

  getAllPaginated(pageIndex: number, pageSize: number, filter?: string): Observable<T[]> {
    this.setBreadcrumbParams();
    const route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString()  + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' +  this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }

    return this.http.get<T[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getById(id: number): Observable<T> {
    const queryString = '/byId/' + id.toString();
    return this.http.get<T>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllById(id: number): Observable<T[]> {
    const queryString = '/byId/' + id.toString();
    return this.http.get<T[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCount(filter?: string): Observable<number> {
    // this.setBreadcrumbParams();
    if (this.filterLevel === LevelFilter.None) {
    return this.http.get<CountResultModel>(this.baseUrl + '/count' , { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
    } else {
        return this.getCountByBreadcumd(filter);
    }
  }

  getCountByBreadcumd(filter?: string): Observable<number> {
    const endpoint = filter ? '/getCountByParamsFiltered/' : '/getCountByParams/';
      const queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
       + this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
      + '/' + this.searchParamsBreadcrumb.unitId + (filter ? '/' + filter : '');
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  add(entity: T): Observable<CrudResponseModel> {
    entity['originId'] = 2;
    return this.http.post<CrudResponseModel>(this.baseUrl, entity, { headers: this.buildPostHeaders() });
  }

  addBulk(entities: T[]): Observable<CrudResponseModel> {
    entities.map(x => x['originId'] = 2);
    return this.http.post<CrudResponseModel>(this.baseUrl + '/bulk', entities, { headers: this.buildPostHeaders() });
  }

  update(entity: T): Observable<null> {
    entity['originId'] = 2;
    return this.http.put<null>(this.baseUrl, entity, { headers: this.buildPostHeaders() });
  }

  delete(id: number): Observable<null> {
    return this.http.delete<null>(this.baseUrl + '/' + id.toString(), { headers: this.buildGetHeaders() });
  }

  private handleError(error: HttpErrorResponse): Observable<ServiceError> {
    const serviceError = new ServiceError();
    serviceError.errorInfo = new ErrorResponse();
    if (error instanceof ErrorEvent) {

    } else {
      if (error.error !== null) {
        serviceError.errorInfo.code = error.error.code;
        serviceError.errorInfo.description = error.error.description;
      } else {
        serviceError.errorInfo.description = 'Description unavailable.';
      }
      serviceError.errorInfo.statusCode = error.status;
    }

    return throwError(serviceError);
  }

  private tryRefreshToken() {

  }

}
