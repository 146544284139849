import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnlyNumberDirective } from '../directives/only-number.directive';
import { UnderConstructionComponent } from '../under-construction/under-construction.component';
import { MaterialModule } from '../material.module';
import { SidebarAccountComponent } from './sidebar-account/sidebar-account.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PeopleEditComponent } from '../people/people-edit/people-edit.component';
import { SearchUnitComponent } from './search-unit/search-unit.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [
    OnlyNumberDirective,
    UnderConstructionComponent,
    SidebarAccountComponent,
    PaymentMethodsComponent,
    PeopleEditComponent,
    SearchUnitComponent
  ],
  exports: [
    OnlyNumberDirective,
    UnderConstructionComponent,
    SidebarAccountComponent,
    PaymentMethodsComponent,
    PeopleEditComponent,
    SearchUnitComponent
  ]
})
export class SharedModule { }
