import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PackagesRoutingModule } from './community-packages-routing.module';
import { PackagesListComponent } from './com-packages-list/packages-list.component';
import { PackagesEditComponent } from './com-packages-edit/packages-edit.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { ReceivePackageComponent } from './receive-com-package/receive-package.component';
import { ActionsPackageComponent } from './actions-com-package/actions-package.component';
import { DeliverPackageComponent } from './deliver-com-package/deliver-package.component';
import { SettingsPackageComponent } from './settings-com-package/settings-package.component';
import { PackageLogComponent } from './com-package-log/package-log.component';
import { PackageAddComponent } from './com-package-add/package-add.component';
import { PackageAddDialogComponent } from './com-package-add-dialog/package-add-dialog.component';
import { PackageLogDetailComponent } from './com-package-log/package-log-detail/package-log-detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PackagesRoutingModule,
    MaterialModule
  ],
  declarations: [PackagesListComponent,
     PackagesEditComponent,
     ReceivePackageComponent,
     ActionsPackageComponent,
     DeliverPackageComponent,
     SettingsPackageComponent,
     PackageLogComponent,
     PackageAddComponent,
     PackageAddDialogComponent,
     PackageLogDetailComponent],
  entryComponents: [
    PackageAddDialogComponent,
  ]
})
export class PackagesModule { }
