import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CommunityConfiguration } from '../../model/community-configuration';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommunityConfigurationService } from '../../services/community-configuration.service';
import { CompanyService } from '../../services/company.service';
import { CommunityService } from '../../services/community.service';
import { Community } from '../../model/community';
import { UploadImageComponent } from '../../app-dialogs/upload-image/upload-image.component';
import { Picture } from '../../model/picture';
import { PictureService } from '../../services/picture.service';
import { stringHelper } from '../../helpers/stringHelper';
import { PictureEntry } from '../../model/picture-entry';
import { PictureCommunityService } from '../../services/picture-community.service';
import { PictureCommunity } from '../../model/picture-community';
import { CommunityMapping } from '../../model/community-mapping';
import { CommunityMappingService } from '../../services/community-mapping.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CommunityImportationFromC3poComponent } from '../community-importation-from-c3po/community-importation-from-c3po.component';
import { SourceService } from '../../services/source.service';
import { Source } from '../../model/source';
import { SmaugService } from '../../services/smaug.service';
import { PayPoint } from '../../model/smaug';
import { SiteHelper } from 'src/app/helpers/site-helper';

@Component({
  selector: 'app-community-configuration',
  templateUrl: './community-configuration.component.html',
  styleUrls: ['./community-configuration.component.css'],
})
export class CommunityConfigurationComponent implements AfterViewInit, OnInit {
  public model: CommunityConfiguration;
  public modelMapping: CommunityMapping;
  public modelCommunity: Community;
  public CommunityConfigurationFormGroup: FormGroup;
  public loading: boolean;
  public selectedFile: File;
  public imgSrc = '../../../assets/images/noimage.png';
  public changePicture = false;
  public arrayBuffer: any;
  public CommunityName: string;
  public pictures: PictureEntry[];
  public sources: Source[] = [];

  /**
 * Initializes a new instance of TagAddEditComponent.
 *
 * @param dialogRef A reference to an instance of MatDialogRef that allows
 * add/update dialogs to be opened.
 * @param data If a tag has been selected for update, the data of the tag
 * to update.
 * @param fb A reference to an instance of FormBuilder.
 * @param snackBar A reference to an instance of MatSnackBar.
 * @param dialog
 *
 */
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService,
    private companyService: CompanyService,
    private sourceService: SourceService,
    private dialog: MatDialog,
    private pictureService: PictureService,
    private pictureCommunityService: PictureCommunityService,
    private communityConfigurationService: CommunityConfigurationService,
    private communityMappingService: CommunityMappingService,
    private smaugService: SmaugService) {
    this.model = new CommunityConfiguration(this.fb);
    this.modelMapping = new CommunityMapping();
    this.CommunityConfigurationFormGroup = this.model.buildFormGroup();
  }

  ngOnInit() {
    this.loading = true;

    this.sourceService.getAll().subscribe(s => {
      this.sources = s;
    });
    this.pictures = [];
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params['id'];
      this.model.communityId = id;
      this.communityService.getById(id).subscribe(c => {
        this.modelCommunity = c;
        this.CommunityName = c.name;
        this.getLogo(c.companyId);
        this.getPictures(c.communityId);
      });
      this.communityConfigurationService.getByCommunityId(id).subscribe(config => {
        if (config.length > 0) {
          this.model.fromObject(config[0]);
          if (this.model.communityMappingId) {
            this.modelMapping.communityMappingId = this.model.communityMappingId;
            this.modelMapping.externalCode = this.model.externalCode;
            this.modelMapping.sourceId = this.model.sourceId;
            this.modelMapping.communityId = this.model.communityId;
          }
        } else {
          this.model.communityId = id;
          this.modelMapping.communityId = this.model.communityId;
        }
      });
    });
  }

  openDialogTree(areas: string): void {
    let data;
    data = {};
    const model = this.model.toDto();
    model.communityId = this.model.communityId;
    let dialogRef: MatDialogRef<CommunityImportationFromC3poComponent>;
    if (this.modelMapping.communityMappingId === 0) {
      this.modelMapping.externalCode = model.externalCode;
      this.modelMapping.sourceId = model.sourceId;
      this.modelMapping.communityId = model.communityId;
      this.communityMappingService.add(this.modelMapping).subscribe(cm => {
        this.model.communityMappingId = cm.id;
        model.communityMappingId = cm.id;
        this.modelMapping.communityMappingId = cm.id;
        dialogRef = this.openTree(model, areas);
      });
    } else {
      dialogRef = this.openTree(model, areas);
    }
  }
  openTree(model: any, areas: string): MatDialogRef<CommunityImportationFromC3poComponent> {
    const host = this.sources.filter(s => s.sourceId === model.sourceId)[0].hostId;
    const dialogRef = this.dialog.open(CommunityImportationFromC3poComponent, {
      width: '800px',
      data: { model: model, areas: areas, host: host }
    });
    return dialogRef;
  }
  ngAfterViewInit() {
  }
  addPictures() {
    this.openDialog();
  }

  openDialog(): void {
    let data;
    data = {};

    const dialogRef = this.dialog.open(UploadImageComponent, {
      width: '600px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true) {
        this.getSrcList(result.data.files);
      }
    });
  }

  getSrcList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const pic = new PictureEntry();
      const reader = new FileReader();
      reader.onload = (event: any) => {
        pic.src = event.target.result;
        pic.file = fileList[i];
        pic.name = fileList[i].name;
        pic.orderShown = this.pictures.length;
        this.pictures.push(pic);
      };
      reader.readAsDataURL(fileList[i]);
    }
  }

  getLogo(companyId: number) {
    this.companyService.getCompanyLogo(companyId).subscribe(pic => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/jpeg' });
      if (blob.size > 0) {
        const file = new File([blob], 'Logo', { type: 'image/jpeg', lastModified: Date.now() });
        this.selectedFile = file;
        reader.onload = (event: any) => {
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    });
  }
  getPictures(communityId: number) {
    this.loading = true;
    this.pictureService.GetCommunityPictureGallery(communityId).subscribe(pic => {
      if (pic) {
        this.pictures = pic;
        this.pictures.forEach((p, index) => {
          let src: string;
          const reader = new FileReader();
          const blob = new Blob([stringHelper.base64ToArrayBuffer(p.pictureData)], { type: 'image/jpeg' });
          if (blob.size > 0) {
            const file = new File([blob], 'pic', { type: 'image/jpeg', lastModified: Date.now() });
            reader.onload = (event: any) => {
              src = event.target.result;
              p.src = src;
            };
            reader.readAsDataURL(file);
          }
          this.loading = false;
        });
      } else { this.loading = false; }
    });
  }
  onFileChanged(file: FileList) {
    this.selectedFile = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgSrc = event.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
    this.changePicture = true;
  }

  @HostListener('dragover', ['$event'])
  allowDrop(ev) {
    ev.preventDefault();
  }

  @HostListener('dragstart', ['$event'])
  drag(ev) {
    ev.dataTransfer.setData('id', ev.target.id);
  }
  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    const from: number = $event.dataTransfer.getData('id');
    const pictureFrom = this.pictures[from];
    const to: number = $event.target.id;
    const pictureTo = this.pictures[to];
    pictureTo.orderShown = Number(from);
    pictureFrom.orderShown = Number(to);
    this.pictures[from] = pictureTo;
    this.pictures[to] = pictureFrom;
  }
  remove(i: number) {
    if (this.pictures[i].picture_CommunityId) {
      this.pictures[i].isDeleted = true;
    } else {
      this.pictures.splice(i, 1);
    }
  }

  save() {
    if (this.CommunityConfigurationFormGroup.valid) {
      const dto = this.model.toDto();
      dto.communityId = this.model.communityId;
      this.modelMapping.externalCode = dto.externalCode;
      this.modelMapping.sourceId = dto.sourceId;
      if (dto.communityConfigurationId) {
        this.communityConfigurationService.update(dto).subscribe();
        this.savePayPointNumber(dto.paypointNumber, this.modelMapping.externalCode, true);
        this.saveGallery(dto.communityId);
      } else {
        this.communityConfigurationService.add(dto).subscribe(result => {
          this.saveGallery(result.id);
        });
        this.savePayPointNumber(dto.paypointNumber, this.modelMapping.externalCode, false);
      }
      if (this.modelMapping.communityMappingId !== 0) {
        this.communityMappingService.update(this.modelMapping).subscribe();
      } else {
        this.modelMapping.communityId = dto.communityId;
        this.communityMappingService.add(this.modelMapping).subscribe();
      }
      this.savePicture();
      this.goToList();
    } else {
      SiteHelper.markFormGroupTouched(this.CommunityConfigurationFormGroup);
    }
  }

savePayPointNumber(payPointNumber: string, sourceCommunityCode: string, isUpdate: boolean) {
    const payPoint = new PayPoint();
    payPoint.paypointNumber = payPointNumber;
    payPoint.sourceCommunityCode = sourceCommunityCode;
    this.smaugService.getApplication().subscribe(app => {
      const appId = app[0].applicationId;
      this.smaugService.getClassificationItemById(appId).subscribe(ci => {
        const classificationItem = ci[0].classificationItemId;
        this.smaugService.getProvider(appId, classificationItem).subscribe(pro => {
          payPoint.providerId = pro[0].providerId;
          if (isUpdate) {
            this.smaugService.getPaypointBybyCommunityCode(sourceCommunityCode).subscribe(p => {
              if (p.length > 0) {
                payPoint.paypointNumberId = p[0].paypointNumberId;
              }
              this.smaugService.updatePaypoint(payPoint).subscribe();
            });
          } else {
            this.smaugService.addPaypoint(payPoint).subscribe();
          }
        });
      });
    });
  }

  savePicture() {
    if (this.selectedFile && this.changePicture) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.arrayBuffer = reader.result;
        this.companyService.setCompanyLogo(this.modelCommunity.companyId, this.arrayBuffer).subscribe();
      };
      reader.readAsArrayBuffer(this.selectedFile);
    }
  }

  saveGallery(communityId: number) {
    this.pictures.forEach(pic => {

      const picture: Picture = {
        pictureId: pic.pictureId, name: pic.name, description: pic.description, path: pic.path,
        bucketId: pic.bucketId, isDeleted: pic.isDeleted,
      };

      const pictureCommunity: PictureCommunity = {
        pictureId: pic.pictureId, picture_CommunityId: pic.picture_CommunityId,
        communityId: communityId, showInCarrusell: true, orderShown: pic.orderShown
      };

      if (!pic.pictureId) {
        this.pictureService.AddPicture(pic).subscribe(result => {
          pictureCommunity.pictureId = result.id;
          this.pictureCommunityService.add(pictureCommunity).subscribe();
        });
      } else if (pic.pictureId && !pic.isDeleted) {
        this.pictureService.update(picture).subscribe();
        this.pictureCommunityService.update(pictureCommunity).subscribe();

      } else {
        this.pictureService.delete(pic.pictureId).subscribe();
      }
    });
  }

  goToList(): void {
    this.router.navigate(['app/community/list']);
  }

  cancel(): void {
    this.goToList();
  }
}
