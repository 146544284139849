import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

import { CommunityRoutingModule } from './community-routing/community-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SharedCompanyModule } from '../companies/shared-company/shared-company.module';

import { CommunityConfigurationComponent } from './community-configuration/community-configuration.component';
import { CommunityListComponent } from './community-list/community-list.component';
import { CommunityAddEditComponent } from './community-add-edit/community-add-edit.component';

import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffEditComponent } from './staff/staff-edit/staff-edit.component';
import { StaffContactComponent } from './staff/staff-contact/staff-contact.component';
import { ContactUsModule } from '../contact-us/contact-us.module';
import { PairDeviceComponent } from '../pair-device/pair-device.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommunityRoutingModule,
    MaterialModule,
    SharedModule,
    SharedCompanyModule,
    ContactUsModule,
  ],
  exports: [
    CommunityRoutingModule,
  ],
  declarations: [
    CommunityConfigurationComponent,
    CommunityListComponent,

    StaffListComponent,
    StaffEditComponent,
    StaffContactComponent,
    PairDeviceComponent
  ],
  bootstrap: [
    CommunityAddEditComponent,
    StaffEditComponent,
  ],
})
export class CommunityModule { }
