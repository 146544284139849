import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { RegisterComponent } from '../register.component';
import { SearchService } from '../../services/search.service';
import { SearchAddress } from 'src/app/model/search-address';
import { SearchPeople } from 'src/app/model/search-people';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { RegistrationService } from 'src/app/services/registration.service';
import { UserService } from 'src/app/services/user.service';
import { NewUser } from 'src/app/model/new-user';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { MatDialog } from '@angular/material';
import { SearchUnitComponent } from 'src/app/shared/search-unit/search-unit.component';
import { EmailService } from 'src/app/services/email.service';
import { forkJoin } from 'rxjs';
import { validateConfig } from '@angular/router/src/config';

interface Contact {
  id: number;
  type: string;
  contact: string;
  peopleId: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-register-step1',
  templateUrl: './register-step1.component.html',
  styleUrls: ['./register-step1.component.css']
})
export class RegisterStep1Component extends RegisterComponent implements OnInit {
  resultSearch: SearchAddress[] = [];
  contacts: SearchPeople[] = [];
  verificationCode = '';
  showSearch = true;
  password = '';
  confirmPassword = '';
  notValidated = false;
  username = '';
  passwordInvalid = false;
  confirmPasswordInValid = false;
  isUserAvailable = true;
  profileFormGroup: FormGroup;

  constructor(
    public searchService: SearchService,
    private registrationService: RegistrationService,
    private userService: UserService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public emailService: EmailService,
  ) {
    super();
  }
  @Output() messageEvent = new EventEmitter<boolean>();
  @ViewChild('searchUnitComponent') searchUnitComponent: SearchUnitComponent;
  ngOnInit() {
    this.searchUnitComponent.onSelectedAddress().subscribe(r => {
      this.selectedAddresses = this.searchUnitComponent.selectedAddresses;
      this.showSubmit1 = this.selectedAddresses.length > 0;
    });
    this.searchUnitComponent.onSearchAddressCompleted().subscribe(r => {
      this.notFound =  this.searchUnitComponent.notFound;
    });
  }

  searchPeople(unitId: number) {
    if (unitId > 0) {
      this.searchService.findPeopleByUnitId(unitId).subscribe(results => {
        this.contacts = results;
      });
    }
  }

  selectStep1() {
    this.step = 2;
    this.showStep1 = false;
    this.showAddress = false;
    this.showSearch = false;
    this.searchUnitComponent.showAddress = false;
    this.searchPeople(this.selectedAddresses[0].unitId);
  }

  cancelProfile() {
    this.step = 2;
    this.showStep1 = false;
    this.showAddress = false;
    this.selectedContact = null;
    this.notValidated = false;
    this.searchPeople(this.selectedAddresses[0].addressId);
  }

  cancelProcess() {
    this.step = 1;
    this.showStep1 = true;
    this.showAddress = false;
    this.showSearch = true;
    this.notValidated = false;
    this.selectedContact = null;
    this.selectedAddresses = [];
  }
  change(value) {
    this.verificationCode = value;
  }
  sendCode() {
    if (this.selectedContact.type !== 'Phone') {
      this.registrationService.sendCode(this.selectedContact.id).subscribe(result => {
        this.showSearch = false;
        this.verificationCode = '';
        this.step = 3;
      });
    }
  }

  verifyCode() {
    this.emailService.authApiKey = true;
    forkJoin(this.registrationService.verifyCode(this.selectedContact.id, this.verificationCode),
    this.emailService.getByEmailId(this.selectedContact.id)).subscribe(result => {
      if (result[0]) {
        if (result[0].valid) {
          this.step = 4;
          this.profileFormGroup = this.fb.group({
            'firstName': [ this.selectedContact.firstName, Validators.required ],
            'lastName': new FormControl(this.selectedContact.lastName, Validators.required),
            'email': new FormControl(result[1][0].address, [Validators.required, Validators.email, this.checkEmail.bind(this)]),
            'password': new FormControl(this.password, Validators.required),
            'confirmPassword': ['']
          }, {validator: this.checkSamePasswords });
        } else {
          this.notValidated = true;
        }
      }
    });
  }


  checkEmail(control: AbstractControl){
    const email = control.value;
    if (email.trim() === '') {
      return;
    }
    this.userService.isUserAvailable(email).subscribe(res => {
      this.isUserAvailable =res.isUserAvailable;
      if (!this.isUserAvailable){
        control.markAsTouched();
      }
      return  !res.isUserAvailable;
    });
  }
  checkSamePasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  checkPasswordInvalid(): boolean {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
    const password = this.profileFormGroup.get('password').value;
    this.passwordInvalid = !strongRegex.test(password);
    return this.passwordInvalid;
  }
  checkConfirmPassInvalid() {
    this.confirmPasswordInValid = this.profileFormGroup.hasError('notSame');
    return this.confirmPasswordInValid;
  }

  createProfile() {
    const firstName = this.profileFormGroup.get('firstName').value;
    const lastName = this.profileFormGroup.get('lastName').value;
    const email = this.profileFormGroup.get('email').value;
    const password = this.profileFormGroup.get('password').value;
    if (this.checkPasswordInvalid() || this.checkConfirmPassInvalid()) {
      return;
    }
    this.messageEvent.emit(true);
      const user: NewUser = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        isDisabled: false,
        peopleId: this.selectedContact.peopleId,
        userName: undefined
      };

      this.userService.addNewUser(user).subscribe(result => {
        if (result) {
          this.messageEvent.emit(false);
          if (result.code === -1) {
            SiteHelper.openDialogAlert('Warning', 'The user can not be created. ' +  result.description, this.dialog);
          } else {
          this.step = 5;
          this.selectedContact = null;
          }
        }
      });
    }
}
