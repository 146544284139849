import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { PropertyManagementCompany } from '../model/property-management-company';
import { Observable } from 'rxjs';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class PropertyManagmentCompanyService extends AbstractDataProviderService<PropertyManagementCompany> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/propertyManagementCompany', http, appConfig, LevelFilter.PropertyManagementCompany);
  }

  getAllTreePaginated(pageIndex: number, pageSize: number, PropertyManagementCompanyId: number): Observable<PropertyManagementCompany[]> {
    let queryString = '/allPaged/' + pageIndex.toString() + '/' + pageSize.toString();
    if (PropertyManagementCompanyId > 0) {
     queryString = '/allPagedById/' + pageIndex.toString() + '/' + pageSize.toString() + '/' + PropertyManagementCompanyId;
    }
    return this.http.get<PropertyManagementCompany[]>(this.baseUrl + queryString ,  { headers: this.buildGetHeaders() });
  }
}

