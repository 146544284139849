import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTableDataSource,
  MatPaginator,
  MatSnackBar,
  MatSort
} from '@angular/material';

import { forkJoin } from 'rxjs';

import { AlertDialogComponent } from '../../app-dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';

import { CompanyService } from '../../services/company.service';
import { EntityService } from '../../services/entity.service';
import { RingService } from '../../services/ring.service';
import { RingTypeService } from '../../services/ring-type.service';

import { CompanyRing } from '../../model/company-ring';
import { Company } from '../../model/company';
import { Entity } from '../../model/entity';
import { ExplicitSecurity } from '../../model/explicit-security';
import { Ring } from '../../model/ring';
import { RingTableEntry } from '../../model/rings-table-entry';
import { RingType } from '../../model/ring-type';
import { Role } from '../../model/role';
import { RoleType } from 'src/app/model/role-type';
import { RoleTypeService } from 'src/app/services/role-type.service';

@Component({
  selector: 'app-role-add-edit-dialog',
  templateUrl: './role-add-edit-dialog.component.html',
  styleUrls: ['./role-add-edit-dialog.component.css']
})
export class RoleAddEditDialogComponent implements OnInit {
  public counter = 0;
  public model: Role;
  public rings: RingTableEntry[];
  public ringsAll: Ring[];
  public ringsByRole: number[] = [];
  public companiesByRole: number[] = [];

  public ringModel: RingTableEntry;
  public ringTableColumns: string[] = ['select', 'ringType', 'entity', 'name'];
  public ringDataSource = new MatTableDataSource<RingTableEntry>();
  public companies: Company[];
  public ringsToAdd: number[];
  public ringsToDelete: number[];
  public companyRing: CompanyRing[];
  public explicitSecurity: ExplicitSecurity[];
  public explicitSecurityAdd: ExplicitSecurity[] = [];
  public explicitSecurityToDelete: ExplicitSecurity[] = [];
  public explicitSecurityAll:  ExplicitSecurity[] = [];
  public roleFormGroup: FormGroup;
  public ringsTypes: RingType[];
  public entities: Entity[];
  public roleTypes: RoleType[] = [];
  public loading: boolean;
  public TITLE_WARNING = 'Warning';
  public SELECT_A_COMPANY = 'Must select a company';
  public SELECT_RINGS = 'Must select actions for the company';
  public FIELD_REQUIREDS = 'Must select companies and actions';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchText') searchText: ElementRef;

  constructor(public dialogRef: MatDialogRef<RoleAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ringService: RingService,
    private ringTypeService: RingTypeService,
    private entityService: EntityService,
    private roleTypeService: RoleTypeService,
    private companyService: CompanyService) {

      this.model = new Role(this.fb);
      this.roleFormGroup = this.model.buildFormGroup();
  }

  ngOnInit() {
    this.loading = true;
    this.ringModel = new RingTableEntry();
    this.rings = [];
    this.companyRing = [];
    this.initModel();

    forkJoin(
      this.ringTypeService.getAll(),
      this.entityService.getAll(),
      this.companyService.getAll(),
      this.roleTypeService.getAll())
    .subscribe(results => {
      this.ringsTypes = results[0];
      this.entities = results[1];
      this.companies = results[2];
      this.roleTypes = results[3];
      this.updateDataSource();
    });
  }

  initModel() {
    if (this.data.update === true) {
      this.model.fromObject(this.data.model.role);
      this.explicitSecurity = this.data.model.explicitSecurity;

      forkJoin(this.ringService.getByRoleId(this.data.model.role.roleId)).subscribe(result => {
            this.ringsByRole = result[0].map(role => role.ringId);
      });
    }
    if (this.data.update === false || (this.data.update === true && this.data.model.explicitSecurity.length === 0 )) {
      this.explicitSecurity = [];
      this.companyRing = [];
      this.companyRing[0] = new CompanyRing();
      this.companyRing[0].companies = [];
      this.companyRing[0].rings = [];
    }
  }


  updateDataSource() {
    this.ringService.getAll().subscribe(x => {
      this.ringsAll = x;
      for (const r of this.ringsAll) {
        const ring = new RingTableEntry();
        ring.ringId = r.ringId;
        ring.ringTypeId = r.ringTypeId;
        ring.name = r.name;
        ring.ringTypeName = this.ringsTypes.find(t => t.ringTypeId === r.ringTypeId).name;
        ring.entityId = r.entityId;
        ring.entityName = this.entities.find(e => e.entityId === r.entityId).name;
        this.rings.push(ring);
      }
      if (this.data.update && this.data.model.explicitSecurity.length > 0) {
        this.getCompanyRings();
      }
      this.ringDataSource = new MatTableDataSource(this.rings);
      this.ringDataSource.paginator = this.paginator;
      this.ringDataSource.sort = this.sort;
      this.loading = false;
    });
  }

  onChangeSelection(item, e): void {
    item.select = true;
    if (this.companyRing[this.counter].companies.length === 0) {
      this.openDialog(this.TITLE_WARNING, this.SELECT_A_COMPANY);
      e.preventDefault();
      return;
    }
    this.addRing(item);
  }
  changeSelectionExpSecu(i) {
      this.counter =  i;
      this.rings.forEach(ri => {
        ri.select =  this.companyRing[this.counter].rings.filter(ring => ring.ringId === ri.ringId).length > 0;
      });
  }

  addRing(ring: RingTableEntry): void {
    this.companyRing[this.counter].rings.unshift(ring);
  }

  getCompanyRings() {
    let count = 0;
    const distinctCompanies = Array.from(new Set(this.explicitSecurity.map((item: any) => item.companyId)));
    const ringsCom: number[][] = [];

    distinctCompanies.forEach((comId, index) => {
      ringsCom.push(this.explicitSecurity.filter(es => es.companyId === comId).map((item: any) => item.ringId));
    });

    distinctCompanies.forEach((comId, index) => {
      this.companyRing[index] = new CompanyRing();
      count = index === 0 || ringsCom[index] !== ringsCom[index - 1] ? count : count - 1;
      if (count === index) { this.companyRing[count].companies = []; }
      this.companyRing[count].companies.push(this.companies.find(com => com.companyId === comId));
      const idx = this.companies.findIndex(x => x.companyId === comId);
      this.companies.splice(idx, 1);

      if (count === index) {
        this.companyRing[index].rings = [];
        ringsCom[index].forEach(r => {
          this.companyRing[index].rings.push(this.rings.find(ring => r === ring.ringId));
        });
      }

      count++;
    });
    this.counter = 0;
    ringsCom[this.counter].forEach(r => {
      this.rings.find(ri => ri.ringId === r).select = true;
    });
    this.rings = this.rings.sort((b, a) => Number(a.select) - Number(b.select));
  }

  clearSelect(com, i, j) {
    this.companies.push(com);
    this.companyRing[i].companies.splice(j, 1);
    if (this.companyRing[i].companies.length === 0) {
      this.companyRing[i].rings = [];
    }
  }

  clearSelectRing(ring, i, j) {
    if ( j === -1) {
      this.companyRing[i].rings = [];
      if (i === this.counter) {
        this.rings.find(r => r.select = false);
      } else {
        this.companyRing[i].companies.forEach(c => this.companies.push(c)) ;
        this.companyRing[i].companies = [];
      }
    } else {
    this.rings.find(r => r.ringId === ring.ringId).select = false;
    this.companyRing[i].rings.splice(j, 1);
    }
  }

  isAllSelected(): boolean {
    if (!this.ringDataSource) { return false; }
    return this.rings.length === this.rings.filter(r => r.select).length;
  }

  masterToggle(e) {
    if (!this.ringDataSource) { return; }
    if (this.companyRing[this.counter].companies.length === 0) {
      this.openDialog(this.TITLE_WARNING, this.SELECT_A_COMPANY);
      return;
    }
    const select = this.isAllSelected();
    this.rings.forEach(d => {
      d.select = !select;
      this.addRing(d);
    });
    this.ringDataSource = new MatTableDataSource(this.rings);
    this.ringDataSource.paginator = this.paginator;
    this.ringDataSource.sort = this.sort;
  }

  select(com) {
    if (this.companyRing.length > 0 && this.companyRing[this.counter].rings.length > 0) {
      this.counter++;
      const companyRing = new CompanyRing();
       companyRing.companies = [];
       companyRing.rings = [];
       this.companyRing.unshift(companyRing);
    }
    this.companyRing[0].companies.unshift(com);
    const idx = this.companies.findIndex(x => x.companyId === com.companyId);
    if (idx > -1) {
      this.companies.splice(idx, 1);
    }
    this.counter = 0;
    this.rings.forEach(d => d.select = false);
  }

  getExplicitSecurity() {
    this.companyRing.forEach((cr, index) => {
      cr.companies.forEach((com) => {
        this.companyRing[index].rings.forEach(rin => {
          const expSecu = new ExplicitSecurity();
          expSecu.roleId =  this.model.roleId;
          expSecu.companyId = com.companyId;
          expSecu.ringId = rin.ringId;
         if (this.explicitSecurity.filter(exp => exp.companyId === com.companyId &&
            exp.ringId === rin.ringId).length === 0) {
              this.explicitSecurityAdd.push(expSecu);
            }
            this.explicitSecurityAll.push(expSecu);
        });
      });
    });
    this.explicitSecurity.forEach(exp => {
      if (this.explicitSecurityAll.filter(int => int.companyId === exp.companyId && int.ringId === exp.ringId).length === 0) {
        this.explicitSecurityToDelete.push(exp);
      }
    });
  }


  getRoleRings() {
    const ringsNew = Array.from(new Set(this.explicitSecurityAll.map(exp => exp.ringId)));
    this.ringsToAdd = ringsNew.filter(c => !this.ringsByRole.includes(c));
    this.ringsToDelete = this.ringsByRole.filter(rol => !ringsNew.includes(rol));
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  isAnySelected() {
    const lenSelect = this.rings.filter(r => r.select).length;
    return this.rings.length !== lenSelect && lenSelect > 0;
  }

  onCancel(): void {
    if (this.roleFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else {
      this.dialogRef.close({ valid: false });
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.ringDataSource.filter = filterValue;
  }

  onSubmit(): void {

    if (this.companyRing.length === 0) {
      this.openDialog(this.TITLE_WARNING, this.FIELD_REQUIREDS);
      return;
    }
    if (this.companyRing[this.companyRing.length - 1].companies.length > 0 &&
      this.companyRing[this.companyRing.length - 1].rings.length === 0) {
      this.openDialog(this.TITLE_WARNING, this.SELECT_RINGS);
      return;
    }
    const entity = this.model.toDto();
    this.getExplicitSecurity();
    this.getRoleRings();
    this.dialogRef.close({
      valid: true,
      entity: entity,
      explicitSecurityToAdd: this.explicitSecurityAdd,
      explicitSecurityToDelete: this.explicitSecurityToDelete,
      ringsToAdd: this.ringsToAdd,
      ringsToDelete: this.ringsToDelete
    });
  }
}
