export class ResidentSearch {
    communityId: number;
    communityName: string;
    peopleId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    relationshipId: number;
    address: string;
    phone: string;
    unit: string;
    unitId: number;
}
