import { Component, OnInit } from '@angular/core';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { SystemInfoService } from '../services/system-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public version: string;
  private showLink = false;
  constructor(private systemInfoService: SystemInfoService) {
  }

  ngOnInit() {
    this.showLink = false;
    this.systemInfoService.getServiceInfo().subscribe(s => {
      LocalStorageHelper.setSystemInfo(s);
      this.version = s[0].version;
      this.showLink = (s.length > 1);
    });
  }
}
