import { Component, OnInit, Input } from '@angular/core';
import { Menu } from '../model/menu';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.css']
})
export class MenuListItemComponent implements OnInit {

  @Input() item: Menu;
  @Input() class: string;
  @Input() index: number;
  @Input() collapse: boolean;

  constructor() { }

  ngOnInit() {
  }

  showChildren(item: Menu, e) {
    if (item.children.length > 0) {
      e.preventDefault();
      item.opened = !item.opened;
    } else {
      if (item.click !== undefined) {
        item.click();
      }
    }
  }

}
