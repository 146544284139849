import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { VisitorLogEntryComponent } from './visitor-log-entry/visitor-log-entry.component';
import { VisitorsRoutingModule } from './visitors-routing.module';
import { ActionsVisitorComponent } from '././actions-visitor/actions-visitor.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    VisitorsRoutingModule
  ],
  declarations: [
    VisitorLogEntryComponent,
    ActionsVisitorComponent
  ]
})
export class VisitorsModule { }
