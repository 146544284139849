import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NewsfeedService } from '../services/newsfeed.service';
import { Newsfeed } from '../model/newsfeed';
import { MatIconRegistry, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageHelper } from '../helpers/local-storage-helper';


const timestampFactor = 621355968000000000;

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent implements OnInit {
  newsfeed: Newsfeed[] = [];
  loading = true;

  @Output() newsFeedChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private snackbar: MatSnackBar,
    private domSanitizer: DomSanitizer,
    private newsfeedService: NewsfeedService
  ) {
    this.matIconRegistry.addSvgIcon(
      'emergency',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/bell.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'package',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/email.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'visitor',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/add-guest.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'balance',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/payment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'circle-ellipsis',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/circle-ellipsis.svg')
    );
  }

  ngOnInit() {
    this.populateNewsFeed();
    LocalStorageHelper.watchImpersonatedUser().subscribe(e  => {
      this.populateNewsFeed();
    });
  }

  populateNewsFeed() {
    this.newsfeedService.getNewsfeedByUserId().subscribe(results => {
      this.newsfeed = results.map(x => {
        const newX: Newsfeed = x;
        newX.timestamp = this.convert(parseInt(x.timestamp, 10));
        return newX;
      }).sort((a, b) => (parseInt(a.timestamp, 10) - parseInt(b.timestamp, 10)));
      this.loading = false;
    }, error => {
      this.snackbar.open(error, '', { duration: 5000 });
      this.loading = false;
    });
  }
  convert(timestamp: number) {
    const secondsO = (timestamp - timestampFactor) / 10000;
    const msecPerMinute = 1000 * 60;
    const msecPerHour = msecPerMinute * 60;
    const msecPerDay = msecPerHour * 24;

    let interval = Date.now() - secondsO;
    const days = Math.floor(interval / msecPerDay );
    interval = interval - (days * msecPerDay );

    const hours = Math.floor(interval / msecPerHour );
    interval = interval - (hours * msecPerHour );

    const minutes = Math.floor(interval / msecPerMinute );
    interval = interval - (minutes * msecPerMinute );

    const seconds = Math.floor(interval / 1000 );

    if (days > 0) { return `${days}d Ago`; } else {
      if (hours > 0) { return `${hours}h Ago`; } else {
        if (minutes > 0) { return `${minutes}m Ago`; } else {
          return 'now';
        }
      }
    }
    // const date = new Date(secondsO);
    // return date;
  }

}
