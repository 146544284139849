import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { LoginService } from '../services/login.service';
import { PeopleService } from '../services/people.service';
import { SystemInfoService } from '../services/system-info.service';
import { UserService } from '../services/user.service';

import { AccountDetailsComponent } from '../account/account-details/account-details.component';
import { SelectUserDialogsComponent } from '../app-dialogs/select-user-dialogs/select-user-dialogs.component';
import { CompanyService } from '../services/company.service';
import { CommunityService } from '../services/community.service';
import { SiteHelper } from '../helpers/site-helper';
import { Observable, forkJoin } from 'rxjs';
import { LoginComponent } from '../login/login.component';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { MenuService } from '../services/menu.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { UserImpersonated } from '../model/user-impersonated';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  public collapse: boolean;
  public userInfo: string;
  public userInfoImpersonate: string;
  public userPicture: string;
  public companyLogo: string;
  public companyInfo: string;
  public hasPhoto = false;
  public hasLogo = false;
  public impersonate = false;
  public title = 'Select user to impersonate';
  public titleButton = 'Impersonate';
  public isCommunity: boolean;
  public version: string;
  public alowImpersonate = false;

  @Input() sideNav: SideNavComponent;

  constructor(private _router: Router,
     private _loginService: LoginService,
     private infoService: SystemInfoService,
     private peopleService: PeopleService,
     private companyService: CompanyService,
     private communityService: CommunityService,
     private userService: UserService,
     private menuService: MenuService,
     private dialog: MatDialog) {
    this.collapse = false;
   }

  logout(): void {
    this._loginService.logout();
    this._router.navigateByUrl('/login');
  }

  account(): void {
    this.openDialog();
  }

  impersonateUser() {
    if (this.impersonate) {
      this.sideNav.loading = true;
      this.userService.clearImpersonate().subscribe( i => {
        this.impersonate = false;
        LocalStorageHelper.setUserImpersonatedInfo(null);
        LocalStorageHelper.setBreadcrumbInfo(null);
        this.reloadUserInfo();
     });
    } else {
      this.openDialogUsers();
    }
  }

  openDialogUsers(): void {
    const dialogRef = this.dialog.open(SelectUserDialogsComponent, {
      data: { title: this.title , titleButton: this.titleButton},
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.sideNav.loading = true;
        this.userService.impersonate(result.data.user).subscribe( u => {
          LocalStorageHelper.setUserImpersonatedInfo(u);
          LocalStorageHelper.setBreadcrumbInfo(null);
          LocalStorageHelper.setLoggedUserInfo(null);
           this.impersonate = u !== null && u.success;
           this.reloadUserInfo(u);
        });
      }
    });
  }
  reloadUserInfo(user?: UserImpersonated) {
    const userName = user ? user.username : LocalStorageHelper.getUsername();
    this.userInfoImpersonate = user ? user.firstName + ' ' + user.lastName : '';
    this.sideNav.breadcrumb.populateBreadcrumb();
    const menuObservable = this.menuService.getMenusByAppName('GEWEB');
    const paymentInfoObservable = this.peopleService.getPaymentInfo(userName);
    const loggerUserObservable = this.userService.getLoggedUserInfo();
    forkJoin(
      menuObservable,
      paymentInfoObservable,
      loggerUserObservable).subscribe(results => {
      LocalStorageHelper.setUserMenu(results[0][0]);
      this.sideNav.populateMenu(); // reload menu
      LocalStorageHelper.setPaymentInfo(results[1]);
      LocalStorageHelper.setLoggedUserInfo(results[2]);
      this._router.navigate(['/']);
      this.sideNav.loading = false;

    });

  }
  openDialog(): void {
   const dialogRef = this.dialog.open(AccountDetailsComponent, {
      width: '800px',
      data: {isImpersonated : this.impersonate},
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  fire(target) {
    target.dispatchEvent(new Event('collapse', { bubbles: true }));
  }

  ngOnInit() {
    this.userService.isStaff.subscribe(isStaff => this.alowImpersonate = isStaff);
    this.peopleService.setUserPicture();
    this.setUserInfo();
    this.setCommunityInfo();
    if (SiteHelper.isCommunity()) {
      this.communityService.getBySubDomain(location.host.split('.')[0]).subscribe(c => {

        if (Array.isArray(c) && c.length > 0) {
          this.companyService.setCommunity(c[0]);
          this.setCommunityInfo();
        }
    });
  }
  }



  setUserInfo(): void {
    this.peopleService.getUserInfo().subscribe( userInfo => {
        this.userInfo = userInfo;
    });

    const userImpersonate = LocalStorageHelper.getUserImpersonatedInfo();
    if (userImpersonate) {
      this.impersonate = true;
      this.alowImpersonate = true;
      this.reloadUserInfo(userImpersonate);
    }
    this.peopleService.currentUserPicture.subscribe( userPicture => {
      this.userPicture = userPicture;
      this.hasPhoto = (userPicture !== null && userPicture !== undefined && userPicture !== '');
  });
}
  setCommunityInfo(): void {
    this.companyService.currentCompanyLogo.subscribe(logo => {
      this.companyLogo = logo;
      this.hasLogo = (logo !== null && logo !== undefined);
    });
    this.companyService.currentCompanyInfo.subscribe(info => {
      this.companyInfo = info;
    });
  }

}
