import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitor-log-entry',
  templateUrl: './visitor-log-entry.component.html',
  styleUrls: ['./visitor-log-entry.component.css']
})
export class VisitorLogEntryComponent implements OnInit {
 public isLoading: boolean;
 public loading: boolean;
  constructor() { }

  ngOnInit() {
  }

}
