import { Component, Injector, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { Observable } from 'rxjs';

import { BaseList } from '../../building-blocks/base-list';

import { PropertyManagementCompany } from '../../model/property-management-company';
import { PropertyManagmentCompanyService } from '../../services/property-managment-company.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { map } from 'rxjs/operators';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';

const propertyManagementCompanyColumns = [
  'name',
  'actions',
];

@Component({
  selector: 'app-property-management-company-list',
  templateUrl: './property-management-company-list.component.html',
  styleUrls: ['./property-management-company-list.component.css']
})
export class PropertyManagementCompanyListComponent extends BaseList<PropertyManagementCompany> implements OnDestroy {

  protected dataSourceAll: PropertyManagementCompany[] = [];
  protected states: boolean[] = [];
  protected collapseAll = true;
  public pmcPermisions = new EntityRing();
  constructor(
    public injector: Injector,
    private snackbar: MatSnackBar,
    private propertyManagmentCompanyService: PropertyManagmentCompanyService ,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef) {

      super(propertyManagementCompanyColumns, '/propertyManagmentCompany', propertyManagmentCompanyService,
       'propertyManagmentCompany', injector); { }
       this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.pmcPermisions.rings.push({ accessType: AccessType.Add, ringId: 511 });
    this.pmcPermisions.rings.push({ accessType: AccessType.Delete, ringId: 611 });
    this.pmcPermisions.rings.push({ accessType: AccessType.Update, ringId: 711 });
    this.entityRings.push(this.pmcPermisions);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<PropertyManagementCompany[]> {
     return this.propertyManagmentCompanyService.getAllPaginated(pageIndex, pageSize)
     .pipe(map(result => this.renderTree(result)));
  }
  protected renderTree(array: PropertyManagementCompany[]): PropertyManagementCompany[] {
    this.loading = true;
    this.dataSourceAll = array;
    this.dataSourceAll.forEach((p, i) => this.states[i] = false);
    this.loading = false;
    return this.dataSourceAll;
  }

  protected getCount(): Observable<number> {
    return this.propertyManagmentCompanyService.getCount();
  }

  protected openEditForm(isUpdate: boolean, model: PropertyManagementCompany, emitter: any) {
  }

  protected clickOpenCollapse(parentId: number, index: number, isOpen: boolean): void {
    this.collapseAll = false;
    this.dataSourceAll.forEach((mc, i)  => {
      if (mc.parent ===  parentId) {
        this.states[i] = isOpen;
      }
    });
    this.states[index] = isOpen;
  }

  protected opened(index: number,  isOpen: boolean ): boolean {
    return this.collapseAll ?  !isOpen : this.states[index] === isOpen;
  }

  public add(parent: number) {
    this.router.navigate(['app/management-company/add/' + parent ]);
  }

  public edit(id: number): void {
    this.router.navigate(['app/management-company/edit/' + id]);
  }

  public remove(id: number): void {
    if (confirm('Are you sure you want to delete this Management Company?')) {
      this.loading = true;
      this.propertyManagmentCompanyService.delete(id).subscribe(x => {
        this.breadcrumbService.watchUpdated(true).subscribe();
        this.dataSource.reload();
            this.loading = false;
      });
    }
  }
}
