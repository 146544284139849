import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PaymentDialogComponent } from 'src/app/app-dialogs/payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material';
import { AccountData } from 'src/app/model/account-data';
import { PaymentConfirmationComponent } from 'src/app/app-dialogs/payment-confirmation/payment-confirmation.component';
import { forkJoin, Subscription } from 'rxjs';
import { C3POService } from 'src/app/services/c3po.service';
import { AccountService } from 'src/app/services/account.service';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { PaymentInfo } from 'src/app/model/paymentInfo';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';

@Component({
  selector: 'app-sidebar-account',
  templateUrl: './sidebar-account.component.html',
  styleUrls: ['./sidebar-account.component.css']
})
export class SidebarAccountComponent  extends BaseSecurity implements OnInit , OnDestroy {
  totalAmount = 0;
  protected breadcrumbChanges: Subscription;
  protected breadcrumbChangesInfo: Subscription;
  protected showOtherUnits: boolean;
  public payPermisions = new EntityRing();

  @Input() unitSelected = '233 GC';

  selectedAccount: AccountData = {
    account: '',
    amount_due: 0,
    checked: true
  };

  public accounts: number[] = [];

  loading = true;

  unitAccounts: AccountData[] = [];

  constructor(
    private dialog: MatDialog,
    private accountService: AccountService,
  ) {
    super();
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.payPermisions.rings.push({ringId: 7012, accessType: AccessType.Add});
    this.entityRings.push(this.payPermisions);
  }

  ngOnDestroy() {
    if (this.breadcrumbChanges) {
      this.breadcrumbChanges.unsubscribe();
    }
    if (this.breadcrumbChangesInfo) {
    this.breadcrumbChangesInfo .unsubscribe();
    }
  }

  ngOnInit() {
    let paymentInfo = LocalStorageHelper.getPaymentInfo().filter(x => (x.accountExternalCode));
    this.unitSelected = LocalStorageHelper.getUnitFromBreadcrumb();
    this.populateBalance(paymentInfo);
    this.breadcrumbChanges  = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      this.unitSelected = LocalStorageHelper.getUnitFromBreadcrumb();
    });
    this.breadcrumbChangesInfo  = LocalStorageHelper.watchPaymentInfo().subscribe(changes => {
        paymentInfo = LocalStorageHelper.getPaymentInfo().filter(x => (x.accountExternalCode));
        this.populateBalance(paymentInfo);
    });
}

populateBalance(paymentInfo: PaymentInfo[]) {
  this.unitAccounts = [];
  this.accounts = [];
  if (paymentInfo.length > 0) {
  this.accounts = paymentInfo.map(x => x.accountExternalCode);
    const observableBalanceArray = this.accountService.getBalances();
    forkJoin(observableBalanceArray).subscribe(results => {
      for (let i = 0; i < results[0].length; i++) {
        const element = results[0][i];
        this.unitAccounts.push({
          account: this.accounts[i].toString(),
          amount_due: element.amount,
          checked: true
        });
       }
      this.selectedAccount = this.unitAccounts[0];
      this.totalAmount += results[0].map(s => s.amount).reduce((acc, val) => {
          return acc + val;
      });
      this.showOtherUnits = this.unitAccounts.length > 1;
      this.loading = false;
    });
  }
}

  openPayment(value: string) {
    let accountsToPay: string[] = [];
    (Array.isArray(value)) ? accountsToPay = value : accountsToPay.push(value);
    const data = {
      accounts: accountsToPay
    };
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dialogConfirmation = this.dialog.open(PaymentConfirmationComponent, {
          data: result
        });
        dialogConfirmation.afterClosed().subscribe(results => {

        });
      }
    });
  }

}
