import { Component, EventEmitter, Injector, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { Observable } from 'rxjs';
import { BaseList } from '../../building-blocks/base-list';

import { Community } from '../../model/community';
import { CommunityTree } from '../../model/community-tree';

import { CommunityService } from '../../services/community.service';
import { CrudResponseModel } from '../../model/crud-response-model';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { map } from 'rxjs/operators';

import { EntityRing, AccessType } from 'src/app/model/access-type-ring';
import { SecurityHelper } from 'src/app/helpers/security-helper';

const communityColumns = [
  'name',
  'actions',
];


@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.css']
})
export class CommunityListComponent extends BaseList<Community> implements OnDestroy {
  public collapseAll = true;
  protected communityIdSelected = 0;
  protected managementCompanyIdSelected = 0;
  public dataSourceAll: CommunityTree[];
  public communityPermisions = new EntityRing();
  public settingPermisions = new EntityRing();
  constructor(
    public injector: Injector,
    private communityService: CommunityService,
    private breadcrumbService: BreadcrumbService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef) {
    super(communityColumns, '/community', communityService, 'community', injector); { }
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.communityPermisions.rings.push({ accessType: AccessType.Add, ringId: 508 });
    this.communityPermisions.rings.push({ accessType: AccessType.Delete, ringId: 608 });
    this.communityPermisions.rings.push({ accessType: AccessType.Update, ringId: 708 });
    this.entityRings.push(this.communityPermisions);

    this.settingPermisions.rings.push({ accessType: AccessType.Add, ringId: 514 });
    this.settingPermisions.rings.push({ accessType: AccessType.Update, ringId: 714 });
    this.entityRings.push(this.settingPermisions);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Community[]> {
    this.loading = true;
    return this.communityService.getAllPaginated(pageIndex, pageSize).pipe(map(result => this.renderTree(result)));
  }

  protected renderTree(communitiesArray: Community[]): Community[] {
    this.loading = true;
    this.dataSourceAll = communitiesArray as CommunityTree[];
    this.loading = false;
    return communitiesArray;
  }
  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any { }

  protected opened(index: number, isOpen: boolean): boolean {
    return this.collapseAll ? !isOpen : this.dataSourceAll[index].openChilds === isOpen;
  }

  protected clickOpenCollapse(community: CommunityTree, open: boolean, index: number): void {
    this.collapseAll = false;
    this.dataSourceAll[index].openChilds = true; // open;
    this.dataSourceAll.forEach((co, i) => {
      if (co.parent === community.id) {
        co.state = open;
        this.clickOpenCollapse(co, open, i);
      }
    });
  }

  protected getCount(): Observable<number> {
    return this.communityService.getCount();
  }

  public edit(communityId: number): void {
    this.router.navigate(['app/community/edit/' + communityId]);
  }

  public configure(communityId: number): void {
    this.router.navigate(['app/community/setting/' + communityId]);
  }

  public remove(communityId: number): void {
    if (confirm('Are you sure you want to delete this item?')) {
      this.loading = true;
      this.communityService.delete(communityId).subscribe(result => {
        this.breadcrumbService.watchUpdated(true).subscribe();
        this.dataSource.reload();
        this.loading = false;
      });
    }
  }

  public add(community: CommunityTree) {
    this.router.navigate(['app/community/add/' + community.id + '/' + community.type]);
  }

  public validateClaim(ringId: number): boolean {
    return SecurityHelper.ValidatePermition(ringId);
  }
}
