import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Observable } from 'rxjs';

import { Address } from '../model/address';
import { CrudResponseModel } from '../model/crud-response-model';
import { AddressTableEntry } from '../model/address-table-entry';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends AbstractDataProviderService<Address> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/address', http, appConfig);
  }

  linkAddressToCompany(addressId: number, companyId: number, isPreferred: boolean ): Observable<CrudResponseModel> {
    const body = {
      'addressId': addressId,
      'companyId': companyId,
      'name': name,
      'isPreferred': isPreferred
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/company', body, { headers: this.buildPostHeaders() });
  }

  setPreferedAddress(addressId: number, peopleId: number): Observable<CrudResponseModel> {
    const body = {
      'addressId': addressId
    };
    return this.http.post<CrudResponseModel>(this.baseUrl + '/setPreferredForPeople/' + peopleId,
     body, { headers: this.buildPostHeaders() });
  }

  unlinkPeopleAddressFromRelationship(relationshipId: number): Observable<CrudResponseModel> {
    const body = {
      'relationshipId': relationshipId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlinkPeopleAddressFromRelationship',
     body, { headers: this.buildPostHeaders() });
  }

  unlinkPeopleAddressFromPeople(peopleId: number): Observable<CrudResponseModel> {
    const body = {
      'peopleId': peopleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlinkPeopleAddressFromPeople',
     body, { headers: this.buildPostHeaders() });
  }

  unlinkAddressFromCompany(addressId: number, companyId: number): Observable<CrudResponseModel> {
    const body = {
      'addressId': addressId,
      'companyId': companyId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/company', body, { headers: this.buildPostHeaders() });
  }

  getByCompanyId(companyId: number): Observable<AddressTableEntry[]> {
    return this.http.get<AddressTableEntry[]>(this.baseUrl + '/byCompanyId/' + companyId.toString(), { headers: this.buildGetHeaders() });
  }

  getByPeopleId(peopleId: number): Observable<AddressTableEntry[]> {
    return this.http.get<AddressTableEntry[]>(this.baseUrl + '/byPeopleId/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }

  getByRelationship(peopleId: number): Observable<AddressTableEntry[]> {
    return this.http.get<AddressTableEntry[]>(this.baseUrl + '/byRelationship/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }

  linkAddressToPeople(addressId: number, peopleId: number): Observable<CrudResponseModel> {
    const body = {
      'addressId': addressId,
      'peopleId': peopleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/people', body, { headers: this.buildPostHeaders() });
  }

  linkAddressToRelationship(addressId: number, relationshipId: number): Observable<CrudResponseModel> {
    const body = {
      'addressId': addressId,
      'relationshipId': relationshipId,
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/relationship', body, { headers: this.buildPostHeaders() });
  }

  unlinkAddressFromPeople(addressId: number, peopleId: number): Observable<CrudResponseModel> {
    const body = {
      'addressId': addressId,
      'peopleId': peopleId,
    };
    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/people', body, { headers: this.buildPostHeaders() });
  }

  getAddressesRelatedToPeopleId(peopleId: number): Observable<AddressTableEntry[]> {
    return this.http.get<AddressTableEntry[]>(this.baseUrl + '/getAddressesRelatedToPeopleId/' +
     peopleId, { headers: this.buildGetHeaders() });
  }

}
