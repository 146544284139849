import { AfterViewInit, Component, OnInit,  QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { MatPaginator, MatTable, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';

import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';

import { C3POService } from '../../services/c3po.service';
import { PaymentDialogComponent } from 'src/app/app-dialogs/payment-dialog/payment-dialog.component';
import { PaymentConfirmationComponent } from 'src/app/app-dialogs/payment-confirmation/payment-confirmation.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AccountService } from 'src/app/services/account.service';
import { FinancialsAccount, BalanceAccount } from 'src/app/model/account';
import { RelationshipService } from 'src/app/services/relationship.service';
import { PaymentInfo } from 'src/app/model/paymentInfo';
import { Unit } from 'src/app/model/unit';
import { UnitService } from 'src/app/services/unit.service';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { AccessType } from 'src/app/model/access-type-ring';
import { Transaction } from 'src/app/model/transaction';
import { TransactionItem } from 'src/app/model/transaction-item';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent extends BaseSecurity implements AfterViewInit,  OnDestroy {

  public displayedColumns = [
    'description',
    'amount',
    'transType',
    'date'
  ];

  public accounts: number[] = [];

  @ViewChildren('paginator') paginatorList: QueryList<MatPaginator>;

  public dataSourceArray: MatTableDataSource<TransactionItem>[] = [];

  public balances: number[] = [];

  private loadComplete$: BehaviorSubject<boolean>;

  private observableBalanceArray: Observable<BalanceAccount[]>;

  public loading: boolean;

  public unitId: number;

  public unitName: string;

  private units: number[] = [];

  private paymentInfo: PaymentInfo[];

  protected breadcrumbChanges: Subscription;

  constructor(
    private c3poService: C3POService,
    private accountService: AccountService,
    private unitService: UnitService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private relationshipService: RelationshipService
  ) {
    super();
    this.populateTansactions();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(e  => {
      this.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
      this.unitName = LocalStorageHelper.getUnitFromBreadcrumb();
      if (this.unitId > 0) {
        this.populateTansactions();
      }
    });
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.securityEntity.rings.push({ringId : 7012, accessType: AccessType.Add });
    this.securityEntity.rings.push({ringId : 7013, accessType: AccessType.Execute });
    this.entityRings.push(this.securityEntity);
  }
  ngOnDestroy() {
    if (this.breadcrumbChanges) {
    this.breadcrumbChanges .unsubscribe();
    }
  }

  populateTansactions() {
    this.loadComplete$ = new BehaviorSubject(false);
    this.loading = true;

    this.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
    this.paymentInfo = LocalStorageHelper.getPaymentInfo().filter(x => (x.accountExternalCode));
    if (this.paymentInfo.length > 0 || this.unitId > 0) {

      if (this.unitId > 0) {
        this.observableBalanceArray = this.accountService.getBalancesByUnitId(this.unitId);
      } else {
          this.observableBalanceArray = this.accountService.getBalances();
      }
      const observableTransacctions = this.accountService.getAllTransactions();

      forkJoin(this.observableBalanceArray,
              observableTransacctions).subscribe(results => {
        if (results.length > 0 && results[0] && results[0].length > 0) {
            this.accounts =  results[0].map(accountNo => Number(accountNo.accountCode));
            this.balances = results[0].map(balances => this.balance(balances));
             const transactionArray = results[0]
            .map(accountNo => results[1].filter(trx => trx.accountCode === accountNo.accountCode)[0].transactions);

            this.dataSourceArray = transactionArray.map(transactions =>
              new MatTableDataSource(transactions.sort((a, b) => Date.parse(b.date.toString()) - Date.parse(a.date.toString()))));

            // this.units =  results[1].map(t => Number(t.unitCode));
            this.loadComplete$.next(true);
            this.loadComplete();
        } else { this.loadComplete(); }
      }, error => {
        this.snackbar.open(error, '', { duration: 5000 });
        this.loadComplete$.next(true);
        this.loadComplete();
      });
    } else {
      this.loadComplete();
    }
  }
  private loadComplete() {
    this.loadComplete$.complete();
    this.loading = false;
  }

  private balance(balance: BalanceAccount) {
    if (balance) {
      return balance.amount;
    } else { return 0; }
  }

  ngAfterViewInit() {
    this.loadComplete$.asObservable().subscribe(result => {
      if (result) {
        this.paginatorList.forEach((v, i) => {
          this.dataSourceArray[i].paginator = v;
        });
      }
    });
  }
  tooltipText(row: any): string {
    return  this.accountService.getTooltipForPending(row);
  }
  openPayment(account: string) {
    let data;
    data = {
      accounts: [
        account
      ]
    };
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dialogConfirmation = this.dialog.open(PaymentConfirmationComponent, {
          data: result
        });
        dialogConfirmation.afterClosed().subscribe(results => {

        });
      }
    });
  }

}
