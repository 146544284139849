import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { isString } from 'util';
import { of } from 'rxjs';

export class ValidatorHelper {
    static required(control: AbstractControl): ValidationErrors | null {
         return (isString(control.value) && control.value.trim() === '') ?
               {'required': true} :
               null;
    }

    // Validates phone numbers
    static phoneNumber(number): any {
        if (number.pristine) {
            return null;
         }
         const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
         number.markAsTouched();
         if (PHONE_REGEXP.test(number.value)) {
            return null;
         }
         return {
            invalidNumber: true
         };
    }
}
