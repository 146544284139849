import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

import { RoleListComponent } from './role-list/role-list.component';
import { RingListComponent } from './ring-list/ring-list.component';
import { SecurityRoutingModule } from './security-routing/security-routing.module';
import { ApiKeyListComponent } from './api-key-list/api-key-list.component';
import { EntityListComponent } from './entity-list/entity-list.component';
import { UsersListComponent } from './users-list/users-list.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplateAddEditComponent } from './template-add-edit/template-add-edit.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SecurityRoutingModule,
    MaterialModule
  ],
  exports: [
    SecurityRoutingModule,
  ],
  declarations: [
    RoleListComponent,
    RingListComponent,
    ApiKeyListComponent,
    EntityListComponent,
    UsersListComponent,
    TemplateListComponent,
    TemplateAddEditComponent]
})

export class SecurityModule { }
