import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { FirebaseMessagingService } from './firebase-messaging.service';
import { LoginService } from './login.service';

import { default as decode } from 'jwt-decode';
import { SiteHelper } from '../helpers/site-helper';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private messaging: FirebaseMessagingService) { }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  goToLandingPage(): void {
    this.router.navigate(['/home']);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const serializedObjectData = localStorage.getItem('GridSystemsUserData');
    let userData, decodedToken, result;

    if (serializedObjectData != null) {
      userData = JSON.parse(serializedObjectData);

      // If the user data available in the local storage is not a valid UserData
      // object, then redirect the user to the login screen.
      if (userData.token !== null) {
        decodedToken = decode(userData.token);

        const nowSeconds = Date.now() / 1000;

        // If the token is close to expiring, refresh it.
        // (5 seconds is a time span large enough to avoid sending in a request a token that
        // expired a few milliseconds ago).
        if (decodedToken.exp <= nowSeconds - 5 && userData.refreshToken !== undefined && userData.refreshToken !== null) {
          this.loginService.refreshToken(userData.refreshToken).subscribe(_ => {
            // TODO: Handle ServiceError objects.
            this.messaging.requestPermission();
          });
        } else if (decodedToken.exp > nowSeconds) {
          // If the tokens expiration date hasn't passed (> Date.now), then it continues.
          // If it has passed erase the userData from local storage and go back to the login screen
          result = true;
          this.messaging.requestPermission();
        } else {
          localStorage.removeItem('GridSystemsUserData');
          result = false;

          if (SiteHelper.isCommunity()) {
            this.goToLandingPage();
          } else {
            this.goToLogin();
          }
        }
      } else {
        this.goToLogin();
        result = false;
      }
    } else {
      if (state.url === '/app/payment') {
        return true;
      } else {
        this.goToLogin();
      }
      result = false;
    }

    return result;
  }
}
