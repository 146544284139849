import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { PhoneNumber } from '../model/phone-number';
import { Observable } from 'rxjs';
import { CrudResponseModel } from '../model/crud-response-model';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService extends AbstractDataProviderService<PhoneNumber> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.GridVendor, '/phoneNumber', http, appConfig);
  }

  linkPhoneNumberToCompany(phoneNumberId: number, companyId: number): Observable<CrudResponseModel> {
    const body = {
      'phoneNumberId': phoneNumberId,
      'companyId': companyId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/company', body, { headers: this.buildPostHeaders() });
  }

  unlinkPhoneNumberFromCompany(phoneNumberId: number, companyId: number): Observable<CrudResponseModel> {
    const body = {
      'phoneNumberId': phoneNumberId,
      'companyId': companyId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/company', body, { headers: this.buildPostHeaders() });
  }

  getByCompanyId(companyId: number): Observable<PhoneNumber[]> {
    return this.http.get<PhoneNumber[]>(this.baseUrl + '/byCompanyId/' + companyId.toString(), { headers: this.buildGetHeaders() });
  }
  getByPeopleId(peopleId: number): Observable<PhoneNumber[]> {
    return this.http.get<PhoneNumber[]>(this.baseUrl + '/byPeopleId/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }
  getByRelationship(peopleId: number): Observable<PhoneNumber[]> {
    return this.http.get<PhoneNumber[]>(this.baseUrl + '/byRelationship/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }
  getFullByPeopleId(peopleId: number): Observable<PhoneNumber[]> {
    return this.http.get<PhoneNumber[]>(this.baseUrl + '/fullByPeopleId/' + peopleId.toString(), { headers: this.buildGetHeaders() });
  }

  addPhoneNumberToPeople(phone: PhoneNumber, peopleId: number) {
    this.add(phone).subscribe(result => {
        this.linkPhoneNumberToPeople(result.id, peopleId).subscribe();
    });
  }
  deletePhoneNumberFromPeople(phone: PhoneNumber, peopleId: number) {
    this.delete(phone.phoneNumberId).subscribe();
      if (phone.relationshipId === null) {
        this.unlinkPhoneNumberFromPeople(phone.phoneNumberId, peopleId).subscribe();
      } else {
        this.unlinkPhoneNumberFromPeople(phone.phoneNumberId, phone.relationshipId).subscribe();
      }
    }
  linkPhoneNumberToPeople(phoneNumberId: number, peopleId: number, isPrimary?: boolean):
  Observable<CrudResponseModel> {
    const body = {
      'phoneNumberId': phoneNumberId,
      'peopleId': peopleId,
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/people', body, { headers: this.buildPostHeaders() });
  }

  linkPhoneNumberToRelationship(phoneNumberId: number, relationshipId: number):
  Observable<CrudResponseModel> {
    const body = {
      'phoneNumberId': phoneNumberId,
      'relationshipId': relationshipId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/relationship', body, { headers: this.buildPostHeaders() });
  }

  unlinkPhoneNumberFromPeople(phoneNumberId: number, peopleId: number): Observable<CrudResponseModel> {
    const body = {
      'phoneNumberId': phoneNumberId,
      'peopleId': peopleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/people', body, { headers: this.buildPostHeaders() });
  }
}
